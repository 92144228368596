import React from 'react';

const PrintIconICO = () => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="979.201px"
    height="979.201px"
    viewBox="0 0 979.201 979.201"
    xmlSpace="preserve"
  >
    <g>
      <path
        d="M749.451,190.2c0-30.5-12.101-59.8-33.7-81.4L640.65,33.7C619.051,12.1,589.751,0,559.251,0h-269.6c-33.1,0-60,26.9-60,60
      v192.7h519.9v-62.5H749.451z"
      />
      <path
        d="M62.351,822.101h103V613.7h64.3h519.9h64.3v208.4h103c33.101,0,60-26.9,60-60V382.6c0-33.1-26.899-60-60-60H749.451h-519.8
      h-167.3c-33.1,0-60,26.9-60,60v379.5C2.351,795.3,29.151,822.101,62.351,822.101z M157.251,404.601c34.8,0,63,28.199,63,63
      c0,34.8-28.2,63-63,63c-34.8,0-63-28.2-63-63C94.251,432.8,122.451,404.601,157.251,404.601z"
      />
      <path
        d="M753.851,949.3v-57.1v-35v-35V673.7h-12.5h-503.6h-12.5v148.4v35v35v57.1c0,16.5,13.5,30,30,30h468.6
      C740.351,979.3,753.851,965.8,753.851,949.3z M359.551,796.5h260c15.1,0,27.6,11.101,29.7,25.601c0.2,1.399,0.3,2.899,0.3,4.399
      c0,16.601-13.4,30-30,30h-260c-16.6,0-30-13.399-30-30c0-1.5,0.1-2.899,0.3-4.399C331.951,807.601,344.451,796.5,359.551,796.5z"
      />
    </g>
  </svg>
);
export default PrintIconICO;
