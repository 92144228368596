import React, { Component } from 'react';
import InfoIconSVG from './image/InfoIcon';
import QuestionIconSVG from './image/QuestionIcon';

class InfoBoxComponent extends Component {
  render() {
    const { info, question } = this.props;

    let component = <InfoIconSVG />;
    if (question) {
      component = <QuestionIconSVG />;
    }
    let extraClass = 'infobox';
    let extraInformation = '';
    if (info) {
      extraClass += ' hover-info';
      extraInformation = <div className="hovered-information">{info}</div>;
    }

    return (
      <div className="infobox-wrapper">
        <div className={extraClass}>{component}</div>
        {extraInformation}
      </div>
    );
  }
}

export default InfoBoxComponent;
