import React, { Component } from 'react';

class Spacing extends Component {
  render() {
    const { space, extraClass } = this.props;

    return (
      <div
        className={`spacing-row col-12 ${!extraClass ? '' : extraClass}`}
        style={{ float: 'left', width: '100%', height: `${space}px` }}
      />
    );
  }
}

export default Spacing;
