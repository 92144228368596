import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';

import LoadingAnimation from '../../../atoms/forms/LoadingAnimation';

import PictureSelectionComponent from '../../../atoms/image/PictureSelection';
import { MainHeadlineSmall } from '../../../molecules/typography/heading';

import { addForm } from '../../../../reducer/actions';
import Spacing from '../../../atoms/spacing/spacing';

class ColorSelectiveComponent extends Component {
  constructor(props) {
    super(props);
    const { form } = this.props;
    const { forms } = form;
    const { colorsSelective } = forms;

    let interiorSelection = [];
    if (colorsSelective) {
      interiorSelection = colorsSelective;
    }

    this.state = {
      selectedRows: interiorSelection,
      Colors: [],
      sessionExist: 0,
    };
  }

  componentDidMount() {
    const { addForm, form } = this.props;
    const { forms } = form;

    const { selectedRows, Colors, sessionExist } = this.state;

    const url = `${forms.apiUrl}/api/v1/colors`;
    if (Colors.length < 1) {
      const rows = [];
      axios.get(url).then((res) => {
        const { data } = res;
        if (data) {
          data.map((value, index) => {
            rows.push(value);
            if (index + 1 === data.length) {
              this.setState({
                Colors: rows,
              });
            }
            return value;
          });
        }
      });
    }

    const checkStorage = localStorage.getItem('ligeaForm');
    if (checkStorage) {
      this.setState({
        sessionExist: 1,
      });
    }

    addForm({ nextStepSidebarText: 'Next' });

    const st = selectedRows;
    if (st.length > 0) {
      addForm({ sidebarNextEnable: true });
      addForm({ parentLead: 'color_selective' });
      addForm({ backButton: 'color_selective' });
      if (sessionExist) {
        addForm({ nextStepSidebar: 'brief_checkout' });
      } else {
        addForm({ nextStepSidebar: 'area_specification' });
      }
    } else {
      addForm({ sidebarNextEnable: false });
      addForm({ nextStepSidebar: false });
    }

    this.setState((prevState) => ({ ...prevState, Colors }));

    addForm({ backBtn: 'pattern_selective' });
  }

  handleClick(type) {
    const { addForm } = this.props;
    addForm({ currentStep: type });
  }

  handlePicClick(e) {
    const { addForm } = this.props;

    const { selectedRows, sessionExist } = this.state;
    const st = selectedRows;

    if (st.length > 0) {
      let exist = false;
      st.map((value, index) => {
        if (value[0] === e[0]) {
          exist = true;
          st.splice(index, 1);
        }

        if (index + 1 === st.length) {
          if (!exist) {
            st.push(e);
          }
        }
        return false;
      });
    } else {
      st.push(e);
    }

    this.setState({
      selectedRows: st,
    });

    if (st.length > 0) {
      addForm({ sidebarNextEnable: true });
      addForm({ parentLead: 'color_selective' });
      addForm({ backButton: 'color_selective' });
      if (sessionExist) {
        addForm({ nextStepSidebar: 'brief_checkout' });
      } else {
        addForm({ nextStepSidebar: 'area_specification' });
      }
    } else {
      addForm({ sidebarNextEnable: false });
      addForm({ nextStepSidebar: false });
    }

    addForm({ colorsSelective: st });
  }

  render() {
    // prettier-ignore
    const {
      Colors,
      selectedRows
    } = this.state;

    let picList = [];
    if (Colors.length > 0) {
      Colors.map((color) => {
        const st = selectedRows;
        if (st.length > 0) {
          let indexRow = false;
          let exist = false;
          st.map((value, index) => {
            if (value[0] === color.id) {
              exist = true;
              indexRow = index + 1;
            }
            if (index + 1 === st.length) {
              picList.push(
                <Col lg={2} xs={6} className="col">
                  <PictureSelectionComponent
                    limit={5}
                    alertInfo="Only 3 selection are limited"
                    counter="colorsSelective"
                    color={color.color}
                    label={`${color.title}`}
                    isSelected={exist}
                    numberSelect={indexRow}
                    select
                    id={color.id}
                    name={`${color.title}`}
                    triggerclick={(e) => {
                      this.handlePicClick(e);
                    }}
                  />
                </Col>
              );
            }
            return false;
          });
        } else {
          picList.push(
            <Col lg={2} xs={6} className="col">
              <PictureSelectionComponent
                limit={5}
                alertInfo="Only 3 selection are limited"
                counter="colorsSelective"
                color={color.color}
                label={`${color.title}`}
                select
                id={color.id}
                name={`${color.title}`}
                triggerclick={(e) => {
                  this.handlePicClick(e);
                }}
              />
            </Col>
          );
        }

        return false;
      });
    } else {
      picList = <LoadingAnimation />;
    }

    return (
      <Container fluid className="colorSelective-container p-0 m-0">
        <Row>
          <Col xs={12}>
            <Col xs={12} className="pl-lg-0 p-mob-0">
              <MainHeadlineSmall
                topline="CREATIVE BRIEF TOOL"
                text="What color do you prefer?"
                subline="Choose your preferred colours. Choose up to 5 Colors."
              />
            </Col>
            <Row>
              <Spacing space={39} />
            </Row>
            <Col xs={12} className="p-0 color-list p-mob-0">
              <Row>{picList}</Row>
              <Row className="d-none d-md-block">
                <Spacing space={39} />
              </Row>
            </Col>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ColorSelectiveComponent);
