import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { addForm } from '../../../reducer/actions';

import { MainHeadlineSmall } from '../../molecules/typography/heading';
import Spacing from '../../atoms/spacing/spacing';
import Topline from '../../atoms/typography/topline';

import DefaultImage from '../../../assets/images/product-selection-blank.png';

class BriefChoosenDesigns extends Component {
  triggerme(type) {
    const { addForm } = this.props;
    addForm({ currentStep: type });
  }

  render() {
    // prettier-ignore
    const {
      form
    } = this.props;

    // prettier-ignore
    const {
      forms
    } = form;

    // const { interiorSelective, patternSelective } = forms;

    const checkLines = [
      'interiorSelective',
      'patternSelective',
      'colorsSelective',
    ];

    const Rows = [];

    checkLines.map((key) => {
      if (forms[key]) {
        if (Array.isArray(forms[key])) {
          forms[key].map((value) => {
            if (value[1]) {
              const image = !value[2] ? DefaultImage : value[2].url;
              let title = '';
              let triggerme = '';
              if (key === 'patternSelective') {
                title = 'PATTERN';
                triggerme = 'pattern_selective';
              } else if (key === 'colorsSelective') {
                title = 'COLOR RANGE';
                triggerme = 'color_selective';
              } else {
                title = 'INTERIOR STYLE';
                triggerme = 'interior_selective';
              }

              let imageWrapper = (
                <img src={image} alt="Product " className="img-fluid" />
              );
              if (value[3]) {
                imageWrapper = (
                  <Col
                    xs={12}
                    className="color-selection"
                    style={{ backgroundColor: value[2].url }}
                  >
                    <Row>
                      <Spacing space={50} />
                    </Row>
                  </Col>
                );
              }
              Rows.push(
                <Row className="chosen-designs-row">
                  <Col xs={4} className="product-image pl-lg-0">
                    {imageWrapper}
                  </Col>
                  <Col xs={8} className="pr-0">
                    <Topline topline={title} />
                    <h4 className="product-title">{value[1]}</h4>
                    <button
                      type="button"
                      className="edit-btn"
                      onClick={() => {
                        this.triggerme(triggerme);
                      }}
                    >
                      Edit
                    </button>
                  </Col>
                </Row>
              );
            }

            return false;
          });
        }
      } else {
        Rows.push(
          <ul>
            <li>No Design is selected</li>
          </ul>
        );
      }
      return false;
    });

    return (
      <Col xs={12} className="chosen-designs">
        <div className="headline-row">
          <MainHeadlineSmall
            topline="PRODUCT"
            text="Your chosen preferences"
            subline="You have selected the following interior styles, patterns and colours as inspiration for your custom design carpet"
          />
        </div>
        <Row>
          <Spacing space={39} />
        </Row>
        <div className="design-selection">{Rows}</div>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BriefChoosenDesigns);
