import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import validator from 'validator';

import Input from '../atoms/forms/input';

class AreaRugs extends Component {
  constructor() {
    super();

    this.addAreaRug = this.addAreaRug.bind(this);
    this.removeAreaRug = this.removeAreaRug.bind(this);
  }

  addAreaRug() {
    const { forms, addForm, handleChanges } = this.props;

    const { areaRugs } = forms;

    addForm({
      areaRugs: [...areaRugs, {
        width: '',
        length: '',
        amount: '',
        id: Math.floor(Math.random() * 1000)
      }]
    });

    handleChanges();
  }

  removeAreaRug(areaRugId) {
    const { handleChanges, addForm, forms } = this.props;

    const { areaRugs } = forms;

    addForm({
      areaRugs: areaRugs.filter((areaRug) => areaRug.id !== areaRugId)
    });

    handleChanges();
  }

  render() {
    const { forms, areaType, handleChanges } = this.props;

    return areaType === 'Area Rug' ? (
      <Col xs={12}>
        {forms.areaRugs ? forms.areaRugs.map((areaRug, index) => (
          <div key={areaRug.id}>
            <Row>
              <Col xs={12} lg={6}>
                <Col xs={12} className="p-mob-0">
                  <div className={`area-rug-title ${index === 0 ? 'no-margin' : ''}`}>
                    <span>Area Rug {index + 1}:</span>
                    {index > 0
                      && (
                        <button type="button" className="btn btn-primary area-rug-delete" onClick={() => this.removeAreaRug(areaRug.id)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10.001"><path fill="#fff" d="M6.414 5.001l3.293-3.294A1 1 0 008.293.293L5 3.586 1.707.293A1 1 0 00.293 1.707l3.293 3.294L.293 8.294a1 1 0 101.414 1.414L5 6.415l3.293 3.293a1 1 0 001.414-1.414z" /></svg>
                        </button>
                      )}
                  </div>
                </Col>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={6}>
                <Col xs={12} className="p-mob-0">
                  <Input
                    name={`areaRugs.${index}.length`}
                    type="number"
                    label="Length"
                    value={areaRug.length}
                    reducer
                    parentupdate={(e) => handleChanges(e)}
                    error={validator.isEmpty(areaRug.length)}
                    errorContent="Please Fill Area Size"
                    extraClasses="with-cm"
                  />
                </Col>
                <Col xs={12} className="p-mob-0">
                  <Input
                    name={`areaRugs.${index}.width`}
                    type="number"
                    label="Width"
                    value={areaRug.width}
                    reducer
                    parentupdate={(e) => handleChanges(e)}
                    error={validator.isEmpty(areaRug.width)}
                    errorContent="Please Fill Area Size"
                    extraClasses="with-cm"
                  />
                </Col>
                <Col xs={12} className="p-mob-0">
                  <Input
                    name={`areaRugs.${index}.amount`}
                    type="number"
                    label="Amount"
                    value={areaRug.amount}
                    reducer
                    parentupdate={(e) => handleChanges(e)}
                    error={validator.isEmpty(areaRug.amount)}
                    errorContent="Please Fill Amount Size"
                  />
                </Col>
              </Col>
            </Row>
          </div>
        )) : ''}
        <Row>
          <Col xs={12} lg={6}>
            <Col xs={12} className="p-mob-0">
              <button className="area-rug-add" type="button" onClick={() => this.addAreaRug()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14.143" height="14.143"><path fill="#cc7828" d="M8.072 6.073V1.415a1 1 0 00-2 0v4.657H1.415a1 1 0 000 2h4.657v4.658a1 1 0 102 0V8.073h4.657a1 1 0 000-2z" /></svg>
                <span>Add another set of dimensions</span>
              </button>
            </Col>
          </Col>
        </Row>
      </Col>
    ) : '';
  }
}

export default AreaRugs;
