import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { addForm } from '../../reducer/actions';

import Spacing from '../atoms/spacing/spacing';
import { MainHeadlineSmall } from '../molecules/typography/heading';

class OverviewInformationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  triggerme(type) {
    const { addForm } = this.props;
    addForm({ currentStep: type });
  }

  formatDate(date) {
    const d = new Date(date);
    const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth();

    return `${d.getDate()}.${month}.${d.getFullYear()}`;
  }

  render() {
    // prettier-ignore
    const {
      form
    } = this.props;

    // prettier-ignore
    const {
      forms
    } = form;

    const rows = [];

    if (forms.ProjectCategory) {
      let rooms = '';
      if (forms.roomTypeSelection) {
        forms.roomTypeSelection.map((value, index) => {
          let title = value[1];
          if (index + 1 !== forms.roomTypeSelection.length) {
            title += ', ';
          }
          rooms += title;
          return rows;
        });
      }

      rows.push(
        <li>
          It&apos;s a project for
          <span className="result">{forms.ProjectCategory}</span> for the
          <span className="result">{rooms}</span>
          <button
            type="button"
            className="edit-btn"
            onClick={() => {
              this.triggerme('upload_files');
            }}
          >
            Edit
          </button>
        </li>
      );
    }

    if (forms.areaType) {
      if (forms.areaType === 'Wall to Wall') {
        rows.push(
          <li>
            You are looking for <span className="result">Wall to Wall</span>
            rugs to cover an area of
            <span className="result">{forms.areaSizeInSqm} sqm .</span>

            {forms.ElseAreaSpecification && (
              <>
                <br />
                We should know that: <span className="result">{forms.ElseAreaSpecification}</span>
              </>
            )}

            {!forms.readOnly
              && (
                <button
                  type="button"
                  className="edit-btn"
                  onClick={() => {
                    this.triggerme('area_specification');
                  }}
                >
                  Edit
                </button>
              )}
          </li>
        );
      } else {
        if (forms.areaRugs) {
          rows.push(
            <li>
              You are looking for the following <span className="result">Area Rugs:</span>
              <br />
              {forms.areaRugs && forms.areaRugs.map((areaRug, index) => (
                <>
                  <span className="result">Area Rug {index + 1}</span> to cover a Width of
                  <span className="result">{areaRug.width} cm</span>
                  and Length of
                  <span className="result">{areaRug.length} cm</span>
                  and Amount of
                  <span className="result">{areaRug.amount} </span>
                  <br />
                </>
              ))}

              {forms.ElseAreaSpecification && (
                <>
                  <br />
                  We should know that: <span className="result">{forms.ElseAreaSpecification}</span>
                </>
              )}

              {!forms.readOnly
                && (
                  <button
                    type="button"
                    className="edit-btn"
                    onClick={() => {
                      this.triggerme('area_specification');
                    }}
                  >
                    Edit
                  </button>
                )}
            </li>
          );
        }
        if (!forms.areaRugs) {
          rows.push(
            <li>
              You are looking for <span className="result">Area Rug</span> to
              cover an area of
              <span className="result">{forms.areaSizeInSqm} sqm .</span>
              <button
                type="button"
                className="edit-btn"
                onClick={() => {
                  this.triggerme('area_specification');
                }}
              >
                Edit
              </button>
            </li>
          );
        }
      }
    }

    if (forms.AreaSpecificationYourCountry) {
      const date = this.formatDate(forms.AreaSpecificationShipmentDate);
      const offerDate = this.formatDate(forms.AreaSpecificationBindingOffer);

      let individualProject = '';
      if (forms.AreaSpecificationProjectTitle) {
        individualProject = (
          <p>
            Your individual project name is
            <span className="result">
              {forms.AreaSpecificationProjectTitle}
            </span>
          </p>
        );
      }

      const country = forms.AreaSpecificationYourCountry;

      rows.push(
        <li>
          <div>
            <p>
              The project is located in
              <span className="result">{country}</span>
              and shipment is due on
              <span className="result">{date}</span>
            </p>
            <p>
              You need the offer until
              <span className="result">{offerDate}</span>
            </p>
            {individualProject}
            <button
              type="button"
              className="edit-btn"
              onClick={() => {
                this.triggerme('area_specification_details');
              }}
            >
              Edit
            </button>
          </div>
        </li>
      );
    }

    return (
      <Col xs={12} className="form">
        <MainHeadlineSmall
          text="Overview of your information"
          topline="Quotation request"
          subline="These are the specifications you have set for this project."
        />
        <Row>
          <Spacing space={39} />
        </Row>
        <ul>{rows}</ul>
        <Row>
          <Spacing space={39} />
        </Row>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OverviewInformationComponent);
