import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import validator from 'validator';
import { MainHeadlineSmall } from '../molecules/typography/heading';
import { addForm } from '../../reducer/actions';

import Spacing from '../atoms/spacing/spacing';

import ToggleRadioComponent from '../atoms/forms/toggle';
import { H4 } from '../atoms/typography/heading';

import ContactInformation from '../organisms/Billing/ContactInformation';
import BillingInformation from '../organisms/Billing/BillingInformation';
import ShippingInformation from '../organisms/Billing/ShippingInformation';

class BillingShipmentAddressComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShippingAddressActive: false,
    };

    this.checkValidation = this.checkValidation.bind(this);
  }

  componentDidMount() {
    const { addForm } = this.props;
    addForm({ sidebarNextEnable: false });
    addForm({ nextStepSidebarText: 'Request personalized quote' });
    addForm({ fullButtonSize: true });
    this.checkValidation();
  }

  ValidateParent() {
    this.checkValidation();
  }

  checkValidation() {
    // prettier-ignore
    const { form, addForm } = this.props;
    const { forms } = form;

    let checkLines = [
      'BillingCompany',
      'BillingStreet',
      // 'BillingNumber',
      'BillingZip',
      // 'BillingCity',
      'ContactFirstName',
      'ContactLastName',
      // 'ContactPhone',
    ];

    const { ShippingAddressActive } = this.state;
    if (ShippingAddressActive) {
      const join = [
        'ShippingCompany',
        'ShippingStreet',
        // 'ShippingNumber',
        // 'ShippingAddress',
        'ShippingZip',
        'ShippingCity',
        // 'ShippingUST',
      ];
      checkLines = checkLines.concat(join);
    }

    let continueRow = true;
    checkLines.map((key, index) => {
      if (forms[key]) {
        const validate = validator.isEmpty(forms[key]);
        // prettier-ignore
        if (validate) {
          continueRow = false;
        }
      } else {
        continueRow = false;
      }

      if (index + 1 === checkLines.length) {
        if (continueRow) {
          addForm({ sidebarNextEnable: true });
          addForm({ nextStepSidebar: 'offer_completed' });
        } else {
          addForm({ sidebarNextEnable: false });
        }
        const checkStorage = localStorage.getItem('ligeaForm');
        if (checkStorage) {
          addForm({ isRepeatedCustomer: true });
        }
      }
      return false;
    });
  }

  render() {
    const { ShippingAddressActive } = this.state;

    let ShippingInfo = '';
    if (ShippingAddressActive) {
      ShippingInfo = (
        <ShippingInformation
          ValidateParent={() => {
            this.ValidateParent();
          }}
        />
      );
    }
    return (
      <Container fluid className="billingaddress-container p-0 m-0">
        <Row>
          <Col xs={12} className="mt-3 pl-lg-0">
            <Col xs={12} className="pl-lg-0 p-mob-0">
              <MainHeadlineSmall
                topline="05 - BILLING INFORMATION"
                text="Billing and shipment address"
                subline="Please fill in your contact Information plus the billing-and shipment address."
              />
            </Col>
            <Row>
              <Spacing space={63} />
            </Row>
            <Col xs={12} className="form form-row p-mob-0">
              <ContactInformation
                ValidateParent={() => {
                  this.ValidateParent();
                }}
              />

              <Col xs={12}>
                <hr className="mt-5 mb-5" />
              </Col>

              <BillingInformation
                ValidateParent={() => {
                  this.ValidateParent();
                }}
              />

              <Col xs={12}>
                <hr className="mt-5 mb-5" />
              </Col>

              <Col lg={4} xs={12}>
                <H4 text="Different shipping address?" />
              </Col>
              <Col lg={8} xs={12} className="mt-4">
                <Row>
                  <Col xs={12} className="mt-4">
                    <ToggleRadioComponent
                      name="fieldWork"
                      required={false}
                      title={[
                        <span>
                          Yes, billing and shipment go to <br /> different
                          addresses.
                        </span>,
                      ]}
                      reducer
                      value={ShippingAddressActive}
                      parentupdate={(e) => {
                        addForm({ ShippingAddressActive: e.target.checked });
                        this.setState({
                          ShippingAddressActive: e.target.checked,
                        });
                        setTimeout(() => {
                          this.checkValidation();
                        }, 200);
                      }}
                    />
                  </Col>
                  <Row>
                    <Spacing space={39} />
                  </Row>
                </Row>
              </Col>
              {ShippingInfo}
            </Col>
          </Col>

          <Col xs={12} className="choiceRole-cards mt-5" />
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingShipmentAddressComponent);
