import React, { Component } from 'react';

import Logo from '../../assets/images/footer-logo.svg';

class FooterLogoComponent extends Component {
  render() {
    return (
      <div className="logo">
        <a href="https://www.ligea.com/">
          <img src={Logo} className="img-fluid" alt="Logo" />
        </a>
      </div>
    );
  }
}

export default FooterLogoComponent;
