import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import validator from 'validator';

import Spacing from '../../atoms/spacing/spacing';

import Input from '../../atoms/forms/input';
import { addForm } from '../../../reducer/actions';

class BillingInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      BillingCompany: '',
      BillingStreet: '',
      BillingNumber: '',
      BillingAddress: '',
      BillingZip: '',
      BillingCity: '',
      BillingUST: '',
    };
  }

  componentDidMount() {
    const { form, ValidateParent } = this.props;
    const { forms } = form;

    const checkLines = [
      'BillingCompany',
      'BillingStreet',
      'BillingNumber',
      'BillingAddress',
      'BillingZip',
      'BillingCity',
      'BillingUST',
    ];

    checkLines.map((key, index) => {
      if (forms[key]) {
        const st = this.state;
        st[key] = forms[key];
        this.setState((prevState) => ({ ...prevState, st }));
      }
      if (index + 1 === checkLines.length) {
        this.setState({
          updateField: true,
        });
      }
      return false;
    });

    ValidateParent();
  }

  handleInputChange(e) {
    const { ValidateParent } = this.props;
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    const { addForm } = this.props;
    addForm({ name: value });

    ValidateParent();
  }

  render() {
    // prettier-ignore
    const {
      BillingCompany,
      BillingStreet,
      BillingNumber,
      BillingAddress,
      BillingZip,
      BillingCity,
      BillingUST,
      updateField
    } = this.state;

    if (!updateField) {
      return '';
    }

    return (
      <Row>
        <Col lg={4} className="mt-5">
          <h4>Billing information</h4>
        </Col>
        <Col lg={8} className="mt-3">
          <Row>
            <Row>
              <Spacing space={60} />
            </Row>
            <Col xs={12} className="form-group">
              <Input
                name="BillingCompany"
                type="text"
                label="Company name *"
                reducer
                value={BillingCompany}
                error={validator.isEmpty(BillingCompany)}
                errorContent="Please Fill Company name"
                parentupdate={(e) => {
                  this.handleInputChange(e);
                }}
              />
            </Col>
            <Col xs={12}>
              <Row>
                <Col md={6} xs={12} className="form-group">
                  <Input
                    name="BillingStreet"
                    type="text"
                    label="Street *"
                    reducer
                    value={BillingStreet}
                    error={validator.isEmpty(BillingStreet)}
                    errorContent="Please Fill Street"
                    parentupdate={(e) => {
                      this.handleInputChange(e);
                    }}
                  />
                </Col>
                <Col md={6} xs={12} className="form-group">
                  <Input
                    name="BillingNumber"
                    type="text"
                    label="No."
                    reducer
                    value={BillingNumber}
                    error={validator.isEmpty(BillingNumber)}
                    errorContent="Please Fill No."
                    parentupdate={(e) => {
                      this.handleInputChange(e);
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="form-group">
              <Input
                name="BillingAddress"
                type="text"
                label="Additional address details"
                reducer
                value={BillingAddress}
                // error={validator.isEmpty(BillingAddress)}
                // errorContent="Please Fill Additional address details"
                parentupdate={(e) => {
                  this.handleInputChange(e);
                }}
              />
            </Col>
            <Col xs={12}>
              <Row>
                <Col md={6} xs={12} className="form-group">
                  <Input
                    name="BillingZip"
                    type="text"
                    label="ZIP Code *"
                    reducer
                    value={BillingZip}
                    error={validator.isEmpty(BillingZip)}
                    errorContent="Please Fill ZIP Code"
                    parentupdate={(e) => {
                      this.handleInputChange(e);
                    }}
                  />
                </Col>
                <Col md={6} xs={12} className="form-group">
                  <Input
                    name="BillingCity"
                    type="text"
                    label="City"
                    reducer
                    value={BillingCity}
                    // error={validator.isEmpty(BillingCity)}
                    // errorContent="Please Fill City"
                    parentupdate={(e) => {
                      this.handleInputChange(e);
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="form-group">
              <Input
                name="BillingUST"
                type="text"
                label="USt.-ID"
                reducer
                value={BillingUST}
                // error={validator.isEmpty(BillingUST)}
                // errorContent="Please Fill USt.-ID"
                parentupdate={(e) => {
                  this.handleInputChange(e);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(BillingInformation);
