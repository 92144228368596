import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';
import config from '../../../Constants';

// import Spacing from '../../atoms/spacing/spacing';
import { addForm } from '../../../reducer/actions';
import LogoComponent from '../../atoms/Logo';
import Spacing from '../../atoms/spacing/spacing';
import { MainHeadline } from '../../molecules/typography/heading';

import BriefChoosenDesigns from '../../organisms/Brief/ChoosenDesigns';
import OverviewInformationComponent from '../../organisms/OverviewInformation';

import PrintIconICO from '../../atoms/image/PrintIcon';

class OrderCompletedURLComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingOrder: true,
      orderExist: true,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;

    const url = config.url.API_URL;

    const getUrl = `${url}/api/v1/brief?id=${params.id}`;
    axios.post(getUrl).then((res) => {
      const interiorSelective = [];
      res.data.interiorSelective.map((value) => {
        const localArr = [];
        localArr.push(value.id);
        localArr.push(value.title);
        let img = null;
        if (value.image) {
          img = {
            url: value.image,
          };
        }
        localArr.push(img);
        interiorSelective.push(localArr);
        return value;
      });

      const patternselective = [];
      res.data.patternselective.map((value) => {
        const localArr = [];
        localArr.push(value.id);
        localArr.push(value.title);
        let img = null;
        if (value.image) {
          img = {
            url: value.image,
          };
        }
        localArr.push(img);
        patternselective.push(localArr);
        return value;
      });

      const colorselective = [];
      res.data.colorselective.map((value) => {
        const localArr = [];
        localArr.push(value.id);
        localArr.push(value.title);
        let color = null;
        if (value.color) {
          color = {
            url: value.color,
          };
        }
        localArr.push(color);
        localArr.push(color, 'color');
        colorselective.push(localArr);
        return value;
      });

      const { addForm } = this.props;
      addForm({ emailAddress: res.data.emailAddress });
      addForm({ interiorSelective });
      addForm({ patternSelective: patternselective });
      addForm({ colorsSelective: colorselective });
      addForm({ areaRugs: res.data.areaRugs });
      addForm({ areaType: res.data.areatype });
      addForm({ ElseAreaSpecification: res.data.areaElseMessage });
      addForm({ readOnly: true });
      addForm({ areaSizeInSqm: res.data.areasizeinsqm });

      if (res.data) {
        this.setState({
          loadingOrder: false,
        });
      } else {
        this.setState({
          orderExist: false,
        });
      }
    });
  }

  render() {
    const { loadingOrder, orderExist } = this.state;

    let Message = 'LOADING ORDER ...';
    if (orderExist) {
      // prettier-ignore
      Message = (<h4>ORDER DOESNT EXIST , PLEASE BE SURE YOU ARE USING THE RIGHT LINK.</h4>);
    }
    if (loadingOrder) {
      return (
        <Container fluid className="order-template-completion">
          <Row>
            <Col xs={10} className="content">
              <Row className="header-logo">
                <Col lg={12}>
                  <a href="/">
                    <LogoComponent />
                  </a>
                </Col>
              </Row>
              <Row>
                <Spacing space={10} />
              </Row>
              <Row className="header-logo pagerow">
                <Col lg={12}>
                  <MainHeadline
                    topline="My individual Project title"
                    text={Message}
                    SubLineSpace={39}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      );
    }
    const { form } = this.props;
    const { forms } = form;
    const { emailAddress } = forms;
    return (
      <Container fluid className="order-template-completion checkout-page">
        <Row>
          <Col xs={10} className="content">
            <Row className="header-logo">
              <Col lg={12}>
                <a href="/">
                  <LogoComponent />
                </a>
              </Col>
            </Row>
            <Row>
              <Spacing space={10} />
            </Row>
            <Row className="header-logo pagerow">
              <Col lg={12}>
                <MainHeadline
                  topline="My individual Project title"
                  text="Creative Brief Overview"
                  SubLineSpace={39}
                  subline={[
                    <span>
                      This design brief was created by
                      <a
                        className="blackc bold"
                        href={`mailto:${emailAddress}`}
                      >
                        {' '}
                        {emailAddress}
                      </a>
                      .
                    </span>,
                  ]}
                />
                <Col
                  xs={12}
                  onClick={() => {
                    window.print();
                  }}
                >
                  <div className="print-icon">
                    <PrintIconICO />
                  </div>
                </Col>
                <Row>
                  <BriefChoosenDesigns />
                </Row>
                <Row>
                  <Spacing space={39} />
                </Row>
                <Row className="rows">
                  <OverviewInformationComponent />
                </Row>
                <Row>
                  <Spacing space={39} />
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderCompletedURLComponent);
