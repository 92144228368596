import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';

import { addForm } from '../../reducer/actions';

import Spacing from '../atoms/spacing/spacing';
import { BtnGhost, BtnPrimary } from '../atoms/buttons/buttons';
import { MainHeadline } from '../molecules/typography/heading';
import ColorContentBox from '../molecules/banner/ColorContentBox';

class OfferServiceCompletedComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storageCompleted: true,
    };
  }

  componentDidMount() {
    const { addForm, form } = this.props;
    const { forms } = form;
    addForm({ sidebarNextEnable: false });
    addForm({ hideSidebar: true });
    addForm({ backBtn: false });
    addForm({ hideSidebar: true });

    const checkStorage = localStorage.getItem('ligeaForm');
    if (!checkStorage) {
      if (forms.where_do_you_need_support === 'Products and Prices') {
        const results = JSON.stringify(forms);
        localStorage.setItem('ligeaForm', results);
        // first time.
        this.sendOrderToCRM();
      } else {
        this.sendOrderToCRM();
      }
    }
    if (forms.isRepeatedCustomer) {
      localStorage.removeItem('ligeaForm');
      this.setState({
        storageCompleted: false,
      });
      this.sendOrderToCRM();
    }
  }

  sendOrderToCRM() {
    const { form, supportFile, uploadInspirationFiles } = this.props;
    const { forms } = form;

    const postData = new FormData();

    Object.keys(forms).forEach((key) => {
      postData.append(key, JSON.stringify(forms[key]));
    });

    if (uploadInspirationFiles.length > 0) {
      if (uploadInspirationFiles.length > 1) {
        uploadInspirationFiles.forEach((file, i) => {
          postData.append(`files[${i}]`, file);
        });
      } else {
        postData.append('file', uploadInspirationFiles[0]);
      }
    } else {
      postData.append('file', supportFile);
    }

    if (window.gtag) {
      window.gtag('event', 'order_completed', {
        app_name: 'Ligea Configurator',
        screen_name: 'Order Completed',
        currency: forms.currency,
        price: forms.TotalPriceState,
        email: forms.emailAddress,
      });
    }

    const url = `${forms.apiUrl}/api/v1/order`;
    axios
      .post(url, postData, {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      })
      .then(() => {
        axios.post(
          'https://flow.zoho.eu/20076121788/flow/webhook/incoming?zapikey=1001.0c4edb7f0c1935e7b30ab504abd665fe.5a59151dce284e11a616d1244372fdd7&isdebug=false',
          postData,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        fetch(
          'https://flow.zoho.eu/20076121788/flow/webhook/incoming?zapikey=1001.0c4edb7f0c1935e7b30ab504abd665fe.5a59151dce284e11a616d1244372fdd7&isdebug=false',
          {
            body: postData,
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
            }
          }
        );

        if (forms.where_do_you_need_support === 'Products and Prices') {
          window.location.reload();
        }
      });
  }

  triggerme(type) {
    const { addForm } = this.props;

    addForm({ HowCanWeHelpYou: type });
    addForm({ currentStep: type });
  }

  render() {
    const { storageCompleted } = this.state;
    const { form } = this.props;
    const { forms } = form;
    const { emailAddress } = forms;

    let colorBox = '';
    // prettier-ignore
    if (storageCompleted && forms.where_do_you_need_support === 'Products and Prices') {
      colorBox = (
        <ColorContentBox
          topline="WANT A DESIGN?"
          text="Upload your already finished design or use our inspiration tool."
          content={[
            <span>
              Our LIGEA® designers will support you and create an individual design concept based on your vision.
            </span>,
          ]}
          buttonActive={1}
          btnContent="Request individual design concept"
          triggerparent={() => {
            // this.triggerme(2);
            this.triggerme('design_support');
            // window.location.href = '';
          }}
        />
      );
    }

    return (
      <Container fluid className="p-0 m-0">
        <Row>
          <Col xs={12} className="mt-3 pl-lg-0">
            <MainHeadline
              topline="OFFER"
              text={[
                <span className="lh-3">
                  Thank you for using our <br /> LIGEA® services
                </span>,
              ]}
              SubLineSpace={39}
              subline={[
                <span>
                  We will send all of your information to the e-mail address{' '}
                  <span className="font-weight-bold">{emailAddress}</span>.
                </span>,
              ]}
            />
            <Row>
              <Spacing space={49} />
            </Row>

            <Col xs={12} className="startPage-buttons mt-5">
              <a href="https://ligea.com">
                <BtnGhost content="To the LIGEA® homepage" />
              </a>

              <BtnPrimary
                trigger
                triggerme={() => {
                  const checkStorage = localStorage.getItem('ligeaForm');
                  if (checkStorage) {
                    localStorage.removeItem('ligeaForm');
                    setTimeout(() => {
                      window.location.href = '/';
                    }, 1000);
                  } else {
                    window.location.href = '/';
                  }
                }}
                content="Restart the LIGEA® assistant."
              />
            </Col>
            <Row>
              <Spacing space={69} />
            </Row>
            <Row>
              <Col xs={12} className="mt-4">
                <Col xs={12} className="pl-lg-0">
                  {colorBox}
                </Col>
              </Col>
            </Row>
          </Col>
          <Row>
            <Spacing space={139} />
          </Row>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OfferServiceCompletedComponent);
