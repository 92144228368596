import React from 'react';
import Topline from './topline';

export const H1 = (props) => {
  const { topline, text } = props;
  return (
    <h1>
      <Topline topline={topline} />
      <span>{text}</span>
    </h1>
  );
};

export const H2 = (props) => {
  const { topline, text } = props;
  return (
    <h2>
      <Topline topline={topline} />
      {text}
    </h2>
  );
};

export const H3 = (props) => {
  const { topline, text } = props;
  return (
    <h3>
      <Topline topline={topline} />
      {text}
    </h3>
  );
};

export const H4 = (props) => {
  const { topline, text } = props;
  return (
    <h4>
      <Topline topline={topline} />
      {text}
    </h4>
  );
};

export const SubLine = (props) => {
  const { subline } = props;
  return <p className="subline">{subline}</p>;
};

export const CpLarge = (props) => {
  const { topline, text } = props;
  return (
    <p className="cp-l">
      <Topline topline={topline} />
      {text}
    </p>
  );
};

export const CpMedium = (props) => {
  const { topline } = props;
  return (
    <p className="cp-m">
      <Topline topline={topline} />
      Messina Sans 25/48pt, Light. Ligea realises the exact carpet design you
      envision for your major project, offers the most fitting product for your
      needs and provides you with recommendations and all relevant information
      within minutes. Ligea realises the exact carpet design you envision for
      your major project, offers the most fitting product for your needs and
      provides you with recommendations and all relevant information within
      minutes.
    </p>
  );
};

export const CpSmall = (props) => {
  const { topline, text } = props;
  return (
    <p className="cp-s">
      <Topline topline={topline} />
      {text}
    </p>
  );
};
