import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';

import { addForm, resetForm } from '../../../reducer/actions';

import Spacing from '../../atoms/spacing/spacing';
import { BtnGhost } from '../../atoms/buttons/buttons';
import { MainHeadline } from '../../molecules/typography/heading';

import ColorContentBox from '../../molecules/banner/ColorContentBox';

class BriefOFferCompletedComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storageCompleted: false,
      link: false,
    };
  }

  componentDidMount() {
    const { addForm } = this.props;
    addForm({ sidebarNextEnable: false });
    addForm({ nextStepSidebarText: 'Next' });
    addForm({ backBtn: false });
    addForm({ hideSidebar: true });
    // addForm({ hideSidebar: true });

    addForm({ offer_service_completed: true });

    const checkStorage = localStorage.getItem('ligeaForm');
    if (checkStorage) {
      localStorage.removeItem('ligeaForm');
      this.setState({
        storageCompleted: true,
      });
    }
    this.sendOrderToCRM();
  }

  sendOrderToCRM() {
    const { form, uploadInspirationFiles, addForm } = this.props;
    const { forms } = form;

    addForm({ Steps: [] });

    const postData = new FormData();

    Object.keys(forms).forEach((key) => {
      postData.append(key, JSON.stringify(forms[key]));
    });

    if (uploadInspirationFiles.length > 0) {
      uploadInspirationFiles.forEach((file, i) => {
        postData.append(`files[${i}]`, file);
      });
    }

    const url = `${forms.apiUrl}/api/v1/briefs`;
    axios
      .post(url, postData, {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      })
      .then((res) => {
        axios.post(
          'https://flow.zoho.eu/20076121788/flow/webhook/incoming?zapikey=1001.0c4edb7f0c1935e7b30ab504abd665fe.5a59151dce284e11a616d1244372fdd7&isdebug=false',
          postData,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        fetch(
          'https://flow.zoho.eu/20076121788/flow/webhook/incoming?zapikey=1001.0c4edb7f0c1935e7b30ab504abd665fe.5a59151dce284e11a616d1244372fdd7&isdebug=false',
          {
            body: postData,
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
            }
          }
        );

        if (res.data) {
          this.setState({
            link: res.data,
          });
        }
      });
  }

  triggerme(type) {
    const { addForm } = this.props;

    addForm({ HowCanWeHelpYou: type });
    addForm({ currentStep: type });
  }

  render() {
    const { storageCompleted, link } = this.state;
    const { form } = this.props;
    const { forms } = form;
    const { emailAddress } = forms;

    let colorBox = '';
    if (!storageCompleted) {
      colorBox = (
        <ColorContentBox
          topline="How would you like to proceed?"
          text="We turn your Creative Brief into an individual design concept."
          content={[
            <span>
              Our LIGEA® designers will support you and create an individual
              design concept based on your vision.
            </span>,
          ]}
          buttonActive={1}
          btnContent="Request individual design concept"
          triggerparent={() => {
            // this.triggerme(2);
            this.triggerme('upload_files');
          }}
        />
      );
    }

    let linkWrapper = '';
    if (link) {
      const linkDynamic = `${window.location.href}order/${link}`;
      linkWrapper = (
        <a target="_blank" rel="noreferrer" href={linkDynamic}>
          {linkDynamic}
        </a>
      );
    }

    return (
      <Container fluid className="p-0 m-0">
        <Row>
          <Col xs={12} className="mt-3 pl-lg-0">
            <MainHeadline
              topline="OFFER"
              text={[
                <span className="lh-3">
                  Thank you for using our <br /> LIGEA® services
                </span>,
              ]}
              SubLineSpace={39}
              subline={[
                <span>
                  With this link you can download and print your creative brief:
                  <br />
                  {linkWrapper}
                  {/* <a className="orangec" href={link}> */}
                  {/* {link} */}
                  {/* </a> */}
                  <br />
                  We will also sent you the link to your e-mail address
                  <span className="font-weight-bold"> {emailAddress}</span>.
                </span>,
              ]}
            />
            <Row>
              <Spacing space={49} />
            </Row>

            <Col xs={12} className="startPage-buttons mt-5 pl-lg-0">
              <a href="https://ligea.com">
                <BtnGhost content="To the LIGEA® homepage" />
              </a>
            </Col>
            <Row>
              <Spacing space={69} />
            </Row>

            <Row>
              <Col xs={12} className="mt-4">
                <Col xs={12} className="pl-lg-0">
                  {colorBox}
                </Col>
              </Col>
            </Row>
          </Col>
          <Row>
            <Spacing space={139} />
          </Row>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
    resetForm: (form) => dispatch(resetForm(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BriefOFferCompletedComponent);
