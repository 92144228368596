import React from 'react';

const InfoIconSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="31"
    viewBox="0 0 31 31"
  >
    <g fill="none" stroke="#1f1d1a" strokeWidth="2px">
      <rect width="31" height="31" stroke="none" rx="5" />
      <rect width="29" height="29" x="1" y="1" rx="4" />
    </g>
    <path
      fill="#1f1d1a"
      d="M4.212 16V7.328H2.788V16zm.08-12.352H2.708V5.36h1.584z"
      transform="translate(12.292 5.6)"
    />
  </svg>
);
export default InfoIconSVG;
