import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import validator from 'validator';
import Input from '../../atoms/forms/input';

import { MainHeadlineSmall } from '../../molecules/typography/heading';
import Spacing from '../../atoms/spacing/spacing';
import { addForm } from '../../../reducer/actions';

import UploadArrowICO from '../../atoms/image/upload-arrow';
import UploadCircleICO from '../../atoms/image/UploadingCircle';
import PictureUpload from '../../atoms/image/PictureUpload';

class UploadInspirationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inspirationfile: '',
      currentFile: '',
      pics: [],
    };
  }

  componentDidMount() {
    const { addForm, form } = this.props;
    const { forms } = form;

    addForm({ nextStepSidebarText: 'Next' });

    addForm({ design_concept: 1 });
    addForm({ sidebarNextEnable: false });

    if (forms.inspirationPics) {
      addForm({ sidebarNextEnable: true });
      addForm({ nextStepSidebar: 'upload_files' });

      const { pics } = this.state;
      forms.inspirationPics.map((value, index) => {
        const file = {
          file_store: value.file_store,
          fileName: value.file_store.name,
          file_location: value.file_store.value,
          input_name: value.file_store.name,
        };
        pics.push(file);
        if (index + 1 === forms.inspirationPics.length) {
          this.setState({
            pics,
          });
          if (pics.length < 1) {
            addForm({ sidebarNextEnable: false });
          } else {
            addForm({ sidebarNextEnable: true });
            const checkStorage = localStorage.getItem('ligeaForm');
            if (checkStorage) {
              addForm({ nextStepSidebar: 'overview_information' });
            }
            addForm({ hideSidebar: false });
          }
        }
        return false;
      });
    }
  }

  handleFile(e) {
    if (e.target.files[0]) {
      const { pics } = this.state;

      const file = {
        file_store: e.target.files[0],
        fileName: e.target.files[0].name,
        file_location: e.target.value,
        input_name: e.target.name,
      };

      this.setState({
        currentFile: file,
        currentAction: 'uploading',
      });
      // setTimeout(() => {
      pics.push(file);
      this.setState({
        pics,
        currentFile: '',
        currentAction: false,
      });

      const { addForm, fileUploadState } = this.props;

      addForm({ inspirationPics: pics });

      fileUploadState(e.target.files[0], 'insertion');

      if (pics.length < 1) {
        addForm({ sidebarNextEnable: false });
      } else {
        addForm({ sidebarNextEnable: true });
        const checkStorage = localStorage.getItem('ligeaForm');
        if (checkStorage) {
          addForm({ nextStepSidebar: 'overview_information' });
        } else {
          addForm({ nextStepSidebar: 'upload_files' });
        }
        addForm({ hideSidebar: false });
      }
      // }, 2500);
    }
  }

  handleDeletion(delFile) {
    const { pics } = this.state;

    const newPics = [];
    // if (pics.length > 0) {
    pics.map((value, index) => {
      if (value.fileName !== delFile.fileName) {
        newPics.push(value);
      }
      if (index + 1 === pics.length) {
        const { addForm, fileUploadState } = this.props;

        fileUploadState(delFile.fileName, 'remove');

        if (newPics.length < 1) {
          addForm({ sidebarNextEnable: false });
        } else {
          addForm({ sidebarNextEnable: true });
          addForm({ nextStepSidebar: 'upload_files' });
        }
        this.setState({
          pics: newPics,
        });
        addForm({ inspirationPics: newPics });
      }
      return false;
    });
    // }
  }

  triggerme(type) {
    const { addForm } = this.props;

    addForm({ HowCanWeHelpYou: type });
    addForm({ currentStep: type });
  }

  render() {
    // prettier-ignore
    const {
      pics,
      inspirationfile,
      currentFile,
      currentAction
    } = this.state;

    const rows = [];
    if (pics.length > 0) {
      pics.map((value, index) => {
        rows.push(
          <Col xs={12} lg={4} key={index.toString()}>
            <PictureUpload
              deleteFile={(delFile) => {
                this.handleDeletion(delFile);
              }}
              file={value}
            />
          </Col>
        );
        return false;
      });
    }

    let fileName = '';
    if (currentFile.fileName) {
      fileName = currentFile.fileName;
    }

    let fileVal = null;
    if (!currentFile.file_store) {
      fileVal = currentFile.file_store;
    }
    let actionIcon = <UploadArrowICO />;
    if (currentAction === 'uploading') {
      actionIcon = <UploadCircleICO />;
    }

    const { form } = this.props;
    const { forms } = form;

    return (
      <Container fluid className="uploadInspiration-container m-0">
        <Row>
          <Col xs={12} className="p-lg-0">
            <form action="" className="form">
              <MainHeadlineSmall
                topline="DESIGN SUPPORT - UPLOAD INSPIRATION"
                text="Upload you inspiration for our designers"
                subline="Our designers will use your upload as an inspiration and create a new design for you based on your vision."
              />
              <Row>
                <Spacing space={39} />
              </Row>
              <Row>
                <Col xs={12} className="mt-4">
                  <Row>
                    <Col lg={4} xs={12}>
                      <h5 className="font-weight-light form-description mt-4">
                        Choose your file to upload (jpg,png,pdf)
                      </h5>
                    </Col>
                    <Col lg={4} xs={12} className="upload-row">
                      <div className="form-item ">
                        <input
                          type="file"
                          name="inspirationfile"
                          value={fileVal}
                          onChange={this.handleFile.bind(this)}
                          validator={validator.isEmpty(inspirationfile)}
                          required
                        />
                        <label htmlFor="inspirationfile">Upload File</label>
                        {fileName}
                        <div className="upload-ico">{actionIcon}</div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Row>
                  <Spacing space={39} />
                </Row>
                <Col xs={12}>
                  <Col xs={12} className="pictures">
                    <Row>{rows}</Row>
                  </Col>
                </Col>
                <Row>
                  <Spacing space={39} />
                </Row>
                <Col xs={12}>
                  <h4>
                    Is there anything we need to know about your inspiration ?
                  </h4>
                  <Col xs={12} className="uploadInspiration-info">
                    <Row>
                      <Spacing space={25} />
                    </Row>
                    <Row>
                      <Col lg={4} xs={12} className="p-mob-0">
                        <h5 className="font-weight-light form-description mt-4">
                          Tell us, what&apos;s important to you.
                        </h5>
                      </Col>
                      <Col lg={4} xs={12} className="p-mob-0">
                        <Input
                          name="ImportantAboutInspiration"
                          type="text"
                          label="What would you like to add?"
                          reducer
                          value={forms.ImportantAboutInspiration}
                          // error={validator.isEmpty(ImportantAboutInspiration)}
                          // errorContent="Please Fill Job Title"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Col>
              </Row>
              <Row>
                <Spacing space={39} />
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadInspirationComponent);
