import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

// import Spacing from '../../../atoms/spacing/spacing';
import { BtnPrimaryWhite } from '../../atoms/buttons/buttons';
import Spacing from '../../atoms/spacing/spacing';

class ColorContentBox extends Component {
  render() {
    // prettier-ignore
    const {
      topline,
      text,
      content,
      buttonActive,
      btnContent,
      triggerparent
    } = this.props;

    let buttonRow = '';
    if (buttonActive) {
      buttonRow = (
        <BtnPrimaryWhite
          content={btnContent}
          trigger
          triggerme={() => {
            triggerparent(1);
          }}
        />
      );
    }
    return (
      <Row className="colorcontent-box">
        <Col xs={12}>
          <Col xs={12}>
            <h5 className="topline">{topline}</h5>
            <h2 className="text">{text}</h2>
            <h4 className="font-weight-light content">{content}</h4>

            <Row>
              <Spacing space={69} />
            </Row>
            {buttonRow}
          </Col>
        </Col>
      </Row>
    );
  }
}

export default ColorContentBox;
