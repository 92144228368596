import React from 'react';

export const RoundedImage = (props) => {
  const { image, name, extraClass } = props;
  return (
    <img
      src={image}
      alt={name}
      className={`${extraClass} rounded-circle img-fluid`}
    />
  );
};

export default RoundedImage;
