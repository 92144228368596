import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import { addForm } from '../../../reducer/actions';

import { BtnPrimary } from '../../atoms/buttons/buttons';

class SideBarNextBtn extends Component {
  render() {
    // prettier-ignore
    const {
      addForm,
      form
    } = this.props;

    // prettier-ignore
    const {
      forms
    } = form;

    // prettier-ignore
    const {
      // roomTypeSelection,
      hideSidebar,
      sidebarNextEnable,
      nextStepSidebar,
      nextStepSidebarText,
      fullButtonSize,
      currentStep,
      customRole,
      ProjectCategory
    } = forms;

    let nextStep = '';
    // if (sidebarNextEnable) {
    let NextText = 'Next';
    if (nextStepSidebarText) {
      NextText = nextStepSidebarText;
    }

    const excludeNext = [
      'brief_completed_offer',
      'How Can We Help You',
      'design_support',
      'brief_tool_or_upload_inspiration',
    ];
    if (currentStep) {
      if (currentStep === 'upload_files' && !ProjectCategory) {
        return <div className="align-bottom nextstep_wrapper row" />;
      }
      if (!hideSidebar && !excludeNext.includes(currentStep)) {
        if ((forms.inspirationPics && currentStep === 'products_and_prices') || (forms.colorsSelective && currentStep === 'products_and_prices')) {
          nextStep = (
            <Col
              xs={12}
              className={`bottom-buttons pr-0 ${
                fullButtonSize ? 'full-size' : ''
              }`}
              onClick={() => {
                addForm({ currentStep: 'area_specification_details' });
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
              }}
            >
              <Col xs={12}>
                <BtnPrimary content={NextText} />
              </Col>
            </Col>
          );
        } else {
          nextStep = (
            <Col
              xs={12}
              className={`bottom-buttons pr-0 ${
                fullButtonSize ? 'full-size' : ''
              }`}
              onClick={() => {
                if (sidebarNextEnable) {
                  addForm({ currentStep: nextStepSidebar });
                  if (customRole) {
                    addForm({ role: nextStepSidebar });
                  }
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                }
              }}
            >
              <Col xs={12}>
                <BtnPrimary content={NextText} disabled={!sidebarNextEnable} />
              </Col>
            </Col>
          );
        }
      }
    }
    return <div className="align-bottom nextstep_wrapper row">{nextStep}</div>;
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SideBarNextBtn);
