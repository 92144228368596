import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';
import axios from 'axios';

import LoadingAnimation from '../../../atoms/forms/LoadingAnimation';

import PictureSelectionComponent from '../../../atoms/image/PictureSelection';
import { MainHeadlineSmall } from '../../../molecules/typography/heading';

import { addForm } from '../../../../reducer/actions';
import Spacing from '../../../atoms/spacing/spacing';

// import Patterns from '../../../../assets/products/Patterns';

class PatternSelectiveComponent extends Component {
  constructor(props) {
    super(props);
    const { form } = this.props;
    const { forms } = form;
    const { patternSelective } = forms;

    let interiorSelection = [];
    if (patternSelective) {
      interiorSelection = patternSelective;
    }

    this.state = {
      selectedRows: interiorSelection,
      categories: [],
      categorySelected: [],
      Patterns: [],
    };
  }

  componentDidMount() {
    const { addForm, form } = this.props;
    const { forms } = form;

    const { Patterns } = this.state;

    if (Patterns.length < 1) {
      const url = `${forms.apiUrl}/api/v1/patterns`;
      axios.get(url).then((res) => {
        const { data } = res;
        if (data) {
          data.map((pattern, index) => {
            const { patternCategories } = pattern;
            this.getCategories(patternCategories);
            if (index + 1 === data.length) {
              this.setState({
                Patterns: data,
              });
            }
            return false;
          });
        }
      });
    }

    addForm({ nextStepSidebarText: 'Next' });
    const { selectedRows } = this.state;
    const st = selectedRows;
    if (st.length > 0) {
      addForm({ sidebarNextEnable: true });
      addForm({ nextStepSidebar: 'color_selective' });
    } else {
      addForm({ sidebarNextEnable: false });
      addForm({ nextStepSidebar: false });
    }

    addForm({ backBtn: 'interior_selective' });
  }

  handleClick(type) {
    const { addForm } = this.props;
    addForm({ currentStep: type });
  }

  handlePicClick(e) {
    const { addForm } = this.props;

    const { selectedRows } = this.state;
    const st = selectedRows;

    if (st.length > 0) {
      let exist = false;
      st.map((value, index) => {
        if (value[0] === e[0]) {
          exist = true;
          st.splice(index, 1);
        }

        if (index + 1 === st.length) {
          if (!exist) {
            st.push(e);
          }
        }
        return false;
      });
    } else {
      st.push(e);
    }

    this.setState({
      selectedRows: st,
    });

    if (st.length > 0) {
      addForm({ sidebarNextEnable: true });
      addForm({ nextStepSidebar: 'color_selective' });
    } else {
      addForm({ sidebarNextEnable: false });
      addForm({ nextStepSidebar: false });
    }

    addForm({ patternSelective: st });
  }

  getCategories(patternCategories) {
    const { categories } = this.state;

    patternCategories.map((category) => {
      const { title } = category;
      if (!categories.includes(title)) {
        categories.push(title);
      }
      return category;
    });
  }

  render() {
    // prettier-ignore
    const {
      selectedRows,
      Patterns,
      categories,
      categorySelected
    } = this.state;

    const categorySelector = [];
    if (categories.length > 0) {
      const localArr = [];
      categories.map((category, catindex) => {
        localArr.push(category);
        if (catindex + 1 === categories.length) {
          categorySelector.push(
            <div className="multiselect" key={catindex.toString()}>
              <DropdownMultiselect
                options={localArr}
                name="categories"
                handleOnChange={(selected) => {
                  this.setState({
                    categorySelected: selected,
                  });
                }}
                placeholder="Categories"
              />
            </div>
          );
        }
        return false;
      });
    }

    let picList = [];
    if (Patterns.length > 0) {
      Patterns.map((pattern, ptindex) => {
        const { patternCategories, image } = pattern;
        const imageUrl = image ? { url: image } : false;

        patternCategories.map((value) => {
          if (categorySelected.length > 0) {
            const includes = categorySelected.includes(value.title);
            if (includes) {
              const st = selectedRows;
              if (st.length > 0) {
                let exist = false;
                st.map((value, index) => {
                  if (value[0] === pattern.id) {
                    exist = true;
                  }
                  if (index + 1 === st.length) {
                    picList.push(
                      <Col
                        key={ptindex.toString()}
                        lg={4}
                        xs={6}
                        className="mb-5 pl-lg-0"
                      >
                        <PictureSelectionComponent
                          label={`${pattern.title}`}
                          isSelected={exist}
                          select
                          image={imageUrl}
                          id={pattern.id}
                          name={`${pattern.title}`}
                          triggerclick={(e) => {
                            this.handlePicClick(e);
                          }}
                        />
                      </Col>
                    );
                  }
                  return false;
                });
              } else {
                picList.push(
                  <Col
                    key={ptindex.toString()}
                    lg={4}
                    xs={6}
                    className="mb-5 pl-lg-0"
                  >
                    <PictureSelectionComponent
                      label={`${pattern.title}`}
                      select
                      image={imageUrl}
                      id={pattern.id}
                      name={`${pattern.title}`}
                      triggerclick={(e) => {
                        this.handlePicClick(e);
                      }}
                    />
                  </Col>
                );
              }
            }
          } else {
            const st = selectedRows;
            if (st.length > 0) {
              let exist = false;
              st.map((value, index) => {
                if (value[0] === pattern.id) {
                  exist = true;
                }
                if (index + 1 === st.length) {
                  picList.push(
                    <Col
                      key={ptindex.toString()}
                      lg={4}
                      xs={6}
                      className="mb-5 pl-lg-0"
                    >
                      <PictureSelectionComponent
                        limit={3}
                        alertInfo="Only 3 selection are limited"
                        counter="patternSelective"
                        label={`${pattern.title}`}
                        isSelected={exist}
                        select
                        image={imageUrl}
                        id={pattern.id}
                        name={`${pattern.title}`}
                        triggerclick={(e) => {
                          this.handlePicClick(e);
                        }}
                      />
                    </Col>
                  );
                }
                return false;
              });
            } else {
              picList.push(
                <Col
                  key={ptindex.toString()}
                  lg={4}
                  xs={6}
                  className="mb-5 pl-lg-0"
                >
                  <PictureSelectionComponent
                    limit={3}
                    alertInfo="Only 3 selection are limited"
                    counter="patternSelective"
                    label={`${pattern.title}`}
                    select
                    image={imageUrl}
                    id={pattern.id}
                    name={`${pattern.title}`}
                    triggerclick={(e) => {
                      this.handlePicClick(e);
                    }}
                  />
                </Col>
              );
            }
          }

          return false;
        });

        return false;
      });
    } else {
      picList = <LoadingAnimation />;
    }
    return (
      <Container fluid className="choiceRole-container p-0 m-0">
        <Row>
          <Col xs={12}>
            <Col xs={12} className="pl-lg-0 p-mob-0">
              <MainHeadlineSmall
                topline="CREATIVE BRIEF TOOL"
                text="What pattern do you like?"
                subline="Choose the kind of pattern which is most appealing to you. Pick up to 3 pattern inspirations."
              />
            </Col>
            <Col className=" p-mob-0">
              <Row>
                <Col xs={12} md={4} className="pl-lg-0 mt-3">
                  {categorySelector}
                </Col>
              </Row>
              <Row>
                <Spacing space={39} />
              </Row>
              <Col xs={12} className="pl-lg-0 p-mob-0">
                <Row>{picList}</Row>
                <Row className="d-none d-md-block">
                  <Spacing space={39} />
                </Row>
              </Col>
            </Col>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatternSelectiveComponent);
