import React, { Component } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';

import DefaultImage from '../../../assets/images/product-selection-blank.png';

import InfoBoxComponent from '../../atoms/infoBox';
import { MainHeadlineSmall } from '../typography/heading';
import Topline from '../../atoms/typography/topline';
import { BtnTransparentGhost } from '../../atoms/buttons/buttons';
import Spacing from '../../atoms/spacing/spacing';

class ProductSelectionComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelected: false,
      updateComp: false,
      showModal: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const { isSelected } = this.props;
    const { updateComp } = this.state;

    if (isSelected) {
      this.setState({
        isSelected,
        updateComp: !updateComp,
      });
    }
  }

  handleClick() {
    // prettier-ignore
    const {
      select,
      triggerclick,
      id,
      name
    } = this.props;
    const { isSelected } = this.state;

    if (select) {
      this.setState({
        isSelected: !isSelected,
      });
      this.forceUpdate();
    }
    if (triggerclick) {
      triggerclick([id, !name ? '' : name]);
    }
  }

  render() {
    // prettier-ignore
    const {
      label,
      extraClass,
      image,
      // MainIcon,
      price,
      triggerclick,
      productId,
      product,
      currency
    } = this.props;

    const { isSelected, showModal } = this.state;

    const url = !image ? DefaultImage : image;
    const alt = image ? image.alt : '';

    // let MainIconRow = '';
    let titleRow = '';
    if (label) {
      // if (MainIcon) {
      // MainIconRow = (
      // <InfoBoxComponent question={0} info="Product informations." />
      // );
      // }
      titleRow = (
        <div className="label-pic-selection">
          <div className="label">{label}</div>
          <button
            type="button"
            className="button btn-transparent btn"
            onClick={() => {
              this.setState({
                showModal: true,
              });
            }}
          >
            <InfoBoxComponent question={0} />
          </button>
        </div>
      );
    }
    let extraClassRow = '';
    if (extraClass) {
      extraClassRow += ` ${extraClass}`;
    }
    if (isSelected) {
      extraClassRow += ' selected';
    }

    const PriceRows = [];
    if (price) {
      price.map((priceC) => {
        priceC.productImage = product.productImage;
        const key = priceC.variantType.value;

        let info = '';
        if (priceC.question) {
          info = <InfoBoxComponent question={1} info="Product informations." />;
        }
        if (isSelected.length > 0) {
          let checkedVal = false;
          let wrapClass = '';
          isSelected.map((value, childindex) => {
            if (priceC.prices) {
              if (!priceC.prices.priceEur) {
                return false;
              }
            }
            if (priceC.id === value.id) {
              const Valuekey = priceC.variantType.value;
              if (Valuekey === key) {
                checkedVal = true;
                wrapClass = 'row-checked';
              }
            }

            let priceDisplay = '';
            let displayVariant = true;
            if (priceC.variantType.value === 'entireProject') {
              priceDisplay = 'ENTIRE';
              if (priceC.prices) {
                // prettier-ignore
                priceDisplay = currency === 'eur' ? priceC.prices.priceDisplayEur : priceC.prices.priceDisplayUsd;
              } else {
                displayVariant = false;
              }
            } else {
              // prettier-ignore
              priceDisplay = currency === 'eur' ? priceC.priceDisplayEur : priceC.priceDisplayUsd;
            }
            if (childindex + 1 === isSelected.length && displayVariant) {
              const showPrices = (
                <li key={`key_${productId}-${key}`}>
                  <div className="name_row condensed">
                    <span className="name">{priceC.variantType.label}</span>
                    <span className="info">{info}</span>
                  </div>
                  <div className={`price orange-checkbox ${wrapClass}`}>
                    <input
                      type="checkbox"
                      name="price_selection"
                      checked={checkedVal}
                      id={`key_${productId}-${key}`}
                      value={`key_${key}`}
                      onClick={() => {
                        triggerclick([productId, priceC]);
                      }}
                    />
                    <label
                      htmlFor={`key_${productId}-${key}`}
                      className="condensed"
                    >
                      {priceDisplay}
                    </label>
                  </div>
                </li>
              );
              PriceRows.push(showPrices);
            }
            return value;
          });
        } else {
          let displayVariant = true;
          let priceDisplay = '';
          if (priceC.variantType.value === 'entireProject') {
            priceDisplay = 'ENTIRE';
            // prettier-ignore
            if (priceC.prices.priceEur && priceC.prices.priceEur !== undefined) {
              if (priceC.prices) {
                // prettier-ignore
                priceDisplay = currency === 'eur' ? priceC.prices.priceDisplayEur : priceC.prices.priceDisplayUsd;
              } else {
                displayVariant = false;
              }
            } else {
              displayVariant = false;
            }
          } else {
            // prettier-ignore
            priceDisplay = currency === 'eur' ? priceC.priceDisplayEur : priceC.priceDisplayUsd;
          }

          if (displayVariant) {
            const showPrices = (
              <li key={`key_${productId}-${key}`}>
                <div className="name_row condensed">
                  <span className="name">{priceC.variantType.label}</span>
                  <span className="info">{info}</span>
                </div>
                <div className="price orange-checkbox">
                  <input
                    type="checkbox"
                    name="price_selection"
                    id={`key_${productId}-${key}`}
                    value={`key_${key}`}
                    onClick={() => {
                      triggerclick([productId, priceC]);
                    }}
                  />
                  <label
                    htmlFor={`key_${productId}-${key}`}
                    className="condensed"
                  >
                    {priceDisplay}
                  </label>
                </div>
              </li>
            );
            PriceRows.push(showPrices);
          }
        }

        return priceC;
      });
    }
    let modalWrapper = '';
    if (showModal) {
      const modalRows = [];
      let singleModalRow = [];
      const { productModal } = product;
      let counter = 0;
      productModal.map((value) => {
        counter += 1;
        singleModalRow.push(
          <Col lg={6} xs={12}>
            <Topline topline={value.productModalTitle} />
            <p className="font-weight-light">{value.productModalValue}</p>
          </Col>
        );
        if (counter === 2) {
          modalRows.push(
            <div>
              <Row>{singleModalRow}</Row>
              <Row>
                <Spacing space={29} />
              </Row>
            </div>
          );
          counter = 0;
          singleModalRow = [];
        }
        return value;
      });
      modalWrapper = (
        <Modal
          className="confirm-modal blue-modal product-modal"
          show
          backdrop="static"
          keyboard={false}
        >
          <button
            className="btn btn-transparent"
            type="button"
            onClick={() => {
              this.setState({
                showModal: false,
              });
            }}
          >
            <span className="close-overlay-icon" />
          </button>
          <Modal.Body>
            <Row>
              <Col lg={6} xs={12} className="text-center">
                <img src={url} alt={alt} className="img-fluid" />
              </Col>
              <Col lg={6} xs={12}>
                <MainHeadlineSmall
                  topline={product.topline}
                  text={product.title}
                  subline={product.subline}
                />
                <Row>
                  <Spacing space={39} />
                </Row>
                {modalRows}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <a href={product.downloadFile} rel="noreferrer" target="_blank">
              <BtnTransparentGhost content="Download Specs Sheet" />
            </a>
          </Modal.Footer>
        </Modal>
      );
    }

    return (
      <Col xs={12} className={`product-selection ${extraClassRow}`}>
        <img src={url} alt={alt} className="img-fluid" />
        {titleRow}
        <ul className="price_rows">{PriceRows}</ul>
        {modalWrapper}
      </Col>
    );
  }
}

export default ProductSelectionComponent;
