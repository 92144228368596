import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';

import LoadingAnimation from '../../../atoms/forms/LoadingAnimation';

import PictureSelectionComponent from '../../../atoms/image/PictureSelection';
import { MainHeadlineSmall } from '../../../molecules/typography/heading';

import { addForm } from '../../../../reducer/actions';
import Spacing from '../../../atoms/spacing/spacing';

class InteriorSelectiveComponent extends Component {
  constructor(props) {
    super(props);
    const { form } = this.props;
    const { forms } = form;
    const { interiorSelective } = forms;

    let interiorSelection = [];
    if (interiorSelective) {
      interiorSelection = interiorSelective;
    }

    this.state = {
      selectedRows: interiorSelection,
      interiorRow: [],
    };
  }

  componentDidMount() {
    const { addForm, form } = this.props;
    const { forms } = form;

    const { selectedRows, interiorRow } = this.state;

    if (interiorRow.length < 1) {
      const rows = [];
      const url = `${forms.apiUrl}/api/v1/interiors`;
      axios.get(url).then((res) => {
        const { data } = res;
        if (data) {
          data.map((value, index) => {
            rows.push(value);
            if (index + 1 === data.length) {
              this.setState({
                interiorRow: rows,
              });
            }
            return value;
          });
        }
      });
    }

    addForm({ nextStepSidebarText: 'Next' });
    const st = selectedRows;
    if (st.length > 0) {
      addForm({ sidebarNextEnable: true });
      addForm({ nextStepSidebar: 'pattern_selective' });
    } else {
      addForm({ sidebarNextEnable: false });
      addForm({ nextStepSidebar: false });
    }
    addForm({ backBtn: 'brief_tool_1' });
  }

  handleClick(type) {
    const { addForm } = this.props;
    addForm({ currentStep: type });
  }

  handlePicClick(e) {
    const { addForm } = this.props;

    const { selectedRows } = this.state;
    const st = selectedRows;

    if (st.length > 0) {
      let exist = false;
      st.map((value, index) => {
        if (value[0] === e[0]) {
          exist = true;
          st.splice(index, 1);
        }

        if (index + 1 === st.length) {
          if (!exist) {
            st.push(e);
          }
        }
        return false;
      });
    } else {
      st.push(e);
    }

    this.setState({
      selectedRows: st,
    });

    if (st.length > 0) {
      addForm({ sidebarNextEnable: true });
      addForm({ nextStepSidebar: 'pattern_selective' });
    } else {
      addForm({ sidebarNextEnable: false });
      addForm({ nextStepSidebar: false });
    }

    addForm({ interiorSelective: st });
  }

  render() {
    const { selectedRows, interiorRow } = this.state;

    let picList = [];
    if (interiorRow.length > 0) {
      interiorRow.map((interiorValue) => {
        const { title, image } = interiorValue;
        const imageUrl = image ? { url: image } : false;

        const st = selectedRows;

        if (st.length > 0) {
          let exist = false;
          st.map((value, index) => {
            if (value[0] === interiorValue.id) {
              exist = true;
            }

            if (index + 1 === st.length) {
              picList.push(
                <Col
                  key={interiorValue.id.toString()}
                  lg={4}
                  xs={6}
                  className="mb-5 pl-lg-0"
                >
                  <PictureSelectionComponent
                    limit={3}
                    alertInfo="Only 3 selection are limited"
                    counter="interiorSelective"
                    label={title}
                    isSelected={exist}
                    image={imageUrl}
                    select
                    id={interiorValue.id}
                    name={title}
                    triggerclick={(e) => {
                      this.handlePicClick(e);
                    }}
                  />
                </Col>
              );
            }
            return false;
          });
        } else {
          picList.push(
            <Col
              key={interiorValue.id.toString()}
              lg={4}
              xs={6}
              className="mb-5 pl-lg-0"
            >
              <PictureSelectionComponent
                label={title}
                select
                id={interiorValue.id}
                image={imageUrl}
                name={title}
                triggerclick={(e) => {
                  this.handlePicClick(e);
                }}
              />
            </Col>
          );
        }

        return interiorValue;
      });
    } else {
      picList = <LoadingAnimation />;
    }

    return (
      <Container fluid className="choiceRole-container p-0 m-0">
        <Row>
          <Col xs={12} className="">
            <Col xs={12} className="pl-lg-0 p-mob-0">
              <MainHeadlineSmall
                topline="CREATIVE BRIEF TOOL"
                text="What interior do you prefer?"
                subline="Pick the type of interior you prefere. Choose up to 3 interor styles."
              />
            </Col>
            <Col className=" p-mob-0">
              <Row>
                <Spacing space={39} />
              </Row>
              <Col xs={12} className="pl-lg-0 p-mob-0">
                <Row>{picList}</Row>
                <Row className="d-none d-md-block">
                  <Spacing space={39} />
                </Row>
              </Col>
            </Col>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InteriorSelectiveComponent);
