import React, { Component } from 'react';
import { connect } from 'react-redux';

import { addForm } from '../../../reducer/actions';

class ToggleRadioComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ajaxError:
        'There was a server error the prevented the form from submitting.',
    };
  }

  componentDidMount() {
    const st = this.state;
    const { value, name } = this.props;

    if (value) {
      const evt = document.createEvent('HTMLEvents');
      evt.initEvent('change', false, true);
      const element = document.getElementById(`id_${name}`);
      element.checked = true;
    } else {
      st[name] = '';
    }
    // this.setState({
    //   st,
    // });
    this.setState((prevState) => ({ ...prevState, st }));
  }

  handleInputChange(event) {
    // prettier-ignore
    const {
      reducer,
      addForm,
      parentupdate
    } = this.props;

    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    // this.setState({
    //   [name]: value,
    // });
    // this.setState((prevState) => ({ ...prevState, value }));

    if (reducer) {
      addForm({
        [name]: value,
      });
      if (parentupdate) {
        parentupdate(event);
      }
    }
  }

  render() {
    // prettier-ignore
    const {
      name,
      title,
      required,
      value
    } = this.props;

    return (
      <div className="custom-control custom-switch">
        <input
          className="custom-control-input"
          id={`id_${name}`}
          type="checkbox"
          value={value}
          name={name}
          onChange={this.handleInputChange.bind(this)}
          required={required}
        />
        <label
          className="custom-control-label checkbox-label condensed"
          htmlFor={`id_${name}`}
        >
          {title}
        </label>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToggleRadioComponent);
