import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import { addForm } from '../../../reducer/actions';

import Spacing from '../../atoms/spacing/spacing';
import CardsComponent from '../../molecules/cards/cards';
import CardsUploadComponent from '../../molecules/cards/CardUploads';
import { MainHeadlineSmall } from '../../molecules/typography/heading';

class DesignSupportComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { addForm } = this.props;

    addForm({ design_concept: 0 });

    const checkStorage = localStorage.getItem('ligeaForm');
    if (!checkStorage) {
      addForm({ backBtn: 'How Can We Help You' });
    } else {
      addForm({ interiorSelective: false });
      addForm({ hideSidebar: false });
      addForm({ backBtn: false });
    }
  }

  backButton() {}

  nextButton() {
    const { addForm } = this.props;

    const checkStorage = localStorage.getItem('ligeaForm');
    if (checkStorage) {
      addForm({ currentStep: 'products_and_prices' });
      addForm({ bakcBtnComp: 'design_support' });
    } else {
      addForm({ currentStep: 'upload_files' });
    }
  }

  parentUpdate(e) {
    const { addForm } = this.props;

    addForm({ currentStep: e });
  }

  render() {
    return (
      <Container fluid className="m-0">
        <Row>
          <form action="" className="form">
            <MainHeadlineSmall
              text="Do you already have a finished design?"
              topline="HOW CAN WE HELP YOU?"
              subline="We can use your finished design or our experienced designers will create a brand new one for you."
            />
            <Row>
              <Spacing space={39} />
            </Row>
            <Col xs={12} className="p-mob-0">
              <Row>
                <Col lg={4}>
                  <CardsUploadComponent
                    name="support_file"
                    topline="UPLOAD"
                    title="Yes, here is my file:"
                    reducer
                    backButton={() => {
                      this.backButton();
                    }}
                    nextButton={() => {
                      this.nextButton();
                    }}
                    fileUploadState={(e) => {
                      const { fileUploadState } = this.props;
                      fileUploadState(e); // parent state
                    }}
                    modal
                  />
                </Col>
                <Col
                  lg={4}
                  onClick={() => {
                    const { addForm } = this.props;

                    addForm({
                      currentStep: 'brief_tool_or_upload_inspiration',
                    });
                  }}
                >
                  <CardsComponent
                    topline="SERVICE"
                    title="No, please create one"
                  />
                </Col>
              </Row>
              <Row>
                <Spacing space={39} />
              </Row>
            </Col>
          </form>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DesignSupportComponent);
