import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import Spacing from '../atoms/spacing/spacing';
import { addForm, currentStep } from '../../reducer/actions';

import ChoiceRoleComponent from './ChoiceRole';
import StartPageContainer from './StartPageContainer';

class StartFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 1,
    };
    this.triggerme = this.triggerme.bind(this);
  }

  componentDidMount() {
    const { addForm } = this.props;

    const checkStorage = localStorage.getItem('ligeaForm');
    if (checkStorage) {
      const ParseStorage = JSON.parse(checkStorage);
      const SkipKeys = ['hideSidebar', 'currentStep'];
      Object.keys(ParseStorage).map((key) => {
        if (!SkipKeys.includes(key)) {
          const obj = {
            [key]: ParseStorage[key],
          };
          addForm(obj);
        }
        return false;
      });
      addForm({ hideSidebar: false });
      addForm({ currentStep: 'offer_completed' });
      this.setState({
        currentStep: 'start_page',
      });
    }
  }

  triggerme(e) {
    const { addForm } = this.props;

    this.setState({
      currentStep: e,
    });
    addForm({ currentStep: e });
  }

  render() {
    let comp = (
      <StartPageContainer
        triggerme={(e) => {
          this.triggerme(e);
        }}
      />
    );
    const { currentStep } = this.state;
    // if (this.props.form) {
    //   var { currentStep } = this.props.form.forms;
    // }
    switch (currentStep) {
      case 'start_page': {
        comp = (
          <ChoiceRoleComponent
            triggerme={(e) => {
              this.triggerme(e);
            }}
          />
        );
        break;
      }
      case 3: {
        comp = 'interior_designer';
        break;
      }
      default: {
        comp = (
          <StartPageContainer
            triggerme={(e) => {
              this.triggerme(e);
            }}
          />
        );
      }
    }
    return (
      <Col xs={12}>
        <Row>
          <Spacing space={90} extraClass="d-none d-md-block" />
        </Row>
        {comp}
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  form: state.form,
  step: state.currentStep,
});
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
    currentStep: (step) => dispatch(currentStep(step)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(StartFormComponent);
