import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

import FooterLogoComponent from '../atoms/FooterLogo';

class FooterComponent extends Component {
  render() {
    const date = new Date();

    return (
      <Row className="align-middle">
        <Col lg={2} className="footer-logo">
          <FooterLogoComponent />
        </Col>
        <Col lg={6} className="footer-text">
          <p>
            Visit <a href="https://www.ligea.com/">ligea.com</a> to find out
            more about next level contract flooring.{' '}
          </p>
        </Col>
        <Col lg={4} className="footer-menu">
          <div className="menu-row">
            <p className="year">©{date.getFullYear()}</p>
          </div>
          <div className="menu-row">
            <ul>
              <li>
                <a href="https://api.ligea.com/uploads/ligea_agb_en.pdf" target="_blank" rel="noreferrer">GTC</a>
              </li>
              <li>
                <a href="https://www.ligea.com/privacy.html">Privacy Policy</a>
              </li>
              <li>
                <a href="https://www.ligea.com/legal.html">Legal Notice</a>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    );
  }
}

export default FooterComponent;
