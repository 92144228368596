import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import { addForm } from '../../../../reducer/actions';

import Spacing from '../../../atoms/spacing/spacing';

import ColorContentBox from '../../../molecules/banner/ColorContentBox';

class BriefTool1Component extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { addForm } = this.props;
    addForm({ sidebarNextEnable: true });
    addForm({ nextStepSidebar: 'interior_selective' });
    addForm({ backBtn: 'brief_tool_or_upload_inspiration' });
    addForm({ hideSidebar: false });

    addForm({ nextStepSidebarText: 'Next' });
  }

  parentUpdate(e) {
    const { addForm } = this.props;

    addForm({ currentStep: e });
  }

  render() {
    return (
      <Container fluid className="p-0 m-0">
        <Row>
          <Col xs={12} className="mt-3 pl-lg-0 p-mob-0">
            <Col xs={12} className="colorbox-brief-wrapper">
              <Col xs={12} className="pl-lg-0 p-mob-0">
                <ColorContentBox
                  topline="Creative brief tool"
                  text="We help you find your style."
                  content={[
                    <span>
                      Inspiration is everything! So let yourself drift a little
                      and discover a wide variety of styles and patterns as
                      inspiration for your project. By the way: All without any
                      obligation and free of charge of course! At the end of
                      your inspirational journey your selection will be sent
                      directly to our designers and we can start right away with
                      your individual design.
                    </span>,
                  ]}
                />
              </Col>
            </Col>
          </Col>
          <Row>
            <Spacing space={139} />
          </Row>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BriefTool1Component);
