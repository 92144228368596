import React, { Component } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import validator from 'validator';

import { addForm } from '../../reducer/actions';

import Spacing from '../atoms/spacing/spacing';
import Input from '../atoms/forms/input';
import { MainHeadlineSmall } from '../molecules/typography/heading';

import InfoBoxComponent from '../atoms/infoBox';
import WallToWall from '../../assets/images/wall-to-wall.png';
import AreaRug from '../../assets/images/area-rug.png';
import AreaRugComponent from './AreaRugs';

class AreaSpecificationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };

    this.handleChanges = this.handleChanges.bind(this);
  }

  componentDidMount() {
    const { form } = this.props;
    const { forms } = form;
    // prettier-ignore
    const {
      areaType,
      areaRugs,
      backButton
    } = forms;

    const { addForm } = this.props;
    addForm({ nextStepSidebarText: 'Next' });

    addForm({ sidebarNextEnable: false });
    addForm({ ProductSelection: [] });
    addForm({ nextStepSidebar: false });
    this.validateNextButton();

    addForm({ backBtn: !backButton ? 'room_type' : backButton });

    if (!areaRugs) {
      addForm({
        areaRugs: [{
          width: '',
          length: '',
          amount: '',
          id: Math.floor(Math.random() * 100)
        }]
      });
    }

    if (!areaType) {
      addForm({
        areaType: 'Wall to Wall',
      });
    }
  }

  handleClick(type) {
    const { addForm } = this.props;
    addForm({ currentStep: type });
  }

  handleChanges() {
    this.validateNextButton();
  }

  validateEmptyFields(field) {
    if (field) {
      return !validator.isEmpty(field);
    }
    return false;
  }

  validateNextButton() {
    const { form, addForm } = this.props;
    const { forms } = form;
    // prettier-ignore
    const {
      areaType,
      areaSizeInSqm,
      areaRugs,
      parentLead
    } = forms;

    let validateAreaRugs = true;
    let totalAreaSizeSqm = 0;

    if (areaRugs) {
      areaRugs.forEach((areaRug) => {
        if (this.validateEmptyFields(areaRug.length) && this.validateEmptyFields(areaRug.width) && this.validateEmptyFields(areaRug.amount)) {
          totalAreaSizeSqm += ((areaRug.width / 100) * (areaRug.length / 100) * areaRug.amount);
        } else {
          validateAreaRugs = false;
        }
      });
    }

    if (areaType === 'Wall to Wall') {
      const areaSizeValidate = this.validateEmptyFields(areaSizeInSqm);

      if (areaSizeValidate) {
        addForm({ sidebarNextEnable: true });

        if (parentLead === 'color_selective') {
          addForm({ nextStepSidebar: 'brief_checkout' });
        } else {
          addForm({ nextStepSidebar: 'products_and_prices' });
        }
        addForm({ hideSidebar: false });
      } else {
        addForm({ sidebarNextEnable: false });
        addForm({ nextStepSidebar: false });
      }
    }
    if (areaType === 'Area Rug') {
      // prettier-ignore
      if (validateAreaRugs) {
        addForm({ sidebarNextEnable: true });
        addForm({ areaSizeInSqm: this.round(totalAreaSizeSqm).toString() });

        if (parentLead === 'color_selective') {
          addForm({ nextStepSidebar: 'brief_checkout' });
        } else {
          addForm({ nextStepSidebar: 'products_and_prices' });
        }
        addForm({ hideSidebar: false });
      } else {
        addForm({ sidebarNextEnable: false });
        addForm({ nextStepSidebar: false });
      }
    }
  }

  round(num) {
    const m = Number((Math.abs(num) * 100).toPrecision(15));
    // eslint-disable-next-line no-mixed-operators
    return Math.round(m) / 100 * Math.sign(num);
  }

  radioButtonChanged(e) {
    const { addForm } = this.props;

    const val = e.target.value;
    addForm({
      areaType: val,
    });

    this.validateNextButton();
  }

  render() {
    const { showModal } = this.state;

    // prettier-ignore
    let areaSizeInSqmVal = '';
    // let widthVal = '';
    // let lengthVal = '';
    // let amountVal = '';
    let areaTypeVal = '';
    let ElseAreaSpecificationVal = '';
    let RepeatPatternVal = '';

    const { form, addForm } = this.props;
    const { forms } = form;
    // prettier-ignore
    const {
      areaType,
      areaSizeInSqm,
      // length,
      // width,
      ElseAreaSpecification,
      // amount,
      // areaRugs,
      RepeatPattern
    } = forms;

    if (areaType) {
      areaTypeVal = areaType;
    }
    if (areaSizeInSqm) {
      areaSizeInSqmVal = areaSizeInSqm;
    }
    // if (length) {
    //   lengthVal = length;
    // }
    // if (width) {
    //   widthVal = width;
    // }
    if (ElseAreaSpecification) {
      ElseAreaSpecificationVal = ElseAreaSpecification;
    }
    // if (amount) {
    //   amountVal = amount;
    // }
    if (RepeatPattern) {
      RepeatPatternVal = RepeatPattern;
    } else {
      RepeatPatternVal = 'Repeat Pattern';
      const val = 'Repeat Pattern';
      addForm({
        RepeatPattern: val,
      });
    }

    let wallToWallRow = '';

    if (areaType === 'Wall to Wall') {
      wallToWallRow = (
        <Row>
          <Col xs={12}>
            <Col lg={6} xs={12} className="p-mob-0">
              <Input
                name="areaSizeInSqm"
                type="number"
                label="Total sqm"
                value={areaSizeInSqmVal}
                min={0}
                reducer
                parentupdate={(e) => {
                  this.handleChanges(e);
                }}
                error={validator.isEmpty(areaSizeInSqmVal)}
                errorContent="Please Fill Area Size"
                extraClasses="with-sqm"
              />
            </Col>
            <Col xs={12} className="carpet-radio-btn">
              <Row>
                <Col xs={12} className="mt-5 mb-4">
                  <p className="font-weight-light  f-s-20">
                    Will your carpet design have a repeat pattern or will it be
                    an all-over design?
                  </p>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col xs={6} lg={4}>
                      <div className="mb-3 radio-wrapper">
                        <input
                          type="radio"
                          name="overDesign"
                          id="Repeat-Pattern"
                          value="Repeat Pattern"
                          checked={RepeatPatternVal === 'Repeat Pattern'}
                          onChange={(e) => {
                            const { addForm } = this.props;
                            const val = e.target.value;
                            addForm({
                              RepeatPattern: val,
                            });
                          }}
                        />
                        <label htmlFor="Repeat-Pattern">Repeat Pattern</label>
                      </div>
                    </Col>
                    <Col xs={6} lg={4}>
                      <div className="mb-3 radio-wrapper">
                        <input
                          type="radio"
                          name="overDesign"
                          id="AllOver-Design"
                          value="All Over Design"
                          checked={RepeatPatternVal === 'All Over Design'}
                          onChange={(e) => {
                            const { addForm } = this.props;
                            const val = e.target.value;
                            addForm({
                              RepeatPattern: val,
                            });
                          }}
                        />
                        <label htmlFor="AllOver-Design">All Over Design</label>
                      </div>
                    </Col>
                    <Col xs={6} lg={4}>
                      <div className="mb-3 radio-wrapper">
                        <input
                          type="radio"
                          name="overDesign"
                          id="I-Dont-Know-Yet"
                          value="I Dont know yet"
                          checked={RepeatPatternVal === 'I Dont know yet'}
                          onChange={(e) => {
                            const { addForm } = this.props;
                            const val = e.target.value;
                            addForm({
                              RepeatPattern: val,
                            });
                          }}
                        />
                        <label htmlFor="I-Dont-Know-Yet">
                          I Don&#39;t know yet
                        </label>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Col>
        </Row>
      );
    }

    let modalArea = '';
    if (showModal) {
      modalArea = (
        <Modal
          className="confirm-modal blue-modal"
          show
          backdrop="static"
          keyboard={false}
        >
          <button
            className="btn btn-transparent"
            type="button"
            onClick={() => {
              this.setState({
                showModal: false,
              });
            }}
          >
            <span className="close-overlay-icon" />
          </button>
          <Modal.Body className="wall-to-wall-popup">
            <Row>
              <Col lg={6} xs={12} className="singleRow">
                <img
                  src={WallToWall}
                  alt="Wall To Wall"
                  className="img-fluid"
                />
                <Row className="hide-mob">
                  <Spacing space={50} />
                </Row>
                <MainHeadlineSmall
                  topline="OPTION 1"
                  text="Wall-To-Wall"
                  subline="Some of our qualities are perfect for installation, so that a complete room can be carpeted from wall to wall. The carpets are then produced on rolls and they can be laid over a large area so there are no limitations to the shape or size of the room... "
                />
              </Col>
              <Col lg={6} xs={12} className="singleRow">
                <img src={AreaRug} alt="Area Rug" className="img-fluid" />
                <Row className="hide-mob">
                  <Spacing space={50} />
                </Row>
                <MainHeadlineSmall
                  topline="OPTION 2"
                  text="Area Rug"
                  subline="An area rug is a type of carpet that is manufactured individually to a specific size and shape. This type of rug is wonderful for adding structure to rooms, setting accents and creating a comfortable ambience. Especially in large rooms, area rugs can create islands and connect seating furniture into a unit..."
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      );
    }

    return (
      <div className="areaSpecification">
        <Col xs={12} className="pl-lg-0 p-mob-0">
          <MainHeadlineSmall
            topline="02 - PRODUCT AND PRICE RANGE - PROJECT INFORMATION"
            text="Information about your project"
            subline="We will recommend only the carpet qualities that are relevant for your individual project."
          />
        </Col>

        <Row>
          <Spacing space={59} />
        </Row>

        <Col xs={12} className="pl-lg-0 p-mob-0">
          <h4>Area Specification</h4>
        </Col>
        <button
          type="button"
          className="area-specification-info btn btn-transparent"
          onClick={() => {
            this.setState({
              showModal: true,
            });
          }}
        >
          <InfoBoxComponent />
        </button>
        {modalArea}

        <Col xs={12} className="pl-lg-0 form p-mob-0">
          <Row>
            <Col xs={12} lg={5}>
              <h4 className="font-weight-light  f-s-20">
                What type of carpet do you need?
              </h4>
            </Col>
            <div className="d-block d-lg-none">
              <Row>
                <Spacing space={20} />
              </Row>
            </div>
            <Col xs={12} lg={7} className="pl-lg-0">
              <Col xs={12} className="pl-lg-0 wall-to-wall-wrapper">
                <Row>
                  <div className="mb-3 radio-wrapper">
                    <input
                      type="radio"
                      name="areaType"
                      id="Wall to Wall"
                      value="Wall to Wall"
                      checked={areaTypeVal === 'Wall to Wall'}
                      onChange={(e) => {
                        this.radioButtonChanged(e);
                      }}
                    />
                    <label htmlFor="Wall to Wall">Wall To Wall</label>
                  </div>
                  <div className="mt-2 col-12">{wallToWallRow}</div>
                </Row>
              </Col>
              <div className="d-none d-lg-block">
                <Row>
                  <Spacing space={100} />
                </Row>
              </div>
              <div className="d-block d-lg-none">
                <Row>
                  <Spacing space={50} />
                </Row>
              </div>
              <Col xs={12} className="pl-lg-0 area-rug-wrapper">
                <Row>
                  <div className="mb-5 radio-wrapper">
                    <input
                      type="radio"
                      name="Area Rug"
                      value="Area Rug"
                      id="Area Rug"
                      checked={areaTypeVal === 'Area Rug'}
                      onChange={(e) => {
                        this.radioButtonChanged(e);
                      }}
                    />
                    <label htmlFor="Area Rug">Area Rug</label>
                  </div>
                  <AreaRugComponent forms={forms} areaType={areaTypeVal} handleChanges={this.handleChanges} addForm={addForm} />
                </Row>
              </Col>
            </Col>

            <Col xs={12}>
              <Row>
                <Spacing space={80} />
              </Row>
            </Col>

            <Col lg={5} xs={12}>
              <h4 className="font-weight-light f-s-20">
                Is there anything else we need to know?
              </h4>
            </Col>

            <Col lg={7} xs={12}>
              <Row>
                <Col lg={6} xs={12} className="p-mob-0">
                  <Input
                    name="ElseAreaSpecification"
                    type="text"
                    label="Your custom text here ..."
                    value={ElseAreaSpecificationVal}
                    reducer
                    required={false}
                  // error={validator.isEmpty(ElseAreaSpecification)}
                  // errorContent="Please Fill Area Size"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AreaSpecificationComponent);
