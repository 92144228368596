import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addForm } from '../../../reducer/actions';

class TotalPriceCalculationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TotalPriceState: 0,
    };
  }

  render() {
    const { type, TotalPrice, currencyDisplay } = this.props;
    const { TotalPriceState } = this.state;

    if (TotalPriceState !== TotalPrice) {
      this.setState({
        TotalPriceState: TotalPrice,
      });

      const { addForm } = this.props;
      addForm({ TotalPriceState: TotalPrice });
    }

    const currencyDisplayL = currencyDisplay === '$' ? 'USD' : 'EUR';

    if (type === 'normal') {
      return (
        <div className="total-price">
          <span className="from">Your estimated costs: </span>
          <span className="price">
            {TotalPrice.toLocaleString('de-DE', {
              style: 'currency',
              currency: currencyDisplayL,
            })}
            {/* {new Intl.NumberFormat('de-DE', {
              maximumSignificantDigits: 7,
            }).format(TotalPrice)}{' '} */}
            {/* {currencyDisplay} */}
          </span>
        </div>
      );
    }
    return (
      <div className="total-price total-from">
        <span className="from">Your estimated costs: </span>
        <span className="price">
          {TotalPrice.toLocaleString('de-DE', {
            style: 'currency',
            currency: currencyDisplayL,
          })}
          {/* {new Intl.NumberFormat('de-DE').format(TotalPrice)} {currencyDisplay} */}
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TotalPriceCalculationComponent);
