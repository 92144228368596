import React, { Component } from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
// import { connect } from 'react-redux';

// import { addForm } from '../../../reducer/actions';

// import { H4 } from '../../atoms/typography/heading';
import { MainHeadlineH2 } from '../typography/heading';

import { BtnPrimaryWhite } from '../../atoms/buttons/buttons';
import Topline from '../../atoms/typography/topline';

class OverlayInfo extends Component {
  render() {
    // prettier-ignore
    const {
      HeadTopline,
      HeadText,
      HeadSubline,
      image,
      Position,
      Name,
      extraLines,
      handleClose
    } = this.props;

    const closeIcon = [];
    if (handleClose) {
      closeIcon.push(
        <button
          className="btn btn-transparent"
          type="button"
          onClick={() => {
            handleClose();
          }}
        >
          <span className="close-overlay-icon" />
        </button>
      );
    }

    return (
      <div>
        <Modal
          className="overlay-modal white-modal"
          show
          backdrop="static"
          keyboard={false}
        >
          {closeIcon}
          <Modal.Body>
            <Row>
              <Col lg={8}>
                <MainHeadlineH2
                  text={HeadText}
                  topline={HeadTopline}
                  subline={HeadSubline}
                  SubLineSpace={60}
                />
              </Col>
              <Col lg={4} className="content">
                <Row>
                  <Col xs={12} className="mb-5 image-row">
                    <img src={image} alt="pics" className="img-fluid" />
                  </Col>
                  <Col xs={12} className="mt-5 contact-info-wrapper">
                    <div className="contact-info">
                      <Topline topline={Position} />
                      <h4 className="font-weight-light mb-4 mt-4">{Name}</h4>
                      <div className="extra-lines">{extraLines}</div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
          <Col xs={12} className="footer-modal">
            <a href="https://zohoadm-ligea.zohobookings.eu/#/customer/ligeagmbh">
              <BtnPrimaryWhite
                content="Arrange appointment"
                trigger={1}
                triggerme={() => {
                  handleClose();
                }}
              />
            </a>
          </Col>
        </Modal>
      </div>
    );
  }
}

export default OverlayInfo;
