import React from 'react';

const DateMapIconSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g id="Gruppe_213" transform="translate(-559 -1558)">
      <g id="Gruppe_212">
        <g id="Gruppe_209" transform="translate(38)">
          <g id="Checkbox_Active_" transform="translate(101 466)">
            <g
              id="Rechteck_51"
              className="cls-1"
              transform="translate(420 1092)"
            >
              <rect width="20" height="20" rx="5" />
              <rect width="18" height="18" x="1" y="1" fill="none" rx="4" />
            </g>
          </g>
          <path
            id="Pfad_215"
            d="M0 7h17"
            className="cls-1"
            transform="translate(522.5 1565.5)"
          />
        </g>
        <text
          id="_1"
          fill="#1f1d1a"
          fontFamily="MessinaSans-SemiBold, Messina Sans"
          fontSize="10px"
          fontWeight="600"
          transform="translate(560 1559)"
        >
          <tspan x="6.835" y="10">
            1
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);
export default DateMapIconSVG;
