import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import validator from 'validator';
import Input from '../../atoms/forms/input';

import { addForm } from '../../../reducer/actions';

class BiefCheckoutContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailAddress: '',
      ContactFirstName: '',
      ContactLastName: '',
      BriefProjectName: '',
      updateField: false,
    };
  }

  componentDidMount() {
    const { form, ValidateParent } = this.props;
    const { forms } = form;

    const checkLines = [
      'emailAddress',
      'ContactFirstName',
      'ContactLastName',
      'BriefProjectName',
    ];

    checkLines.map((key, index) => {
      if (forms[key]) {
        const st = this.state;
        st[key] = forms[key];
        this.setState((prevState) => ({ ...prevState, st }));
      }
      if (index + 1 === checkLines.length) {
        this.forceUpdate();
        this.setState({
          updateField: true,
        });
      }
      return false;
    });
    ValidateParent();
  }

  handleInputChange(e) {
    const { ValidateParent } = this.props;
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    const { addForm } = this.props;
    addForm({ name: value });

    ValidateParent();
  }

  render() {
    const {
      emailAddress,
      ContactFirstName,
      ContactLastName,
      BriefProjectName,
      updateField,
    } = this.state;
    if (!updateField) {
      return '';
    }
    return (
      <Col lg={8} xs={12}>
        <Row>
          <Col lg={6}>
            <h5 className="subline font-weight-light">Your email address*</h5>
          </Col>
          <Col lg={6}>
            <Input
              name="emailAddress"
              value={emailAddress}
              parentupdate={(e) => {
                this.handleInputChange(e);
              }}
              type="email"
              label="e.g. john@johndoe.com"
              reducer
              error={!validator.isEmail(emailAddress)}
              errorContent="Please Fill Email Address correctly"
            />
          </Col>
          <Col lg={6}>
            <h5 className="subline font-weight-light">First Name*</h5>
          </Col>
          <Col lg={6}>
            <Input
              name="ContactFirstName"
              type="text"
              label="First name"
              reducer
              value={ContactFirstName}
              error={validator.isEmpty(ContactFirstName)}
              errorContent="Please Fill First Name"
              parentupdate={(e) => {
                this.handleInputChange(e);
              }}
            />
          </Col>
          <Col lg={6}>
            <h5 className="subline font-weight-light">Last Name*</h5>
          </Col>
          <Col lg={6}>
            <Input
              name="ContactLastName"
              type="text"
              label="Last name"
              reducer
              value={ContactLastName}
              error={validator.isEmpty(ContactLastName)}
              errorContent="Please Fill Last Name"
              parentupdate={(e) => {
                this.handleInputChange(e);
              }}
            />
          </Col>
          <Col lg={6}>
            <h5 className="subline font-weight-light">
              Give your project a name *
            </h5>
          </Col>
          <Col lg={6}>
            <Input
              name="BriefProjectName"
              type="text"
              label="e.g. My project"
              reducer
              value={BriefProjectName}
              error={validator.isEmpty(BriefProjectName)}
              errorContent="Please Fill Project Name"
              parentupdate={(e) => {
                this.handleInputChange(e);
              }}
            />
          </Col>
        </Row>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BiefCheckoutContact);
