const dev = {
  url: {
    API_URL: 'http://app.ligea.localhost'
  },
};

const prod = {
  url: {
    API_URL: 'https://api.ligea.com'
  },
};

const config = process.env.NODE_ENV === 'development' ? dev : prod;

export default config;
