import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import { BtnPrimary, BtnGhost } from '../atoms/buttons/buttons';
import { MainHeadline } from '../molecules/typography/heading';
import { addForm, currentStep } from '../../reducer/actions';

class StartPageContainer extends Component {
  triggerparent(e) {
    const { triggerme } = this.props;

    triggerme(e);
  }

  render() {
    return (
      <div>
        <Col xs={12} className="pl-lg-0">
          <MainHeadline
            text="Get started with the LIGEA® assistant."
            topline="Start"
            SubLineSpace={39}
            subline={
              "Design support, products & prices or simply asking an expert - we're happy to help and provide you with the carpet of your needs."
            }
          />
        </Col>

        <Col xs={12} className="startPage-buttons mt-5">
          <a href="https://ligea.com">
            <BtnGhost content="To the LIGEA® homepage" />
          </a>
          <BtnPrimary
            content="Start LIGEA® assistant"
            trigger
            triggerme={() => {
              this.triggerparent('start_page');
            }}
          />
        </Col>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  form: state.form,
  step: state.currentStep,
});
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
    currentStep: (step) => dispatch(currentStep(step)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(StartPageContainer);
