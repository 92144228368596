import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import config from '../Constants';

import { addForm, currentStep, resetForm } from '../reducer/actions';

import SidebarComponent from '../components/organisms/sidebar/SideBar';
import FooterComponent from '../components/molecules/footer';
import StartPage from '../components/templates/StartPage';

import LogoComponent from '../components/atoms/Logo';
import PriceCalculationComponent from '../components/molecules/price/PriceCalculation';
import SelectArrowICO from '../components/atoms/image/select-arrow';

import OrderCompletedURLComponent from '../components/templates/OrderTemplate/OrderTemplateCompletion';

class LigeaConfiguratorComponent extends Component {
  constructor(props) {
    super(props);
    this.triggerme = this.triggerme.bind(this);
  }

  componentDidMount() {
    const url = config.url.API_URL;
    const { addForm } = this.props;
    addForm({ apiUrl: url });
  }

  triggerme(e) {
    const { addForm, resetForm } = this.props;

    resetForm(true);
    addForm({ currentStep: e });
    const url = config.url.API_URL;
    addForm({ apiUrl: url });
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/order/:id" component={OrderCompletedURLComponent} />
          <Route path="/">
            <Container fluid>
              <Row>
                <Col
                  xs={12}
                  md={9}
                  className="h-100-v content main-content-wrapper"
                >
                  <Row className="header-logo">
                    <Col xs={6} lg={12}>
                      <a href="/">
                        <LogoComponent />
                      </a>
                    </Col>
                    <Col
                      className="d-md-none mobile-menu"
                      id="mobile-menu"
                      onClick={() => {
                        document
                          .getElementById('sidebar-container')
                          .classList.toggle('active');
                        document
                          .getElementById('mobile-menu')
                          .classList.toggle('active');

                        document
                          .getElementsByClassName('main-content-wrapper')[0]
                          .classList.toggle('sidebar-opened');
                      }}
                    >
                      <div className="mobile-menu-wrapper">
                        <Col xs={12} className="mobile-menu-title">
                          <h5 className="font-weight-light">
                            PROJECT OVERVIEW
                          </h5>
                        </Col>
                        <Col xs={12} className="information">
                          <div className="arrow-icon">
                            <SelectArrowICO />
                          </div>
                          <div className="price-total-wrapper">
                            <PriceCalculationComponent />
                          </div>
                        </Col>
                      </div>
                    </Col>
                  </Row>

                  <Container
                    fluid
                    className="startpage-container leftside-content"
                  >
                    <StartPage />
                  </Container>
                </Col>

                <Col
                  xs={12}
                  md={3}
                  className="h-100-v sidebar"
                  id="sidebar-container"
                >
                  <SidebarComponent />
                </Col>

                <Col xs={12} className="footer">
                  <FooterComponent />
                </Col>
              </Row>
            </Container>
          </Route>
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  form: state.form,
  step: state.currentStep,
});
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
    resetForm: (form) => dispatch(resetForm(form)),
    currentStep: (step) => dispatch(currentStep(step)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LigeaConfiguratorComponent);
