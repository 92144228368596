import React, { Component } from 'react';
import { connect } from 'react-redux';

import { addForm } from '../../../reducer/actions';

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldUpdated: 0,
    };
  }

  componentDidMount() {
    let st = this.state;
    // prettier-ignore
    const {
      value,
      name,
      form,
      parentupdate,
      reducer
    } = this.props;

    if (value) {
      st[name] = value;
    } else {
      st[name] = '';
    }
    // this.setState({
    //   st,
    // });
    this.setState(() => ({ st }));

    if (reducer) {
      const value = form[name];
      if (value) {
        st = this.state;
        st[name] = value;

        if (parentupdate) {
          const event = {};
          event.target = {};
          event.target.name = name;
          event.target.value = value;
          parentupdate(event);
        }
      }
    }
  }

  handleInputChange(event) {
    // prettier-ignore
    const {
      reducer,
      parentupdate,
      addForm,
      form
    } = this.props;

    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value,
      fieldUpdated: 1,
    });

    if (reducer) {
      if (name.includes('.')) {
        const nameSplitted = name.split('.');

        const field = [...form.forms[nameSplitted[0]]];
        field[nameSplitted[1]][nameSplitted[2]] = value;

        addForm({
          [nameSplitted[0]]: field
        });
      } else {
        addForm({
          [name]: value,
        });
      }
    }
    // addForm({
    //   [name]: value,
    // });
    if (parentupdate) {
      parentupdate(event);
    }
  }

  render() {
    // prettier-ignore
    const {
      error,
      errorContent,
      extraClasses,
      type,
      name,
      label
    } = this.props;
    const stateName = this.state;
    const { fieldUpdated } = this.state;

    let extraClass = '';
    if (stateName[name]) {
      if (stateName[name].length > 0) {
        extraClass = 'field-filled';
      }
    }
    let errorDiv = '';
    if (fieldUpdated) {
      if (error) {
        errorDiv = (
          <span className="invalid-field-warning condensed">
            {errorContent}
          </span>
        );
        extraClass += ' invalid-field';
      }
    }

    if (extraClasses) {
      extraClass += ' ';
      extraClass += extraClasses;
    }

    if (type === 'textarea') {
      return (
        <div className={`form-item ${extraClass}`}>
          <textarea
            type={type}
            name={name}
            value={stateName[name]}
            onChange={this.handleInputChange.bind(this)}
            required
          >
            {stateName[name]}
          </textarea>
          <label htmlFor={name} className="condensed">
            {label}
          </label>
          {errorDiv}
        </div>
      );
    }

    if (type === 'number') {
      if (fieldUpdated) {
        return (
          <div className={`form-item ${extraClass}`}>
            <input
              type="text"
              name={name}
              value={stateName[name]}
              required
              onChange={(e) => {
                const { target } = e;
                const { value } = target;
                if (!isNaN(value)) {
                  e.target.value = value.trim();
                  this.handleInputChange(e);
                }
              }}
            />
            <label htmlFor={name} className="condensed">
              {label}
            </label>
            {errorDiv}
          </div>
        );
      }
      return (
        <div className={`form-item ${extraClass}`}>
          <input
            type="text"
            name={name}
            value={stateName[name]}
            onChange={(e) => {
              const { target } = e;
              const { value } = target;
              if (!isNaN(value)) {
                e.target.value = value.trim();
                this.handleInputChange(e);
              }
            }}
            required
          />
          <label htmlFor={name} className="condensed">
            {label}
          </label>
        </div>
      );
    }
    if (fieldUpdated) {
      return (
        <div className={`form-item ${extraClass}`}>
          <input
            type={type}
            name={name}
            value={stateName[name]}
            onChange={this.handleInputChange.bind(this)}
            required
          />
          <label htmlFor={name} className="condensed">
            {label}
          </label>
          {errorDiv}
        </div>
      );
    }
    return (
      <div className={`form-item ${extraClass}`}>
        <input
          type={type}
          name={name}
          value={stateName[name]}
          onChange={this.handleInputChange.bind(this)}
          required
        />
        <label htmlFor={name} className="condensed">
          {label}
        </label>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Input);
