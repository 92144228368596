import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import { MainHeadlineSmall } from '../molecules/typography/heading';
import CardsComponent from '../molecules/cards/cards';
import Spacing from '../atoms/spacing/spacing';
import { addForm } from '../../reducer/actions';

import OverlayInfo from '../molecules/Popup/OverlayContactInfo';
import ProfileImage from '../../assets/images/profile-image.png';

class HowCanWeHelpYouComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popup: false,
    };
  }

  componentDidMount() {
    const { addForm } = this.props;
    addForm({ backBtn: 1 });
    addForm({ hideSidebar: false });
  }

  triggerme(type) {
    const { addForm } = this.props;

    addForm({ HowCanWeHelpYou: type });
    addForm({ currentStep: type });
  }

  addExtraFields(field, form) {
    const { addForm } = this.props;

    addForm({ [field]: form });
  }

  render() {
    const { popup } = this.state;

    let popupExpander = '';
    if (popup) {
      popupExpander = (
        <OverlayInfo
          HeadTopline="ASK AN EXPERT"
          HeadText="Hello! I'm the personal assistant for all your questions."
          HeadSubline="Each project is different. That's why it is very important to us that we discuss all the aspects necessary to bring your vision to live. We're happy to help you navigate the LIGEA® assistant, pick the right products and get a better understanding of our process."
          image={ProfileImage}
          Position="PROJECT MANAGEMENT"
          Name="Kathrin Marschall"
          extraLines={[
            <Row>
              <Col xs={12}>
                <span className="float-left mr-4 font-weight-light">P</span>
                <a
                  href="tel:+49 176 60938212"
                  className="link whitec  font-weight-light"
                >
                  +49 176 60938212
                </a>
              </Col>
              <Col xs={12}>
                <span className="float-left mr-4  font-weight-light">M</span>
                <a
                  href="mailto:kathrin.marschall@ligea.com"
                  className="link whitec  font-weight-light"
                >
                  kathrin.marschall@ligea.com
                </a>
              </Col>
            </Row>,
          ]}
          handleClose={() => {
            this.setState({
              popup: false,
            });
          }}
        />
      );
    }
    return (
      <Container fluid className="choiceRole-container p-0 m-0">
        <Row>
          {popupExpander}
          <Col xs={12} className="mt-3">
            <form action="" className="form">
              <MainHeadlineSmall
                text="Where do you need support?"
                topline="HOW CAN WE HELP YOU?"
                subline="Please choose here how we can help you."
              />
              <Row>
                <Spacing space={39} />
              </Row>
              <Col xs={12} className="p-mob-0  designsupport-row">
                <Row>
                  <Col
                    lg={3}
                    xs={6}
                    onClick={() => {
                      this.triggerme('design_support');

                      this.addExtraFields(
                        'where_do_you_need_support',
                        'Design Support'
                      );
                    }}
                  >
                    <CardsComponent title="Design Support" topline="FOR" />
                  </Col>
                  <Col
                    lg={3}
                    xs={6}
                    onClick={() => {
                      this.triggerme('upload_files');
                      this.addExtraFields(
                        'where_do_you_need_support',
                        'Products and Prices'
                      );
                    }}
                  >
                    <CardsComponent title="Products and Prices" topline="FOR" />
                  </Col>
                  <Col
                    lg={3}
                    xs={6}
                    onClick={() => {
                      this.setState({
                        popup: true,
                      });
                    }}
                  >
                    <CardsComponent title="Call an Expert" />
                  </Col>
                </Row>
                <Row>
                  <Spacing space={39} />
                </Row>
              </Col>
            </form>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HowCanWeHelpYouComponent);
