import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import Spacing from '../atoms/spacing/spacing';
import { addForm } from '../../reducer/actions';

import OverviewInformationComponent from '../organisms/OverviewInformation';
import ChosenProducts from '../organisms/ChosenProducts';
// import DesignStyle from '../organisms/DesignStyle';

class CheckoutComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { addForm, form } = this.props;
    const { forms } = form;
    addForm({ sidebarNextEnable: true });
    addForm({ nextStepSidebar: 'billing_and_shipment_address' });
    addForm({ nextStepSidebarText: 'Next' });
    addForm({ fullButtonSize: true });

    const checkStorage = localStorage.getItem('ligeaForm');
    if (checkStorage) {
      const { bakcBtnComp } = forms;
      if (bakcBtnComp) {
        addForm({ backBtn: bakcBtnComp });
      }
    }
  }

  triggerme(type) {
    const { addForm } = this.props;
    addForm({ currentStep: type });
  }

  render() {
    return (
      <Container fluid className="checkout-page p-0 m-0">
        <Row>
          <form action="" className="form">
            <Row>
              <Spacing space={39} />
            </Row>
            <Col xs={12} className="rows p-mob-0">
              <OverviewInformationComponent />
              <Row>
                <Spacing space={39} />
              </Row>

              <ChosenProducts />
              <Row>
                <Spacing space={39} />
              </Row>
              {/*
                    <DesignStyle />
                    <Spacing space={39} /> */}
            </Col>
          </form>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CheckoutComponent);
