import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';

import LoadingAnimation from '../../atoms/forms/LoadingAnimation';

import PictureSelectionComponent from '../../atoms/image/PictureSelection';
import { MainHeadlineSmall } from '../../molecules/typography/heading';

import { addForm } from '../../../reducer/actions';
import Spacing from '../../atoms/spacing/spacing';

// import Home from '../../../assets/products/Home';

class CustomRoomTypeComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRows: [],
      Home: [],
    };
  }

  componentDidMount() {
    const { addForm, form } = this.props;
    const { forms } = form;

    addForm({ roomTypeSelection: [] });
    addForm({ sidebarNextEnable: false });
    addForm({ nextStepSidebar: false });
    addForm({ backBtn: 'upload_files' });
    addForm({ hideSidebar: false });

    const { Home } = this.state;

    if (Home.length < 1) {
      // const rows = [];
      const url = `${forms.apiUrl}/api/v1/categories/roomTypeCategory`;
      axios.get(url).then((res) => {
        const { data } = res;
        if (data) {
          this.setState({
            Home: data,
          });
        }
      });
    }

    setTimeout(() => {
      if (forms.offer_service_completed) {
        const stepsRow = [
          'upload_files'
        ];
        addForm({ Steps: stepsRow });
      }
    }, 500);
  }

  handleClick(type) {
    const { addForm } = this.props;
    addForm({ currentStep: type });
  }

  handlePicClick(e) {
    const { addForm } = this.props;

    const { selectedRows } = this.state;
    const st = selectedRows;

    if (st.length > 0) {
      let exist = false;
      st.map((value, index) => {
        if (value[0] === e[0]) {
          exist = true;
          st.splice(index, 1);
        }

        if (index + 1 === st.length) {
          if (!exist) {
            st.push(e);
          }
        }
        return false;
      });
    } else {
      st.push(e);
    }

    this.setState({
      selectedRows: st,
    });

    if (st.length > 0) {
      addForm({ sidebarNextEnable: true });
      addForm({ nextStepSidebar: 'area_specification' });
    } else {
      addForm({ sidebarNextEnable: false });
      addForm({ nextStepSidebar: false });
    }

    addForm({ roomTypeSelection: st });
  }

  render() {
    const { selectedRows, Home } = this.state;

    let picList = [];

    if (Home.length > 0) {
      Home.map((home, homeindex) => {
        let imageRow = false;
        if (home.image) {
          imageRow = {
            url: home.image,
          };
        }

        const st = selectedRows;
        if (st.length > 0) {
          let exist = false;
          st.map((value, index) => {
            if (value[0] === home.id) {
              exist = true;
            }
            if (index + 1 === st.length) {
              picList.push(
                <Col key={homeindex.toString()} lg={4} xs={6} className="mb-5">
                  <PictureSelectionComponent
                    limit={1}
                    alertInfo="Only 1 selection are limited"
                    counter="roomTypeSelection"
                    label={home.title}
                    isSelected={exist}
                    select
                    image={imageRow}
                    id={home.id}
                    name={home.title}
                    triggerclick={(e) => {
                      this.handlePicClick(e);
                    }}
                  />
                </Col>
              );
            }
            return false;
          });
        } else {
          picList.push(
            <Col key={homeindex.toString()} lg={4} xs={6} className="mb-5">
              <PictureSelectionComponent
                limit={1}
                alertInfo="Only 1 selection are limited"
                counter="roomTypeSelection"
                label={home.title}
                select
                id={home.id}
                image={imageRow}
                name={home.title}
                triggerclick={(e) => {
                  this.handlePicClick(e);
                }}
              />
            </Col>
          );
        }

        return false;
      });
    } else {
      picList = <LoadingAnimation />;
    }

    return (
      <Container fluid className="choiceRole-container p-0 m-0">
        <Row>
          <Col xs={12}>
            <MainHeadlineSmall
              text="What are you working with?"
              subline="Pick the type of room you want to equip."
            />
          </Col>
          <Col xs={12}>
            <Row>
              <Spacing space={39} />
            </Row>
          </Col>
          <Col xs={12}>
            <Row>{picList}</Row>
            <Row className="d-none d-md-block">
              <Spacing space={39} />
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomRoomTypeComponent);
