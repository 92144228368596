import React from 'react';

const QuestionIconSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g>
      <g
        fill="none"
        stroke="#8f8e8c"
        transform="translate(-422.601 -1094.167) translate(422.601 1094.167)"
      >
        <rect width="16" height="16" stroke="none" rx="5" />
        <rect width="15" height="15" x=".5" y=".5" rx="4.5" />
      </g>
      <path
        fill="#8f8e8c"
        d="M-.778-1.08V0H.266v-1.08zm.135-.828H.1v-.333a1.278 1.278 0 0 1 .122-.6 1.977 1.977 0 0 1 .518-.544q.081-.072.347-.266a3.986 3.986 0 0 0 .373-.3q.108-.1.275-.3a1.084 1.084 0 0 0 .229-.423 1.956 1.956 0 0 0 .063-.518 1.5 1.5 0 0 0-.536-1.242 2.16 2.16 0 0 0-1.4-.432 2.336 2.336 0 0 0-1.516.49 1.7 1.7 0 0 0-.608 1.4h.81a1.082 1.082 0 0 1 .342-.864 1.351 1.351 0 0 1 .918-.3 1.463 1.463 0 0 1 .841.24.8.8 0 0 1 .333.711.956.956 0 0 1-.2.616 2.6 2.6 0 0 1-.567.5q-.009.009-.148.1t-.162.112l-.144.112a.981.981 0 0 0-.148.131 1.651 1.651 0 0 1-.126.135.557.557 0 0 0-.126.167 1.936 1.936 0 0 1-.09.184.731.731 0 0 0-.081.225q-.019.107-.04.247a1.836 1.836 0 0 0-.023.293z"
        transform="translate(-422.601 -1094.167) translate(430.63 1106.025)"
      />
    </g>
  </svg>
);
export default QuestionIconSVG;
