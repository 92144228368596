import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import validator from 'validator';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DateMapIconSVG from '../atoms/image/DateMap';

import { addForm } from '../../reducer/actions';

import Spacing from '../atoms/spacing/spacing';
import Input from '../atoms/forms/input';
import { MainHeadlineSmall } from '../molecules/typography/heading';

// import InformationBannerComponent from '../molecules/banner/InformationBanner';

import Countries from '../../assets/products/Countries';

class AreaSpecificationDetailsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { addForm } = this.props;
    addForm({ sidebarNextEnable: false });
    addForm({ nextStepSidebar: false });

    addForm({ backBtn: 'products_and_prices' });
    this.validateNextButton();
  }

  handleClick(type) {
    const { addForm } = this.props;
    addForm({ currentStep: type });
  }

  handleChanges(e) {
    const input = e.target;
    const { name, value } = input;

    this.setState({
      [name]: value,
    });
    addForm({ [name]: value });

    this.validateNextButton();
  }

  validateEmptyFields(field, type) {
    if (type === 'date') {
      if (field) {
        return validator.isDate(field);
      }
    }
    if (field) {
      return !validator.isEmpty(field);
    }
    return false;
  }

  validateNextButton() {
    const { form, addForm } = this.props;
    const { forms } = form;
    // prettier-ignore
    const {
      AreaSpecificationYourCountry,
      AreaSpecificationShipmentDate,
      AreaSpecificationBindingOffer
    } = forms;

    const YourCountryValidate = this.validateEmptyFields(
      AreaSpecificationYourCountry
    );

    const shipmentDateValidate = this.validateEmptyFields(
      !AreaSpecificationShipmentDate
        ? null
        : new Date(AreaSpecificationShipmentDate),
      'date'
    );
    const bindingOfferValidate = this.validateEmptyFields(
      !AreaSpecificationBindingOffer
        ? null
        : new Date(AreaSpecificationBindingOffer),
      'date'
    );

    // prettier-ignore
    if (YourCountryValidate && shipmentDateValidate && bindingOfferValidate) {
      addForm({ sidebarNextEnable: true });
      addForm({ nextStepSidebar: 'overview_information' });
    } else {
      addForm({ sidebarNextEnable: false });
      addForm({ nextStepSidebar: false });
    }
  }

  radioButtonChanged(e) {
    const { addForm } = this.props;

    const val = e.target.value;
    addForm({
      areaType: val,
    });

    this.validateNextButton();
  }

  render() {
    // prettier-ignore
    const { form, addForm } = this.props;
    const { forms } = form;

    // prettier-ignore
    const {
      AreaSpecificationYourMessage,
      AreaSpecificationYourCountryKEY,
      AreaSpecificationProjectTitle,
      AreaSpecificationShipmentDate,
      AreaSpecificationBindingOffer
    } = forms;

    const countrieWrapper = [];
    const countryList = [];
    countryList.push(
      <option value="empty" selected disabled>
        Country
      </option>
    );
    Countries.map((value, index) => {
      countryList.push(
        <option id={`country_${value.country_code}`} value={value.country_code}>
          {value.country_name}
        </option>
      );
      if (index + 1 === Countries.length) {
        countrieWrapper.push(
          <select
            value={AreaSpecificationYourCountryKEY}
            onChange={(e) => {
              const target = e.target.value;
              const findTarget = document.getElementById(`country_${target}`);
              addForm({
                AreaSpecificationYourCountry: findTarget.innerHTML,
                AreaSpecificationYourCountryKEY: target,
              });
              this.validateNextButton();
            }}
          >
            {countryList}
          </select>
        );
      }
      return value;
    });

    return (
      <div className="area-specification-details">
        <Col xs={12} className="pl-lg-0 p-mob-0">
          <MainHeadlineSmall
            topline="02 - PRODUCT AND PRICE RANGE - PROJECT INFORMATION"
            text="More information about your project"
            subline="Pleas give us all of the relevant data so we can give you an individual offer."
          />
        </Col>

        <Row>
          <Spacing space={59} />
        </Row>

        {/* <Row className="infobanner">
          <InformationBannerComponent />
        </Row> */}

        <Row>
          <Spacing space={59} />
        </Row>

        <Row xs={12} className="form">
          <Col xs={12} className="mb-4">
            <h4>Area Specification</h4>
          </Col>
          <Col xs={12} className="p-mob-0">
            <Col xs={12} className="pl-lg-0">
              <Row>
                <Col lg={5} xs={12}>
                  <h4 className="font-weight-light form-description">
                    In which country is the project located? *
                  </h4>
                </Col>
                <Col lg={7} xs={12}>
                  {countrieWrapper}
                </Col>
              </Row>
            </Col>

            <Col xs={12} className="pl-lg-0 mt-4">
              <Row>
                <Col lg={5} xs={12}>
                  <h4 className="font-weight-light form-description">
                    When is shipment due?*
                  </h4>
                </Col>
                <Col lg={7} xs={12} className="datepicker-row">
                  <DatePicker
                    selected={
                      !AreaSpecificationShipmentDate
                        ? null
                        : new Date(AreaSpecificationShipmentDate)
                    }
                    placeholderText="e.g. 01. Oktober 2020"
                    onChange={(date) => {
                      addForm({
                        AreaSpecificationShipmentDate: date,
                      });
                      this.validateNextButton();
                    }}
                  />
                  <DateMapIconSVG />
                </Col>
              </Row>
            </Col>

            <Col xs={12} className="pl-lg-0 mt-4 ">
              <Row>
                <Col lg={5} xs={12}>
                  <h4 className="font-weight-light form-description">
                    When do you need the binding offer?*
                  </h4>
                </Col>
                <Col lg={7} xs={12} className="datepicker-row">
                  <DatePicker
                    selected={
                      !AreaSpecificationBindingOffer
                        ? null
                        : new Date(AreaSpecificationBindingOffer)
                    }
                    placeholderText="e.g. 01. Oktober 2020"
                    onChange={(date) => {
                      addForm({
                        AreaSpecificationBindingOffer: date,
                      });
                      this.validateNextButton();
                    }}
                  />
                  <DateMapIconSVG />
                </Col>
              </Row>
            </Col>

            <Col xs={12} className="pl-lg-0 mt-4">
              <Row>
                <Col lg={5} xs={12} className="mt-4">
                  <h4 className="font-weight-light form-description">
                    Is there anything else we need to know?
                  </h4>
                </Col>
                <Col
                  lg={7}
                  xs={12}
                  className={`mt-4 ${
                    !AreaSpecificationYourMessage
                      ? ''
                      : AreaSpecificationYourMessage
                  }`}
                >
                  <Input
                    name="AreaSpecificationYourMessage"
                    type="textarea"
                    label="Your custom text here ..."
                    value={AreaSpecificationYourMessage}
                    reducer
                    required={false}
                    parentupdate={(e) => {
                      this.handleChanges(e);
                    }}
                  />
                </Col>
              </Row>
            </Col>

            <Col xs={12} className="pl-lg-0 mt-4">
              <Row>
                <Col lg={5} xs={12}>
                  <h4 className="font-weight-light form-description">
                    Individual project title
                  </h4>
                </Col>
                <Col lg={7} xs={12}>
                  <Input
                    name="AreaSpecificationProjectTitle"
                    type="text"
                    label="e.g. My Hotel Project"
                    value={AreaSpecificationProjectTitle}
                    reducer
                    required={false}
                    parentupdate={(e) => {
                      this.handleChanges(e);
                    }}
                    // error={validator.isEmpty(AreaSpecificationProjectTitle)}
                    // errorContent="Please Fill Area Size"
                  />
                </Col>
              </Row>
            </Col>
          </Col>
          <Row>
            <Spacing space={66} />
          </Row>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AreaSpecificationDetailsComponent);
