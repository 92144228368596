export default class GAManagerApi {
  constructor(id) {
    if (!GAManagerApi.instance) {
      GAManagerApi.instance = this;
    }
    this.id = id;

    return GAManagerApi.instance;
  }

  load(isAnonymousTracking) {
    try {
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=${this.id}`;
      script.async = true;
      document.head.appendChild(script);

      const initScript = document.createElement('script');

      initScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag('js', new Date());

        gtag('config', 'G-${this.id}', ${isAnonymousTracking});
      `;
      document.head.appendChild(initScript);
    } catch (ex) {
      console.error('Error appending google analytics');
      console.error(ex);
    }
  }
}
