import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import validator from 'validator';

import Spacing from '../../atoms/spacing/spacing';

import Input from '../../atoms/forms/input';
import { addForm } from '../../../reducer/actions';

class ContactInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ContactFirstName: '',
      ContactLastName: '',
      ContactPhone: '',
      ContactMob: '',
      updateField: false,
    };
  }

  componentDidMount() {
    const { form, ValidateParent } = this.props;
    const { forms } = form;

    const checkLines = [
      'ContactFirstName',
      'ContactLastName',
      // 'ContactPhone',
      // 'ContactMob',
    ];

    checkLines.map((key, index) => {
      if (forms[key]) {
        const st = this.state;
        st[key] = forms[key];
        this.setState((prevState) => ({ ...prevState, st }));
      }
      if (index + 1 === checkLines.length) {
        this.setState({
          updateField: true,
        });
      }
      return false;
    });
    ValidateParent();
  }

  handleInputChange(e) {
    const { ValidateParent } = this.props;
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    const { addForm } = this.props;
    addForm({ name: value });

    ValidateParent();
  }

  render() {
    const {
      ContactFirstName,
      ContactLastName,
      ContactPhone,
      ContactMob,
      updateField,
    } = this.state;

    if (!updateField) {
      return '';
    }

    return (
      <Row>
        <Col lg={4}>
          <h4>Contact information</h4>
        </Col>
        <Col lg={8}>
          <Row>
            <Row>
              <Spacing space={60} />
            </Row>
            <Col xs={12} className="form-group">
              <Input
                name="ContactFirstName"
                type="text"
                label="First Name *"
                reducer
                value={ContactFirstName}
                error={validator.isEmpty(ContactFirstName)}
                errorContent="Please Fill First Name"
                parentupdate={(e) => {
                  this.handleInputChange(e);
                }}
              />
            </Col>
            <Col xs={12} className="form-group">
              <Input
                name="ContactLastName"
                type="text"
                label="Last Name *"
                reducer
                value={ContactLastName}
                error={validator.isEmpty(ContactLastName)}
                errorContent="Please Fill Last Name"
                parentupdate={(e) => {
                  this.handleInputChange(e);
                }}
              />
            </Col>
            <Col xs={12} className="form-group">
              <Input
                name="ContactPhone"
                type="text"
                label="Telephone number"
                reducer
                value={ContactPhone}
                // error={validator.isEmpty(ContactPhone)}
                // errorContent="Please Fill Telephone number"
                parentupdate={(e) => {
                  this.handleInputChange(e);
                }}
              />
            </Col>
            <Col xs={12} className="form-group">
              <Input
                name="ContactMob"
                type="text"
                label="Mobile phone number"
                reducer
                value={ContactMob}
                // error={validator.isEmpty(ContactMob)}
                // errorContent="Please Fill Mobile phone number"
                parentupdate={(e) => {
                  this.handleInputChange(e);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ContactInformation);
