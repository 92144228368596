// prettier-ignore
import {
  FORM_DATA,
  CURRENT_STEP,
  RESET_FORM,
  BACK_STEP
} from '../constants';

export function addForm(payload) {
  return { type: FORM_DATA, payload };
}

export function backStep(payload) {
  return { type: BACK_STEP, payload };
}

export function resetForm(payload) {
  return { type: RESET_FORM, payload };
}

export function currentStep(payload) {
  return { type: CURRENT_STEP, payload };
}
