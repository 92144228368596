import React from 'react';

const ArrowCardICO = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.5"
    height="10.44"
    viewBox="0 0 11.5 10.44"
  >
    <path
      fill="#cc7828"
      d="M.51-.82l5.24-5.22-5.24-5.22-1.08 1.08 3.44 3.34h-8.62v1.6h8.62L-.57-1.9z"
      transform="translate(5.75 11.26)"
    />
  </svg>
);
export default ArrowCardICO;
