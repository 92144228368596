import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';

import DefaultImage from '../../../assets/images/product-selection-blank.png';

class PictureSelectionComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelected: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const { isSelected } = this.props;

    if (isSelected) {
      this.setState({
        isSelected,
      });
    }
  }

  handleClick() {
    // prettier-ignore
    const {
      select,
      triggerclick,
      id,
      image,
      color,
      name,
      form,
      limit,
      // alertInfo,
      counter
    } = this.props;
    const { isSelected } = this.state;

    const { forms } = form;

    if (limit && forms[counter] && !isSelected) {
      setTimeout(() => {
        if (forms[counter].length >= limit) {
          // alert(alertInfo);
        } else {
          if (select) {
            this.setState({
              isSelected: !isSelected,
            });
          }

          if (triggerclick) {
            if (color) {
              const colors = {
                url: color,
              };
              triggerclick([id, !name ? '' : name, colors, colors]);
            } else {
              triggerclick([id, !name ? '' : name, image]);
            }
          }
        }
      }, 100);
    } else {
      if (select) {
        this.setState({
          isSelected: !isSelected,
        });
      }

      if (triggerclick) {
        if (color) {
          const colors = {
            url: color,
          };
          triggerclick([id, !name ? '' : name, colors, colors]);
        } else {
          triggerclick([id, !name ? '' : name, image]);
        }
      }
    }
  }

  render() {
    // prettier-ignore
    const {
      label,
      extraClass,
      select,
      image,
      color,
      numberSelect,
      form,
      limit,
      counter
    } = this.props;

    const { isSelected } = this.state;

    const url = image ? image.url : DefaultImage;
    const alt = image ? image.alt : '';

    let labelrow = '';
    if (label) {
      labelrow = <div className="label-pic-selection">{label}</div>;
    }
    let extraClassRow = '';
    if (extraClass) {
      extraClassRow += ` ${extraClass}`;
    }
    if (isSelected) {
      extraClassRow += ' selected';
    } else {
      const { forms } = form;
      if (limit && forms[counter]) {
        if (forms[counter].length >= limit) {
          extraClassRow += ' disabled';
        }
      }
    }

    let styleAttr = {};
    if (color) {
      styleAttr = { backgroundColor: color };
    }

    let selectRow = '';
    if (select) {
      selectRow = (
        <div className="select-box">
          {!numberSelect ? (
            <FontAwesomeIcon icon={faCheckSquare} />
          ) : (
            <span className="number-select">{numberSelect}</span>
          )}
        </div>
      );
      return (
        <Col
          xs={12}
          className={`picture-selection ${extraClassRow}`}
          onClick={() => {
            this.handleClick();
          }}
          style={styleAttr}
        >
          <img src={url} alt={alt} className="img-fluid" />
          {selectRow}
          {labelrow}
        </Col>
      );
    }
    return (
      <Col xs={12} className={`picture-selection ${extraClassRow}`}>
        <img src={url} alt={alt} className="img-fluid" />
        {selectRow}
        {labelrow}
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps() {
  return {};
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PictureSelectionComponent);
