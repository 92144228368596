// import ReactGA from 'react-ga';

// ReactGA.initialize('UA-000000-01');
let initialState = {
  forms: {},
};

let Steps = [];

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case 'BACK_STEP': {
      Steps.splice(Steps.length - 1, 1);

      let st = state;
      st = st.forms;

      st.Steps = Steps;

      return { ...initialState, refreshmode: true };
    }
    case 'FORM_STEP': {
      // prettier-ignore
      if (action.payload.currentStep && !Steps.includes(action.payload.currentStep)) {
        Steps.push(action.payload.currentStep);
      }
      if (initialState.forms.offer_service_completed && action.payload.currentStep === 'upload_files') {
        Steps = [];
      }
      let st = state;
      st = st.forms;

      st.Steps = Steps;

      let key = Object.keys(action.payload)[0];

      st[key] = action.payload[key];

      key = '';

      const statePage = { page_id: 1 };
      const title = '';
      const url = '/';

      history.pushState(statePage, title, url);

      if (window.gtag && action.payload.currentStep) {
        // document.title = action.payload.currentStep;
        const step = action.payload.currentStep;
        const stepFormat = step.replace('_', ' ');

        window.gtag('event', 'screen_view', {
          app_name: 'Ligea Configurator',
          screen_name: stepFormat,
        });

        // ReactGA.pageview(action.payload.currentStep);
      }

      return { ...initialState, refreshmode: true };
    }
    case 'RESET_FORM': {
      state = undefined;
      initialState = {
        forms: {},
      };
      return { ...initialState, refreshmode: true };
    }
    default: {
      return state;
    }
  }
}

export default rootReducer;
