import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import validator from 'validator';

import Spacing from '../atoms/spacing/spacing';

import Input from '../atoms/forms/input';
import ToggleRadioComponent from '../atoms/forms/toggle';
import { MainHeadlineSmall } from '../molecules/typography/heading';
import CardsComponent from '../molecules/cards/cards';
import { H4 } from '../atoms/typography/heading';
import { addForm, backStep } from '../../reducer/actions';

import { BtnGhost } from '../atoms/buttons/buttons';
import SideBarNextBtn from '../molecules/sidebar/SideBarNextBtn';

import HowCanWeHelpYouComponent from './HowCanWeHelpYou';
import DesignSupportComponent from './DesignSupport/DesignSupport';

import ProjectCategoryComponent from './ProjectCategory';
import AreaSpecificationComponent from './AreaSpecification';
import ProductsAndPricesComponent from './ProductAndPrice/ProductSelection';

import RoomTypeComponent from './ProjectCategory/HomeTypeSelection';
import HospitalityRoomComponent from './ProjectCategory/HospitalityTypeSelection';
import OfficeRoomComponent from './ProjectCategory/OfficeTypeselection';
import RetailRoomComponent from './ProjectCategory/RetailTypeSelection';
import CustomRoomTypeComponent from './ProjectCategory/CustomCategorySelection';

import UploadInspirationComponent from './Inspiration/UploadInspiration';

import AreaSpecificationDetailsComponent from './AreaSpecificationDetails';
import CheckoutComponent from './Checkout';

import BriefOrUploadInspirationComponent from './DesignSupport/BriefOrUploadInspiration';
import BriefTool1Component from './DesignSupport/CreativeBriefTool/BriefTool1';
import InteriorSelectiveComponent from './DesignSupport/CreativeBriefTool/InteriorSelective';
import BillingShipmentAddressComponent from './BillingShipmentAddress';
import OfferServiceCompletedComponent from './OfferServiceCompleted';
import PatternSelectiveComponent from './DesignSupport/CreativeBriefTool/PatternSelective';
import ColorSelectiveComponent from './DesignSupport/CreativeBriefTool/ColorSelective';
import BriefCheckoutComponent from './DesignSupport/BriefCheckout';
import BriefOFferCompletedComponent from './DesignSupport/BriefCompletedOffer';

class ChoiceRoleComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 1,
      fieldWork: false,
      emailAddress: '',
      ContactFirstName: '',
      ContactLastName: '',
      JobTtitle: '',
      supportFile: '',
      uploadInspirationFiles: [],
    };
  }

  componentDidMount() {
    const { form } = this.props;
    const { forms } = form;

    // prettier-ignore
    const {
      emailAddress,
      ContactFirstName,
      ContactLastName,
      fieldWork,
      JobTtitle
    } = forms;

    if (emailAddress) {
      this.setState({
        emailAddress,
        ContactFirstName,
        ContactLastName,
        fieldWork,
        JobTtitle,
      });
    }
    this.checkValidation();
    const checkStorage = localStorage.getItem('ligeaForm');
    if (!checkStorage) {
      this.addExtraFields('sidebarNextEnable', false);
      this.addExtraFields('hideSidebar', true);
      this.addExtraFields('nextStepSidebar', false);
    }
    this.checkNextBtn();
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    // this.checkValidation();
    // this.checkNextBtn();
  }

  checkNextBtn() {
    setTimeout(() => {
      if (this.checkValidation()) {
        const { fieldWork, JobTtitle } = this.state;
        if (fieldWork) {
          const ValidateJobTtitle = validator.isEmpty(JobTtitle);
          if (!ValidateJobTtitle) {
            this.addExtraFields('sidebarNextEnable', true);
            this.addExtraFields('hideSidebar', false);
            this.addExtraFields('you_are', JobTtitle);
            this.addExtraFields('customRole', true);
            this.addExtraFields('nextStepSidebar', 'How Can We Help You');
          } else {
            this.addExtraFields('sidebarNextEnable', false);
            this.addExtraFields('hideSidebar', true);
            this.addExtraFields('nextStepSidebar', false);
            this.addExtraFields('customRole', false);
          }
        } else {
          this.addExtraFields('sidebarNextEnable', false);
          this.addExtraFields('hideSidebar', true);
          this.addExtraFields('nextStepSidebar', false);
          this.addExtraFields('customRole', false);
        }
      } else {
        this.addExtraFields('sidebarNextEnable', false);
        this.addExtraFields('hideSidebar', true);
        this.addExtraFields('nextStepSidebar', false);
        this.addExtraFields('customRole', false);
      }
    }, 100);
  }

  checkValidation() {
    // prettier-ignore
    const {
      emailAddress,
      // ContactFirstName,
      // ContactLastName,
      fieldWork,
      JobTtitle
    } = this.state;

    const email = validator.isEmail(emailAddress);
    // const ValidateFirstName = validator.isEmpty(ContactFirstName);
    // const ValidateLastName = validator.isEmpty(ContactLastName);
    const ValidateJobDescription = fieldWork;

    if (ValidateJobDescription) {
      if (validator.isEmpty(JobTtitle)) {
        return false;
      }
      return true;
    }

    if (email) {
      return true;
    }
    return false;
  }

  triggersubmit(e) {
    const { addForm } = this.props;

    if (this.checkValidation()) {
      addForm({ role: e });
      addForm({ currentStep: e });
      this.setState({
        currentStep: e,
      });
    }
    document.getElementById('hidden-submit-btn').click();
  }

  triggerme(e) {
    this.setState({
      currentStep: e,
    });
    const { addForm } = this.props;
    addForm({ currentStep: e });
  }

  addExtraFields(field, form) {
    const { addForm } = this.props;

    addForm({ [field]: form });
    // addForm({ hideSidebar: false });
  }

  render() {
    let component = false;

    const { addForm, form } = this.props;
    const { forms } = form;

    // prettier-ignore
    const {
      JobTtitle,
      emailAddress,
      ContactFirstName,
      ContactLastName,
      fieldWork,
      supportFile,
      uploadInspirationFiles
    } = this.state;

    let { currentStep } = this.state;
    if (forms.currentStep) {
      const { form } = this.props;
      const { forms } = form;
      currentStep = forms.currentStep;
    }

    switch (currentStep) {
      case 'How Can We Help You':
        component = <HowCanWeHelpYouComponent />;
        break;
      case 'design_support':
        component = (
          <DesignSupportComponent
            fileUploadState={(e) => {
              this.setState({
                supportFile: e,
              });
            }}
          />
        );
        break;
      case 'products_and_prices':
        component = <ProductsAndPricesComponent />;
        break;
      case 'upload_files':
        component = (
          <ProjectCategoryComponent
            triggerme={(e) => {
              this.triggerme(e);
            }}
          />
        );
        break;
      case 'room_type':
        component = (
          <RoomTypeComponent
            triggerme={(e) => {
              this.triggerme(e);
            }}
          />
        );
        break;
      case 'hospitality_room':
        component = (
          <HospitalityRoomComponent
            triggerme={(e) => {
              this.triggerme(e);
            }}
          />
        );
        break;
      case 'office_room':
        component = (
          <OfficeRoomComponent
            triggerme={(e) => {
              this.triggerme(e);
            }}
          />
        );
        break;
      case 'retail_room':
        component = (
          <RetailRoomComponent
            triggerme={(e) => {
              this.triggerme(e);
            }}
          />
        );
        break;
      case 'all_rooms':
        component = (
          <CustomRoomTypeComponent
            triggerme={(e) => {
              this.triggerme(e);
            }}
          />
        );
        break;
      case 'upload_inspiration':
        component = (
          <UploadInspirationComponent
            triggerme={(e) => {
              this.triggerme(e);
            }}
            fileUploadState={(e, type) => {
              if (type === 'remove') {
                const localArr = [];
                uploadInspirationFiles.map((file, index) => {
                  if (file.name !== e) {
                    localArr.push(file);
                  }
                  if (index + 1 === uploadInspirationFiles.length) {
                    this.setState({
                      uploadInspirationFiles: localArr,
                    });
                  }
                  return file;
                });
              } else {
                uploadInspirationFiles.push(e);
                this.setState({
                  uploadInspirationFiles,
                });
              }
            }}
          />
        );
        break;
      case 'area_specification':
        component = (
          <AreaSpecificationComponent
            triggerme={(e) => {
              this.triggerme(e);
            }}
          />
        );
        break;
      case 'area_specification_details':
        component = (
          <AreaSpecificationDetailsComponent
            triggerme={(e) => {
              this.triggerme(e);
            }}
          />
        );
        break;
      case 'overview_information':
        component = (
          <CheckoutComponent
            triggerme={(e) => {
              this.triggerme(e);
            }}
          />
        );
        break;
      case 'billing_and_shipment_address':
        component = (
          <BillingShipmentAddressComponent
            triggerme={(e) => {
              this.triggerme(e);
            }}
          />
        );
        break;
      case 'offer_completed':
        component = (
          <OfferServiceCompletedComponent
            triggerme={(e) => {
              this.triggerme(e);
            }}
            supportFile={supportFile}
            uploadInspirationFiles={uploadInspirationFiles}
          />
        );
        break;
      case 'brief_tool_or_upload_inspiration':
        component = (
          <BriefOrUploadInspirationComponent
            triggerme={(e) => {
              this.triggerme(e);
            }}
          />
        );
        break;
      case 'brief_tool_1':
        component = (
          <BriefTool1Component
            triggerme={(e) => {
              this.triggerme(e);
            }}
          />
        );
        break;
      case 'interior_selective':
        component = (
          <InteriorSelectiveComponent
            triggerme={(e) => {
              this.triggerme(e);
            }}
          />
        );
        break;
      case 'pattern_selective':
        component = (
          <PatternSelectiveComponent
            triggerme={(e) => {
              this.triggerme(e);
            }}
          />
        );
        break;
      case 'color_selective':
        component = (
          <ColorSelectiveComponent
            triggerme={(e) => {
              this.triggerme(e);
            }}
          />
        );
        break;
      case 'brief_checkout':
        component = (
          <BriefCheckoutComponent
            triggerme={(e) => {
              this.triggerme(e);
            }}
          />
        );
        break;
      case 'brief_completed_offer':
        component = (
          <BriefOFferCompletedComponent
            uploadInspirationFiles={uploadInspirationFiles}
            triggerme={(e) => {
              this.triggerme(e);
            }}
          />
        );
        break;
      default:
        component = false;
        break;
    }

    if (component) {
      let backBtnWrapper = '';
      const { form } = this.props;
      if (form) {
        const { forms } = form;
        if (forms) {
          const { backBtn } = forms;
          if (backBtn) {
            if (!(forms.offer_service_completed && forms.currentStep === 'upload_files')) {
              backBtnWrapper = (
                <Col xs={12} className="back-btn choiceRole-cards">
                  <BtnGhost
                    content="Back"
                    trigger
                    triggerme={() => {
                      const { backStep } = this.props;
                      const { Steps } = forms;
                      backStep();
                      this.triggerme(Steps[Steps.length - 1]);
                    }}
                  />
                </Col>
              );
            }
          }
        }
      }
      return (
        <div>
          {component}

          <div className="container-buttons  mt-5 mb-5">
            {backBtnWrapper}
            <div className="next-btn next-btn-sidebar">
              <SideBarNextBtn />
            </div>
          </div>
        </div>
      );
    }

    let SpecifiedJob = '';
    if (fieldWork) {
      SpecifiedJob = (
        <Col xs={12}>
          <Input
            name="JobTtitle"
            type="text"
            label="Enter specific Job title"
            reducer
            value={JobTtitle}
            parentupdate={(e) => {
              this.handleInputChange(e);
              this.checkValidation();
              this.checkNextBtn();
            }}
            error={validator.isEmpty(JobTtitle)}
            errorContent="Please Fill Job Title"
          />
        </Col>
      );
    }
    return (
      <Container
        fluid
        className="choiceRole-container pick-role-container p-0 m-0"
      >
        <Row>
          <Col xs={12} className="mt-3">
            <form
              className="form"
              onSubmit={(e) => {
                this.handleSubmit(e);
              }}
            >
              <MainHeadlineSmall
                text={"What's your name and your role?"}
                topline="01 - PRODUCT AND PRICE RANGE"
                subline="Please enter your name and e-mail address to use the assistant to its full potential."
              />
              <Row>
                <Spacing space={63} extraClass="d-none d-md-block" />
                <Spacing space={30} extraClass="d-block d-md-none" />
              </Row>
              <Col lg={8} xs={12} className="p-lg-0 p-mob-0">
                <Row>
                  <Col lg={6}>
                    <h5 className="subline font-weight-light">
                      Your email address*
                    </h5>
                  </Col>
                  <Col lg={6}>
                    <Input
                      name="emailAddress"
                      value={emailAddress}
                      parentupdate={(e) => {
                        this.handleInputChange(e);
                      }}
                      type="email"
                      label="e.g. john@johndoe.com"
                      reducer
                      error={!validator.isEmail(emailAddress)}
                      errorContent="Please Fill Email Address correctly"
                    />
                  </Col>
                  <Col lg={6}>
                    <h5 className="subline font-weight-light">First Name</h5>
                  </Col>
                  <Col lg={6}>
                    <Input
                      name="ContactFirstName"
                      type="text"
                      label="First Name"
                      reducer
                      value={ContactFirstName}
                      // error={validator.isEmpty(ContactFirstName)}
                      // errorContent="Please Fill Last Name"
                      parentupdate={(e) => {
                        this.handleInputChange(e);
                      }}
                    />
                  </Col>
                  <Col lg={6}>
                    <h5 className="subline font-weight-light">Last Name</h5>
                  </Col>
                  <Col lg={6}>
                    <Input
                      name="ContactLastName"
                      type="text"
                      label="Last Name"
                      reducer
                      value={ContactLastName}
                      // error={validator.isEmpty(ContactLastName)}
                      // errorContent="Please Fill Last Name"
                      parentupdate={(e) => {
                        this.handleInputChange(e);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Row>
                <Spacing space={63} extraClass="d-none d-md-block" />
                <Spacing space={30} extraClass="d-block d-md-none" />
              </Row>
              <h5 className="subline font-weight-light">
                Pick the option that best fits your field of work:
              </h5>
              <Col className="p-lg-0 pick-role-parent">
                <Row>
                  <Spacing space={39} extraClass="d-none d-md-block" />
                  <Spacing space={20} extraClass="d-block d-md-none" />
                </Row>
                <Col xs={12} className="pick-role-wrapper">
                  <Row className="boxes">
                    <Col
                      lg={3}
                      xs={6}
                      onClick={() => {
                        this.triggersubmit('How Can We Help You');
                        this.addExtraFields('you_are', 'Architects');
                      }}
                    >
                      <CardsComponent title="Architects" topline="FOR" />
                    </Col>
                    <Col
                      lg={3}
                      xs={6}
                      onClick={() => {
                        this.triggersubmit('How Can We Help You');
                        this.addExtraFields('you_are', 'Interior Designer');
                      }}
                    >
                      <CardsComponent title="Interior Designer" topline="FOR" />
                    </Col>
                    <Col
                      lg={3}
                      xs={6}
                      onClick={() => {
                        this.triggersubmit('How Can We Help You');
                        this.addExtraFields('you_are', 'Procurement Agents');
                      }}
                    >
                      <CardsComponent
                        title="Procurement Agents"
                        topline="FOR"
                      />
                    </Col>
                    <Col
                      lg={3}
                      xs={6}
                      onClick={() => {
                        this.triggersubmit('How Can We Help You');
                        this.addExtraFields('you_are', 'Hotel Chains');
                      }}
                    >
                      <CardsComponent title="Hotel Chains" topline="FOR" />
                    </Col>
                  </Row>

                  <Row>
                    <Spacing space={39} />
                  </Row>

                  <Row>
                    <hr />
                  </Row>

                  <button
                    type="submit"
                    className="d-none"
                    id="hidden-submit-btn"
                    aria-label="submit"
                  />

                  <Row>
                    <Col lg={5} xs={12} className="p-lg-0 hide-topline">
                      <H4 text="Different job description?" />
                    </Col>
                    <Col lg={6} xs={12} className="p-lg-0">
                      <Row>
                        <Col xs={12}>
                          <ToggleRadioComponent
                            name="fieldWork"
                            value={fieldWork}
                            required={false}
                            title="My Field of work is not mentioned"
                            reducer
                            parentupdate={(e) => {
                              addForm({ fieldWork: e.target.checked });
                              this.setState({ fieldWork: e.target.checked });
                              this.checkNextBtn();
                            }}
                          />
                        </Col>
                        <Row>
                          <Spacing space={39} />
                        </Row>
                        {SpecifiedJob}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Col>
            </form>
          </Col>

          <Col xs={12} className="choiceRole-cards mt-5" />
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
    backStep: (form) => dispatch(backStep(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChoiceRoleComponent);
