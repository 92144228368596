import React from 'react';

const UploadArrowICO = () => (
  <svg
    className="arrow-upload-ico"
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="29"
    viewBox="0 0 17 29"
  >
    <g>
      <text
        fill="#1f1d1a"
        fontSize="24px"
        fontWeight="600"
        transform="translate(-430 -1093) translate(430 1093)"
      >
        <tspan x="0" y="23">
          ↑
        </tspan>
      </text>
    </g>
  </svg>
);
export default UploadArrowICO;
