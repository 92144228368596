import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { addForm } from '../../../reducer/actions';
import InfoBoxComponent from '../../atoms/infoBox';

import TotalPriceCalculationComponent from './TotalPriceCalculation';

class PriceCalculationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currency: 'eur',
      currencyDisplay: '€',
    };
  }

  componentDidMount() {
    const { addForm } = this.props;
    addForm({ currency: 'eur' });
  }

  render() {
    const { currencyDisplay, currency } = this.state;

    // prettier-ignore
    const {
      form
    } = this.props;

    // prettier-ignore
    const {
      forms
    } = form;

    const { ProductSelection, currentStep } = forms;

    let TotalPrice = 0;

    const rows = [];

    const mapArr = ['you_are', 'areaType'];
    mapArr.map((loop) => {
      if (forms[loop]) {
        let header = '';
        let box = false;
        switch (loop) {
          case 'you_are':
            header = 'YOU ARE';
            box = (
              <InfoBoxComponent
                question={1}
                info={
                  "We've structured the process to match your field of work."
                }
              />
            );
            break;
          case 'areaType':
            header = 'YOUR CHOSEN AREA';
            break;
          default:
            header = '';
        }

        return rows.push(
          <li>
            <p className="header">{header}</p>
            <div className="content">
              <span className="content_text">{forms[loop]}</span>
              {box}
            </div>
          </li>
        );
      }
      return false;
    });

    if (forms.roomTypeSelection) {
      const rooms = [];
      forms.roomTypeSelection.map((value, index) => {
        let title = value[1];
        if (index + 1 !== forms.roomTypeSelection.length) {
          title += ',';
        }
        rooms.push(<span key={title.toString()}>{title}</span>);
        if (index + 1 === forms.roomTypeSelection.length) {
          rows.push(
            <li key={index.toString()}>
              <p className="header">YOUR CHOSEN AREA</p>
              <div className="content">
                <span className="content_text">{rooms}</span>
                <InfoBoxComponent />
              </div>
            </li>
          );
        }
        return rows;
      });
    }

    const localArr = [];
    let productSelectionRow = '';
    if (ProductSelection) {
      if (Array.isArray(ProductSelection)) {
        ProductSelection.map((value, index) => {
          let extraInfo = '';

          let insertArr = '';
          if (value.variantType.value === 'entireProject') {
            if (value.price !== 0) {
              // prettier-ignore
              const Price = currency === 'eur' ? value.prices.priceEur : value.prices.priceUsd;
              if (forms.areaType === 'Area Rug') {
                // prettier-ignore
                const totalSum = parseFloat(Price) * parseFloat(forms.areaSizeInSqm);
                if (forms.amount) {
                  TotalPrice += parseFloat(totalSum * forms.amount);
                } else {
                  TotalPrice += parseFloat(totalSum);
                }
              } else {
                TotalPrice += parseFloat(Price * forms.areaSizeInSqm);
              }
              extraInfo = (
                <span className="extrainfo-product-price">
                  Net Price plus duties & taxes if applicable, incoterm EXW
                  Germany
                </span>
              );
            }
            // prettier-ignore
            const PriceDisplay = currency === 'eur' ? value.prices.priceDisplayEur : value.prices.priceDisplayUsd;
            insertArr = (
              <li>
                1 x {value.variantType.label} „ {value.owner.title} &quot;{' '}
                <span className="price">
                  {`${forms.areaSizeInSqm} sqm`} x {PriceDisplay}
                </span>
                {extraInfo}
              </li>
            );
          } else {
            if (value.priceEur !== 0) {
              // prettier-ignore
              const Price = currency === 'eur' ? value.priceEur : value.priceUsd;

              TotalPrice += parseFloat(Price);
              extraInfo = (
                <span className="extrainfo-product-price">
                  Net Price plus duties & taxes if applicable, incoterm DAP
                </span>
              );
              if (value.variantType.value === 'mockup') {
                extraInfo = (
                  <span className="extrainfo-product-price">
                    Net Price plus duties & taxes if applicable, incoterm DAP
                  </span>
                );
              }
            }
            if (value.variantType.value === 'qualitySample') {
              extraInfo = (
                <span className="extrainfo-product-price">
                  Net Price plus duties & taxes if applicable, incoterm DAP
                </span>
              );
            }

            // prettier-ignore
            const PriceDisplay = currency === 'eur' ? value.priceDisplayEur : value.priceDisplayUsd;
            insertArr = (
              <li>
                1 x {value.variantType.label} „ {value.owner.title} &quot;{' '}
                <span className="price">{PriceDisplay}</span>
                {extraInfo}
              </li>
            );
          }

          localArr.push(insertArr);

          if (index + 1 === ProductSelection.length) {
            productSelectionRow = localArr;
          }
          return false;
        });
      }
    }

    let ListExtra = [];
    ListExtra.push('');
    // if (forms.AreaSpecificationYourCountry) {
    //   ListExtra.push(
    //     <li>
    //       Pre-tax from
    //       <span className="price">0  {currencyDisplay}</span>
    //     </li>
    //   );

    //   TotalPrice += 100;
    //   ListExtra.push(
    //     <li>
    //       Taxes (16%)
    //       <span className="price">100  {currencyDisplay}</span>
    //     </li>
    //   );
    // }

    // extra price
    // prettier-ignore
    if ((forms.offer_service_completed && currentStep !== 'brief_completed_offer') || forms.design_concept === 1) {
      const DesignConceptPrice = currencyDisplay === '€' ? 90 : 110;
      localArr.push(
        <li>
          1 x Design Concept by LIGEA
          <span className="price">{DesignConceptPrice} {currencyDisplay}</span>
          <span className="extrainfo-product-price">
            Net Price plus duties & taxes if applicable
          </span>
        </li>
      );
      TotalPrice += DesignConceptPrice;
      productSelectionRow = localArr;
    }

    let TotalPriceCalculation = (
      <TotalPriceCalculationComponent
        type="normal"
        TotalPrice={TotalPrice}
        currencyDisplay={currencyDisplay}
      />
    );

    // prettier-ignore
    if (
      currentStep === 'overview_information' || currentStep === 'billing_and_shipment_address'
    ) {
      TotalPriceCalculation = <TotalPriceCalculationComponent type="orange" TotalPrice={TotalPrice} currencyDisplay={currencyDisplay} />;
    }

    let shippingInfo = '';
    // if (TotalPrice > 0) {
    //   shippingInfo = (
    //     <div className="shipping-info">
    //       <InfoBoxComponent question={0} />
    //       <div className="shipping-content-info">
    //         shipping not included, costs from Hamburg from 150 {currencyDisplay}{' '}
    //         <br />
    //         shipment from Hamburg takes 8-10 working days
    //       </div>
    //     </div>
    //   );
    // }

    let currencySelector = (
      <div className="form currency-selector col-12">
        <select
          onChange={(e) => {
            const { addForm } = this.props;
            addForm({ currency: e.target.value });

            const currencyDisplay = e.target.value === 'eur' ? '€' : '$';

            this.setState({
              currency: e.target.value,
              currencyDisplay,
            });
          }}
          value={currency}
        >
          <option value="eur">€</option>
          <option value="usd">$</option>
        </select>
      </div>
    );

    // prettier-ignore
    if (!productSelectionRow) {
      productSelectionRow = (
        <div style={{ opacity: 0 }}>{productSelectionRow}</div>
      );
      TotalPriceCalculation = (
        <div style={{ opacity: 0 }}>{TotalPriceCalculation}</div>
      );
      shippingInfo = <div style={{ opacity: 0 }}>{shippingInfo}</div>;
      currencySelector = <div style={{ opacity: 0 }}>{currencySelector}</div>;
    }

    // prettier-ignore
    const checkStorage = localStorage.getItem('ligeaForm');
    if (checkStorage) {
      // prettier-ignore
      if (forms.interiorSelective || forms.currentStep === 'brief_tool_1' || forms.currentStep === 'interior_selective') {
        productSelectionRow = '';
        TotalPriceCalculation = '';
        shippingInfo = '';
        ListExtra = '';
        currencySelector = '';
      }
    }

    return (
      <Col xs={12} className="price-calculations">
        <ul className="product-selection-row">
          {productSelectionRow} {ListExtra}
        </ul>

        {TotalPriceCalculation}

        {currencySelector}

        {shippingInfo}
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceCalculationComponent);
