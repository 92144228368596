import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import { addForm } from '../../reducer/actions';

import Spacing from '../atoms/spacing/spacing';
import Input from '../atoms/forms/input';
import CardsComponent from '../molecules/cards/cards';
import { MainHeadlineSmall } from '../molecules/typography/heading';

class ProjectCategoryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ProjectCategory: '',
    };
  }

  componentDidMount() {
    const { addForm, form } = this.props;
    const { forms } = form;
    setTimeout(() => {
      this.validateFields();
    }, 200);

    const { bakcBtnComp } = forms;
    if (bakcBtnComp) {
      addForm({ backBtn: bakcBtnComp });
    } else {
      setTimeout(() => {
        if (forms.Steps.length < 1) {
          const stepsRow = [
            'start_page', 'How Can We Help You'
          ];
          addForm({ Steps: stepsRow });
        }
      }, 300);
      addForm({ backBtn: 'How Can We Help You' });
    }
  }

  handleInputChange(e) {
    const { value } = e.target;
    this.setState({
      ProjectCategory: value,
    });
    setTimeout(() => {
      this.validateFields();
    }, 200);
  }

  validateFields() {
    const { ProjectCategory } = this.state;
    if (ProjectCategory.length > 2) {
      this.addExtraFields('sidebarNextEnable', true);
      this.addExtraFields('hideSidebar', false);
      this.addExtraFields('nextStepSidebar', 'all_rooms');
      this.addExtraFields('room_type', ProjectCategory);
    } else {
      const { form } = this.props;
      const { forms } = form;
      if (forms.inspirationPics) {
        this.addExtraFields('hideSidebar', false);
      }
      this.addExtraFields('sidebarNextEnable', false);
      this.addExtraFields('nextStepSidebar', false);
      this.addExtraFields('room_type', 0);
    }

    const { form } = this.props;
    const { forms } = form;
    if (forms.offer_service_completed) {
      this.addExtraFields('sidebarNextEnable', false);
      this.addExtraFields('hideSidebar', false);
    }
  }

  addExtraFields(field, form) {
    const { addForm } = this.props;

    addForm({ [field]: form });
    // addForm({ hideSidebar: false });
  }

  triggersubmit(e, d) {
    const { addForm } = this.props;

    addForm({ ProjectCategory: d });
    addForm({ currentStep: e });
    addForm({ parentLead: '' });
  }

  render() {
    const { ProjectCategory } = this.state;

    return (
      <Container
        fluid
        className="choiceRole-container p-0 m-0 projectCategory-container"
      >
        <Row>
          <Col xs={12}>
            <MainHeadlineSmall
              text="Please give us more information about your project"
              topline="01 - PRODUCT AND PRICE RANGE"
              subline="We will recommend only the carpet qualities that are relevant for your individual project."
            />
          </Col>
          <Col>
            <Row>
              <Spacing space={39} />
            </Row>
            <Col xs={12} className="p-mob-0">
              <Row>
                <Col
                  lg={3}
                  xs={6}
                  onClick={() => {
                    this.triggersubmit('room_type', 'Home');
                  }}
                >
                  <CardsComponent title="Home" topline="FOR" />
                </Col>
                <Col
                  lg={3}
                  xs={6}
                  onClick={() => {
                    this.triggersubmit('hospitality_room', 'Hospitality');
                  }}
                >
                  <CardsComponent title="Hospitality" topline="FOR" />
                </Col>
                <Col
                  lg={3}
                  xs={6}
                  onClick={() => {
                    this.triggersubmit('office_room', 'Office');
                  }}
                >
                  <CardsComponent title="Office" topline="FOR" />
                </Col>
                <Col
                  lg={3}
                  xs={6}
                  onClick={() => {
                    this.triggersubmit('retail_room', 'Retail');
                  }}
                >
                  <CardsComponent title="Retail" topline="FOR" />
                </Col>
              </Row>
              <Row>
                <Spacing space={39} />
              </Row>
            </Col>
          </Col>
          <Col xs={12} className="choiceRole-cards mt-5 form form-desc">
            <p className="font-weight-light">
              Your carpets are planned for a different area of installation?
            </p>
            <Input
              name="ProjectCategory"
              type="text"
              label="Enter specific project category"
              reducer
              value={ProjectCategory}
              parentupdate={(e) => {
                this.handleInputChange(e);
              }}
            />
          </Col>
          <Row>
            <Spacing space={39} />
          </Row>
          <button
            type="submit"
            aria-label="Submit"
            className="d-none"
            id="hidden-submit-btn"
          />
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectCategoryComponent);
