import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

import EmptyImage from '../../../assets/images/emptyPictureUpload.jpg';

import RemoveIco from './remove-ico';

class PictureUpload extends Component {
  render() {
    const { file, deleteFile } = this.props;

    let img = <img src={EmptyImage} alt="empty" />;
    let name = '';
    if (file) {
      let currUrl = '';
      if (file.file_store.type.includes('image')) {
        currUrl = URL.createObjectURL(file.file_store);
      } else {
        currUrl = EmptyImage;
      }
      img = <img src={currUrl} alt={file.fileName} />;
      name = file.fileName;
    }
    return (
      <Row className="singlepic-upload">
        <Col xs={12}>
          <Col
            xs={12}
            className="delete-file"
            onClick={() => {
              const confirm = window.confirm(
                'Are you sure you want to delete this file ?'
              );
              if (confirm === true) {
                deleteFile(file);
              }
            }}
          >
            <RemoveIco />
          </Col>
          <Col xs={12} className="image p-lg-0">
            {img}
          </Col>
          <Col xs={12} className="title">
            <p>{name}</p>
          </Col>
        </Col>
      </Row>
    );
  }
}

export default PictureUpload;
