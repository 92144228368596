import React, { Component } from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { addForm } from '../../../reducer/actions';

import { H4 } from '../../atoms/typography/heading';
import { MainHeadlineH2 } from '../typography/heading';

import UploadArrowICO from '../../atoms/image/upload-arrow';
import RemoveIco from '../../atoms/image/remove-ico';
import { BtnPrimary } from '../../atoms/buttons/buttons';

class CardsUploadComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: false,
      modalLaunch: false,
      useExactDesign: true,
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.removeUploaded = this.removeUploaded.bind(this);
  }

  componentDidMount() {
    // prettier-ignore
    const {
      reducer,
      form,
      name
    } = this.props;

    if (reducer) {
      const value = form[name];

      if (value && value.files) {
        const file = {
          fileName: value.files.name,
          file_location: value.files.value,
          input_name: name,
        };

        this.setState({
          file,
          modalLaunch: true,
        });
      }
    }
  }

  handleOnChange(e) {
    // prettier-ignore
    const {
      name,
      reducer,
      addForm,
      parentupdate,
      fileUploadState
    } = this.props;

    if (e.target.value) {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      fileUploadState(e.target.files[0]); // parent state

      const file = {
        file_store: formData,
        fileName: e.target.files[0].name,
        file_location: e.target.value,
        input_name: e.target.name,
      };
      this.setState({
        file,
        modalLaunch: true,
      });

      if (reducer) {
        addForm({
          [name]: file,
        });
        if (parentupdate) {
          parentupdate(e);
        }
      }
    }
  }

  handleClose() {
    this.setState({
      modalLaunch: false,
    });
  }

  removeUploaded() {
    const file = false;
    this.setState({
      file,
    });
  }

  render() {
    // prettier-ignore
    const {
      file,
      modalLaunch,
      useExactDesign
    } = this.state;
    // prettier-ignore
    const {
      modal,
      nextButton,
      name,
      title,
      topline
    } = this.props;

    let fileName = '';
    let labelClass = 'condensed ';
    let uploadIco = <UploadArrowICO />;
    if (file.fileName) {
      fileName = <p className="file_name">{file.fileName}</p>;
      labelClass += 'active';
      uploadIco = (
        <div
          aria-hidden="true"
          onClick={() => {
            this.removeUploaded();
          }}
        >
          <RemoveIco />
        </div>
      );
    }

    let modalRow = '';
    if (modal && modalLaunch) {
      const button = file.fileName ? (
        <BtnPrimary
          content="Use this design"
          disabled={useExactDesign}
          trigger
          triggerme={() => {
            if (nextButton) {
              nextButton();
            }
          }}
        />
      ) : (
        <BtnPrimary content="Use this design" disabled />
      );
      modalRow = (
        <Modal
          className="confirm-modal white-modal"
          show
          backdrop="static"
          keyboard={false}
        >
          <button
            className="btn btn-transparent"
            type="button"
            onClick={() => {
              this.handleClose();
            }}
          >
            <span className="close-overlay-icon" />
          </button>
          <Modal.Body>
            <Row>
              <Col lg={8}>
                <MainHeadlineH2
                  text="Please confirm that you have the permission to use this design."
                  topline="Copyright Information"
                  subline="It's important for us to protect the intellectual property of creative people and therefore we only use your uploaded design if you are the owner or have the rights to use it. If this is not the case, our designers will be happy to create a new design for you based on your vision."
                  SubLineSpace={60}
                />
              </Col>
              <Col lg={4} className="card-upload">
                <div className="upload-part">
                  <div className="upload-input-wrapper">
                    <input
                      type="file"
                      name={name}
                      onChange={this.handleOnChange.bind(this)}
                    />
                    <label className={labelClass} htmlFor={name}>
                      Upload File
                    </label>
                    {fileName}
                    {uploadIco}
                  </div>
                  <div className="read-permission form">
                    <input
                      type="checkbox"
                      id="PermissionExactDesign"
                      name="PermissionExactDesign"
                      value="PermissionExactDesign"
                      onChange={() => {
                        this.setState({
                          useExactDesign: !useExactDesign,
                        });
                      }}
                    />
                    <label htmlFor="PermissionExactDesign">
                      Yes, I have permission to use this exact design.
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <p
              className="underline cursor no-permission"
              onClick={() => {
                const { addForm } = this.props;
                addForm({ bakcBtnComp: 'design_support' });

                addForm({
                  currentStep: 'upload_inspiration',
                });
              }}
            >
              I don&apos;t have permission
            </p>
            {button}
          </Modal.Footer>
        </Modal>
      );
    }

    return (
      <div>
        {modalRow}
        <Row
          className="row-card card-upload"
          onClick={() => {
            document.getElementById(`${name}_input`).click();
          }}
        >
          <Col>
            <div className="header">
              <H4 text={title} topline={topline} />
            </div>
            <div className="upload-part">
              <input
                type="file"
                name={name}
                id={`${name}_input`}
                onChange={this.handleOnChange.bind(this)}
              />
              <label className={labelClass} htmlFor={name}>
                Upload File
              </label>
              {fileName}
              {uploadIco}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardsUploadComponent);
