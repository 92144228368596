import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { addForm } from '../../../reducer/actions';
import InfoBoxComponent from '../../atoms/infoBox';
import PriceCalculationComponent from '../price/PriceCalculation';

class SideBarFields extends Component {
  render() {
    // prettier-ignore
    const {
      form
    } = this.props;

    // prettier-ignore
    const {
      forms
    } = form;

    const rows = [];

    const mapArr = ['you_are', 'areaType'];
    mapArr.map((loop, index) => {
      if (forms[loop]) {
        let value = '';

        let header = '';
        let box = false;
        switch (loop) {
          case 'you_are':
            value = forms.you_are;
            header = 'YOU ARE';
            box = (
              <InfoBoxComponent
                question={1}
                info={
                  "We've structured the process to match your field of work."
                }
              />
            );
            break;
          case 'areaType':
            value = forms[loop];
            header = 'CARPET TYPE';
            break;
          default:
            header = '';
        }

        // if (box) {
        return rows.push(
          <li key={index.toString()}>
            <p className="header">{header}</p>
            <div className="content">
              <span className="content_text">{value}</span>
              {box}
            </div>
          </li>
        );
        // }
        // return false;
      }
      return false;
    });

    if (forms.roomTypeSelection) {
      const rooms = [];
      forms.roomTypeSelection.map((value, index) => {
        let title = value[1];
        if (index + 1 !== forms.roomTypeSelection.length) {
          title += ', ';
        }
        rooms.push(<span key={index.toString()}>{title}</span>);
        if (index + 1 === forms.roomTypeSelection.length) {
          rows.push(
            <li>
              <p className="header">YOUR CHOSEN AREA</p>
              <div className="content">
                <span className="content_text">{rooms}</span>
              </div>
            </li>
          );
        }
        return rows;
      });
    }

    if (forms.interiorSelective) {
      const rooms = [];
      forms.interiorSelective.map((value, index) => {
        let title = value[1];
        if (index + 1 !== forms.interiorSelective.length) {
          title += ', ';
        }
        rooms.push(<span>{title}</span>);
        if (index + 1 === forms.interiorSelective.length) {
          rows.push(
            <li>
              <p className="header">CHOSEN INTERIOR STYLE</p>
              <div className="content">
                <span className="content_text">{rooms}</span>
              </div>
            </li>
          );
        }
        return rows;
      });
    }

    if (forms.patternSelective) {
      const rooms = [];
      forms.patternSelective.map((value, index) => {
        let title = value[1];
        if (index + 1 !== forms.patternSelective.length) {
          title += ', ';
        }
        rooms.push(<span>{title}</span>);
        if (index + 1 === forms.patternSelective.length) {
          rows.push(
            <li>
              <p className="header">YOUR CHOOSEN PATTERN</p>
              <div className="content">
                <span className="content_text">{rooms}</span>
              </div>
            </li>
          );
        }
        return rows;
      });
    }

    if (forms.colorsSelective) {
      const rooms = [];
      forms.colorsSelective.map((value, index) => {
        let title = value[1];
        if (index + 1 !== forms.colorsSelective.length) {
          title += ', ';
        }
        rooms.push(<span>{title}</span>);
        if (index + 1 === forms.colorsSelective.length) {
          rows.push(
            <li>
              <p className="header">COLOR SCHEME</p>
              <div className="content">
                <span className="content_text">{rooms}</span>
              </div>
            </li>
          );
        }
        return rows;
      });
    }

    return (
      <Col xs={12} className="selected-options">
        <ul>{rows}</ul>

        <PriceCalculationComponent />
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SideBarFields);
