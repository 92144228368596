import React from 'react';
import ArrowCardICO from '../image/ArrowCard';

export const BtnPrimaryWhite = (props) => {
  const { disabled, trigger, content } = props;
  return (
    <button
      type="button"
      className={`btn btn-white ${disabled ? ' disabled' : ''}`}
      onClick={(e) => {
        if (trigger && !disabled) {
          props.triggerme(e);
        }
      }}
    >
      <span className="anim">{content}</span>
    </button>
  );
};

export const BtnPrimary = (props) => {
  const { disabled, trigger, content } = props;
  return (
    <button
      type="button"
      className={`btn btn-primary ${disabled ? ' disabled' : ''}`}
      onClick={(e) => {
        if (trigger && !disabled) {
          props.triggerme(e);
        }
      }}
    >
      <span className="anim">{content}</span>
    </button>
  );
};

export const BtnGhost = (props) => {
  const { disabled, trigger, content } = props;
  return (
    <button
      type="button"
      className={`btn btn-ghost ${disabled ? ' disabled' : ''}`}
      id="back-btn-page"
      onClick={(e) => {
        if (trigger && !disabled) {
          props.triggerme(e);
        }
      }}
    >
      <span className="anim">{content}</span>
    </button>
  );
};

export const BtnNextFilled = (props) => {
  const { icon, trigger } = props;

  let iconWrapper = '';
  if (icon) {
    iconWrapper = (
      <span className="arrowcard-icon">
        <ArrowCardICO />
      </span>
    );
  }
  return (
    <button
      type="button"
      className="circular"
      onClick={(e) => {
        if (trigger) {
          props.triggerme(e);
        }
      }}
    >
      {iconWrapper}
    </button>
  );
};

export const BtnNextEmpty = (props) => {
  const { icon, trigger } = props;

  let iconWrapper = '';
  if (icon) {
    iconWrapper = (
      <span className="arrowcard-icon">
        <ArrowCardICO />
      </span>
    );
  }
  return (
    <button
      type="button"
      className="circular blank"
      onClick={(e) => {
        if (trigger) {
          props.triggerme(e);
        }
      }}
    >
      {iconWrapper}
    </button>
  );
};

export const BtnTransparentGhost = (props) => {
  const { disabled, trigger, content } = props;
  return (
    <button
      type="button"
      className={`btn btn-transparent-ghost ${disabled ? ' disabled' : ''}`}
      onClick={(e) => {
        if (trigger && !disabled) {
          props.triggerme(e);
        }
      }}
    >
      <span className="anim">{content}</span>
    </button>
  );
};
