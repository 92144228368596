import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

import { H4 } from '../../atoms/typography/heading';
import { BtnNextEmpty } from '../../atoms/buttons/buttons';

class CardsComponent extends Component {
  render() {
    // prettier-ignore
    const { topline, title } = this.props;

    return (
      <Row className="row-card">
        <Col>
          <div className="header">
            <H4 text={title} topline={topline} />
          </div>
          <BtnNextEmpty icon={1} />
        </Col>
      </Row>
    );
  }
}

export default CardsComponent;
