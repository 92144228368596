import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import validator from 'validator';

import Spacing from '../../atoms/spacing/spacing';

import Input from '../../atoms/forms/input';
import { addForm } from '../../../reducer/actions';

class ShippingInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShippingCompany: '',
      ShippingStreet: '',
      ShippingNumber: '',
      ShippingAddress: '',
      ShippingZip: '',
      ShippingCity: '',
      ShippingUST: '',
    };
  }

  componentDidMount() {
    const { form, ValidateParent } = this.props;
    const { forms } = form;

    const checkLines = [
      'ShippingCompany',
      'ShippingStreet',
      // 'ShippingNumber',
      // 'ShippingAddress',
      'ShippingZip',
      'ShippingCity',
      // 'ShippingUST',
    ];

    checkLines.map((key) => {
      if (forms[key]) {
        const st = this.state;
        st[key] = forms[key];
        this.setState((prevState) => ({ ...prevState, st }));
      }
      return false;
    });

    ValidateParent();
  }

  handleInputChange(e) {
    const { ValidateParent } = this.props;
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    const { addForm } = this.props;
    addForm({ name: value });

    ValidateParent();
  }

  render() {
    const {
      ShippingCompany,
      ShippingStreet,
      ShippingNumber,
      ShippingAddress,
      ShippingZip,
      ShippingCity,
      ShippingUST,
    } = this.state;

    return (
      <Row>
        <Col lg={4} className="mt-5">
          <h4>Shipping information</h4>
        </Col>
        <Col lg={8} className="mt-3">
          <Row>
            <Row>
              <Spacing space={60} />
            </Row>
            <Col xs={12} className="form-group">
              <Input
                name="ShippingCompany"
                type="text"
                label="Company name *"
                reducer
                value={ShippingCompany}
                error={validator.isEmpty(ShippingCompany)}
                errorContent="Please Fill Company name"
                parentupdate={(e) => {
                  this.handleInputChange(e);
                }}
              />
            </Col>
            <Col xs={12}>
              <Row>
                <Col xs={12} md={6} className="form-group">
                  <Input
                    name="ShippingStreet"
                    type="text"
                    label="Street *"
                    reducer
                    value={ShippingStreet}
                    error={validator.isEmpty(ShippingStreet)}
                    errorContent="Please Fill Street"
                    parentupdate={(e) => {
                      this.handleInputChange(e);
                    }}
                  />
                </Col>
                <Col xs={12} md={6} className="form-group">
                  <Input
                    name="ShippingNumber"
                    type="text"
                    label="No."
                    reducer
                    value={ShippingNumber}
                    // error={validator.isEmpty(ShippingNumber)}
                    // errorContent="Please Fill No."
                    parentupdate={(e) => {
                      this.handleInputChange(e);
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="form-group">
              <Input
                name="ShippingAddress"
                type="text"
                label="Additional address details"
                reducer
                value={ShippingAddress}
                // error={validator.isEmpty(ShippingAddress)}
                // errorContent="Please Fill Additional address details"
                parentupdate={(e) => {
                  this.handleInputChange(e);
                }}
              />
            </Col>
            <Col xs={12}>
              <Row>
                <Col xs={12} md={6} className="form-group">
                  <Input
                    name="ShippingZip"
                    type="text"
                    label="ZIP Code *"
                    reducer
                    value={ShippingZip}
                    error={validator.isEmpty(ShippingZip)}
                    errorContent="Please Fill ZIP Code"
                    parentupdate={(e) => {
                      this.handleInputChange(e);
                    }}
                  />
                </Col>
                <Col xs={12} md={6} className="form-group">
                  <Input
                    name="ShippingCity"
                    type="text"
                    label="City *"
                    reducer
                    value={ShippingCity}
                    error={validator.isEmpty(ShippingCity)}
                    errorContent="Please Fill City"
                    parentupdate={(e) => {
                      this.handleInputChange(e);
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="form-group">
              <Input
                name="ShippingUST"
                type="text"
                label="USt.-ID"
                reducer
                value={ShippingUST}
                // error={validator.isEmpty(ShippingUST)}
                // errorContent="Please Fill USt.-ID"
                parentupdate={(e) => {
                  this.handleInputChange(e);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShippingInformation);
