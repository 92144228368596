import React, { Component } from 'react';

import Logo from '../../assets/images/logo.svg';

class LogoComponent extends Component {
  render() {
    return (
      <div className="logo">
        <img src={Logo} className="img-fluid" alt="Logo" />
      </div>
    );
  }
}

export default LogoComponent;
