import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { addForm } from '../../reducer/actions';

import { MainHeadlineSmall } from '../molecules/typography/heading';
import Spacing from '../atoms/spacing/spacing';

import DefaultImage from '../../assets/images/product-selection-blank.png';

class ChoosenProducts extends Component {
  render() {
    // prettier-ignore
    const {
      form
    } = this.props;

    // prettier-ignore
    const {
      forms
    } = form;

    const { ProductSelection } = forms;

    let productSelectionRow = [];
    if (ProductSelection) {
      if (Array.isArray(ProductSelection)) {
        const Products = {};

        ProductSelection.map((value, index) => {
          if (!Products[value.owner.title]) {
            Products[value.owner.title] = [];
          }

          Products[value.owner.title].push(value);

          if (index + 1 === ProductSelection.length) {
            Object.keys(Products).map((key) => {
              const localProd = [];
              if (Array.isArray(Products[key])) {
                let images = '';
                let title = '';
                Products[key].map((productRow, index) => {
                  title = productRow.owner.title;
                  images = productRow.productImage;
                  if (!images) {
                    images = DefaultImage;
                  }
                  localProd.push(
                    <li>
                      <span className="quantity">1 x</span>{' '}
                      {productRow.variantType.label} „{productRow.owner.title}{' '}
                      &quot;
                      <span className="price">{productRow.priceDisplay}</span>
                    </li>
                  );

                  if (index + 1 === Products[key].length) {
                    productSelectionRow.push(
                      <Row className="chosen-product-row">
                        <Col lg={4} xs={12} className="product-image">
                          <img
                            src={images}
                            alt="Product "
                            className="img-fluid"
                          />
                        </Col>
                        <Col lg={8} xs={12}>
                          <h3 className="font-weight-light product-title">
                            {title}
                          </h3>
                          <ul>{localProd}</ul>
                        </Col>
                      </Row>
                    );
                  }
                  return false;
                });
              }
              return false;
            });
          }
          return false;
        });
      }
    }

    if (!productSelectionRow) {
      productSelectionRow = (
        <ul>
          <li>No products selected</li>
        </ul>
      );
    }

    if (productSelectionRow.length < 1) {
      return (
        <Col xs={12} className="chosen-products">
          {' '}
        </Col>
      );
    }

    return (
      <Col xs={12} className="chosen-products">
        <MainHeadlineSmall
          topline="PRODUCT"
          text="Your chosen Products"
          subline="You have selected the following products."
        />
        <Row>
          <Spacing space={39} />
        </Row>
        {productSelectionRow}
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ChoosenProducts);
