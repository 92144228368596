import React, { Component } from 'react';
import GAManagerApi from '../../../helpers/ga-manager-api';

class CookieNoticeComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accepted: false,
      active: false,
      storageKeyConsented: 'gdm-consent',
      storageKeyAnonymous: 'gdm-anonymous',
      storageValuePositive: 'yes',
      storageValueNegative: 'no',
      checkboxChecked: true,
    };

    this.checkboxRef = React.createRef();

    this.accept = this.accept.bind(this);
  }

  componentDidMount() {
    const {
      storageKeyConsented,
      storageKeyAnonymous,
      storageValuePositive,
      storageValueNegative,
    } = this.state;

    // prettier-ignore
    const isConsented = localStorage.getItem(storageKeyConsented) === storageValuePositive;
    // prettier-ignore
    const storageAonymousTrackingValue = localStorage.getItem(storageKeyAnonymous) || '';
    let isAnonymousTracking = false;
    // prettier-ignore
    if (
      storageAonymousTrackingValue === storageValuePositive
      || storageAonymousTrackingValue === storageValueNegative
    ) {
      isAnonymousTracking = true;
    }

    this.setState({
      accepted: isConsented && isAnonymousTracking,
      active: !(isConsented && isAnonymousTracking),
    });

    if (isConsented) {
      this.initAnalytics();
    }
  }

  handleAcceptClick(e) {
    e.preventDefault();

    const { checkboxChecked } = this.state;

    this.accept(checkboxChecked);
  }

  handleCheckboxClick(e) {
    const { checkboxChecked } = this.state;

    if (e) {
      this.setState({ checkboxChecked: e.target.checked });
    } else {
      this.setState({ checkboxChecked: !checkboxChecked });
    }
  }

  accept() {
    const { checkboxChecked } = this.state;

    const {
      storageKeyConsented,
      storageKeyAnonymous,
      storageValuePositive,
      storageValueNegative,
    } = this.state;

    const storageValueAnonymous = checkboxChecked
      ? storageValuePositive
      : storageValueNegative;
    localStorage.setItem(storageKeyConsented, storageValuePositive);
    localStorage.setItem(storageKeyAnonymous, storageValueAnonymous);

    setTimeout(() => {
      this.initAnalytics();
    }, 500);

    this.setState({ accepted: true, active: false });
  }

  initAnalytics() {
    const { checkboxChecked } = this.state;
    const gaApi = new GAManagerApi('G-TQK6SYQP3V');

    // Load and trigger page view
    gaApi.load(checkboxChecked);
  }

  render() {
    const { active, accepted, checkboxChecked } = this.state;

    return (
      <div
        id="js-consent-banner"
        className={active ? 'is-active' : ''}
        data-accepted={accepted}
      >
        <div className="consent-banner__content">
          <div className="consent-banner__texts">
            <p>
              Cookies &amp; Third Party Content. This website uses cookies and
              third-party content. In the following you can give or withdraw
              your consent. For further information, please refer to our privacy
              policy.
            </p>
          </div>
          <input
            type="checkbox"
            name="consent"
            checked={checkboxChecked}
            readOnly
          />
          <label
            htmlFor="js-consent-tracking"
            onClick={() => this.handleCheckboxClick(false)}
          >
            <span>Allow anonymous tracking (session)</span>
          </label>
          <div className="consent-banner__buttons">
            <a href="#" id="js-consent-banner-bt" onClick={this.accept}>
              Accept
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default CookieNoticeComponent;
