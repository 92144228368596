import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import { addForm } from '../../../reducer/actions';

import { RoundedImage } from '../../atoms/image/rounded';
import { H4 } from '../../atoms/typography/heading';

import ProfileImage from '../../../assets/images/profile-image.png';
import SideBarFields from './SideBarFields';
import SideBarNextBtn from './SideBarNextBtn';

class SideBarProfileComponent extends Component {
  render() {
    // prettier-ignore
    const {
      form
    } = this.props;

    // prettier-ignore
    const {
      forms
    } = form;

    // prettier-ignore
    const {
      // roomTypeSelection,
      hideSidebar,
    } = forms;

    let SideBarF = '';
    if (!hideSidebar) {
      SideBarF = '';
      SideBarF = <SideBarFields />;
    }
    return (
      <Row className="sidebar-profile">
        <Col xs={4} className="profile-image-wrapper">
          <RoundedImage image={ProfileImage} />
        </Col>
        <Col xs={8} className="personal-details">
          <Row>
            <H4 topline="Your Personal assistant" text="Kathrin Marschall" />
            <Col xs={12} className="phone-mail pl-lg-0">
              <p>P</p>
              <a className="link" href="tel:+49 721 6190921 30">
                +49 721 6190921 30
              </a>
            </Col>
            <Col xs={12} className="phone-mail pl-lg-0">
              <p>M</p>
              <a className="link" href="mailto:kathrin.marschall@ligea.com">
                kathrin.marschall@ligea.com
              </a>
            </Col>
          </Row>
        </Col>
        {SideBarF}
        <SideBarNextBtn />
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideBarProfileComponent);
