import React from 'react';

const RemoveIco = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.828"
    height="10.828"
    viewBox="0 0 10.828 10.828"
    className="remove-ico"
  >
    <g id="Gruppe_726" transform="translate(-1407.086 -1839.586)">
      <path
        id="Linie_2"
        d="M0 0L0 11.314"
        className="cls-1"
        transform="rotate(45 -1514.034 2630.367)"
      />
      <path
        id="Linie_3"
        d="M0 0L0 11.314"
        className="cls-1"
        transform="rotate(-45 2926.534 -779.71)"
      />
    </g>
  </svg>
);
export default RemoveIco;
