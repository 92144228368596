import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';

import LoadingAnimation from '../../atoms/forms/LoadingAnimation';

import ProductSelectionComponent from '../../molecules/product/Product';
import { MainHeadlineSmall } from '../../molecules/typography/heading';

import { addForm } from '../../../reducer/actions';
import Spacing from '../../atoms/spacing/spacing';

// import Products from '../../../assets/products/Products';

class ProductsAndPricesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRows: [],
      loadFinished: false,
      Products: [],
    };
  }

  componentDidMount() {
    const { addForm, form } = this.props;
    const { forms } = form;
    const { roomTypeSelection, ProductSelection } = forms;

    this.setState({
      loadFinished: true,
    });

    addForm({ interiorSelective: false });

    addForm({ sidebarNextEnable: false });
    addForm({ nextStepSidebar: false });
    const checkStorage = localStorage.getItem('ligeaForm');
    if (!checkStorage) {
      addForm({ ProductSelection: [] });
      addForm({ backBtn: 'area_specification' });
    } else {
      this.setState({
        selectedRows: ProductSelection,
      });
      const { bakcBtnComp } = forms;
      if (bakcBtnComp) {
        addForm({ backBtn: bakcBtnComp });
      }
    }

    const { Products } = this.state;

    if (Products.length < 1) {
      const rooms = [];
      roomTypeSelection.map((room, indexRoom) => {
        rooms.push(room[0]);
        if (indexRoom + 1 === roomTypeSelection.length) {
          const { areaSizeInSqm, areaType } = forms;
          const join = rooms.join(',');
          const areaSize = parseFloat(areaSizeInSqm);

          const url = `${forms.apiUrl}/api/v1/products?categories=${join}&areaSize=${areaSize}&areaType=${areaType}`;
          axios.get(url).then((res) => {
            const { data } = res;
            if (data) {
              this.setState({
                Products: data,
              });
            }
          });
        }
        return room;
      });
    }

    addForm({ nextStepSidebarText: 'Next' });
  }

  handleClick(type) {
    const { addForm } = this.props;
    addForm({ currentStep: type });
  }

  handlePicClick(e) {
    const key = e[0];
    const val = e[1];

    const KeyVal = val.variantType.value;

    const { addForm } = this.props;

    const { selectedRows } = this.state;

    if (selectedRows.length > 0) {
      let rowExist = false;
      selectedRows.map((value, index) => {
        if (value.owner.id === key) {
          const Valuekey = value.variantType.value;
          if (Valuekey === KeyVal) {
            selectedRows.splice(index, 1);
            rowExist = true;
          }
        }
        if (index + 1 === selectedRows.length) {
          if (!rowExist) {
            val.selected = true;
            selectedRows.push(val);
          }
        }
        return false;
      });
    } else {
      selectedRows.push(val);
    }

    if (selectedRows.length > 0) {
      addForm({ sidebarNextEnable: true });
      addForm({ hideSidebar: false });
      addForm({ nextStepSidebar: 'area_specification_details' });
    } else {
      addForm({ sidebarNextEnable: false });
      addForm({ nextStepSidebar: false });
    }

    this.setState({
      selectedRows,
    });

    addForm({ ProductSelection: selectedRows });
  }

  render() {
    let picList = [];
    const { selectedRows, loadFinished, Products } = this.state;

    const { form } = this.props;
    const { forms } = form;

    if (loadFinished) {
      const checkStorage = localStorage.getItem('ligeaForm');

      Products.map((product, pindex) => {
        if (!product.productType) {
          return false;
        }
        // prettier-ignore
        if ((product.productType.label !== forms.areaType) && product.productType.label !== 'All') {
          return false;
        }
        if (product.availableFromSqm > forms.areaSizeInSqm) {
          return false;
        }
        let prices = [];
        // prettier-ignore
        if (forms.where_do_you_need_support === 'Products and Prices' && !checkStorage) {
          product.variants.map((price) => {
            // if (price.variantType.value !== 'customDesignSample') {
            prices.push(price);
            // }
            return false;
          });
        } else {
          prices = product.variants;
        }
        picList.push(
          <Col lg={4} md={6} xs={12} className="mb-5" key={pindex.toString()}>
            <ProductSelectionComponent
              currency={forms.currency}
              MainIcon={1}
              label={product.title}
              info="Product Information"
              isSelected={selectedRows}
              image={product.productImage}
              product={product}
              select
              productId={product.id}
              name={product.title}
              triggerclick={(e) => {
                this.handlePicClick(e);
              }}
              price={prices}
            />
          </Col>
        );
        return product;
      });
    } else {
      picList = <LoadingAnimation />;
    }

    return (
      <Container fluid className="Product-container p-0 m-0">
        <Row>
          <Col xs={12}>
            <Col xs={12} className="pl-lg-0 p-mob-0">
              <MainHeadlineSmall
                text="Recommended products for your project"
                topline="02 - PRODUCT AND PRICE RANGE - PROJECT INFORMATION"
                subline="Please pick the desired product options so we can give you an individual offer."
              />
            </Col>
            <Col xs={12} className="pl-lg-0 p-mob-0">
              <Row>
                <Spacing space={39} />
              </Row>
              <Col xs={12} className="pl-lg-0 p-mob-0">
                <Row className="ProductSelectionRow">{picList}</Row>
                <Row className="d-none d-md-block">
                  <Spacing space={39} />
                </Row>
              </Col>
            </Col>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsAndPricesComponent);
