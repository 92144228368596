import React from 'react';

const SelectArrowICO = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.54"
    height="7.684"
    viewBox="0 0 12.54 7.684"
  >
    <path
      fill="none"
      stroke="#1f1d1a"
      strokeWidth="2px"
      d="M-1711.625-286l5.563 5.563 5.562-5.563"
      transform="translate(1712.332 286.707)"
    />
  </svg>
);
export default SelectArrowICO;
