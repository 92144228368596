import React from 'react';
import Topline from '../../atoms/typography/topline';
import { SubLine } from '../../atoms/typography/heading';
import Spacing from '../../atoms/spacing/spacing';

export const MainHeadlineH2 = (props) => {
  // prettier-ignore
  const {
    subline,
    text,
    topline,
    SubLineSpace
  } = props;

  if (subline) {
    return (
      <div>
        <h2>
          <Topline topline={topline} />
          <span>{text}</span>
        </h2>
        {SubLineSpace ? <Spacing space={SubLineSpace} /> : ''}
        <SubLine subline={subline} />
      </div>
    );
  }
  return (
    <h2>
      <Topline topline={topline} />
      <span>{text}</span>
    </h2>
  );
};

export const MainHeadline = (props) => {
  // prettier-ignore
  const {
    subline,
    text,
    topline,
    SubLineSpace
  } = props;

  if (subline) {
    return (
      <div>
        <h1>
          <Topline topline={topline} />
          <span>{text}</span>
        </h1>
        {SubLineSpace ? <Spacing space={SubLineSpace} /> : ''}
        <SubLine subline={subline} />
      </div>
    );
  }
  return (
    <h1>
      <Topline topline={topline} />
      <span>{text}</span>
    </h1>
  );
};

export const MainHeadlineSmall = (props) => {
  // prettier-ignore
  const {
    subline,
    text,
    topline,
    SubLineSpace
  } = props;

  if (subline) {
    return (
      <div>
        <h3>
          <Topline topline={topline} />
          <span>{text}</span>
        </h3>
        {SubLineSpace ? <Spacing space={SubLineSpace} /> : ''}
        <SubLine subline={subline} />
      </div>
    );
  }
  return (
    <h3>
      <Topline topline={topline} />
      <span>{text}</span>
    </h3>
  );
};
