import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import { addForm } from '../../../reducer/actions';

import Spacing from '../../atoms/spacing/spacing';
import CardsComponent from '../../molecules/cards/cards';
import { MainHeadlineSmall } from '../../molecules/typography/heading';

class BriefOrUploadInspirationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { addForm } = this.props;
    addForm({ backBtn: 'design_support' });
    addForm({ design_concept: 0 });
  }

  backButton() {}

  nextButton() {
    const { addForm } = this.props;

    addForm({ currentStep: 'upload_files' });
  }

  parentUpdate(e) {
    const { addForm } = this.props;

    addForm({ currentStep: e });
  }

  render() {
    return (
      <Container fluid className="p-0 m-0">
        <Row>
          <form action="" className="form">
            <Col xs={12}>
              <MainHeadlineSmall
                text="Where do you need support?"
                topline="HOW CAN WE HELP YOU?"
                subline="Do you already have some inspiration to upload for our designers or do you want to click through our creative brief tool and compose first design ideas?"
              />
              <Row>
                <Spacing space={39} />
              </Row>
              <Col xs={12} className="p-mob-0">
                <Row>
                  <Col
                    lg={4}
                    onClick={() => {
                      const { addForm } = this.props;

                      addForm({
                        currentStep: 'brief_tool_1',
                      });
                    }}
                  >
                    <CardsComponent topline="FOR" title="Creative Brief Tool" />
                  </Col>
                  <Col
                    lg={4}
                    onClick={() => {
                      const { addForm } = this.props;
                      addForm({
                        currentStep: 'upload_inspiration',
                      });
                    }}
                  >
                    <CardsComponent
                      topline="UPLOAD"
                      title="Upload your Inspiration"
                    />
                  </Col>
                </Row>
                <Row>
                  <Spacing space={39} />
                </Row>
              </Col>
            </Col>
          </form>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BriefOrUploadInspirationComponent);
