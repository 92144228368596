import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import validator from 'validator';
import { MainHeadlineSmall } from '../../molecules/typography/heading';

import Spacing from '../../atoms/spacing/spacing';
import { addForm } from '../../../reducer/actions';

import BriefChoosenDesigns from '../../organisms/Brief/ChoosenDesigns';
import BiefCheckoutContact from '../../organisms/Brief/CheckoutContact';
import OverviewInformationComponent from '../../organisms/OverviewInformation';

class BriefCheckoutComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { addForm } = this.props;
    addForm({ sidebarNextEnable: false });
    addForm({ nextStepSidebar: 'brief_completed_offer' });
    addForm({ nextStepSidebarText: 'Download Creative Brief' });
    addForm({ fullButtonSize: true });
    this.checkValidation();

    addForm({ backBtn: 'area_specification' });
  }

  checkValidation() {
    // prettier-ignore
    const { form, addForm } = this.props;
    const { forms } = form;

    const checkLines = [
      'emailAddress',
      'ContactFirstName',
      'ContactLastName',
      'BriefProjectName',
    ];

    let continueRow = true;
    checkLines.map((key, index) => {
      if (forms[key]) {
        let validate = validator.isEmpty(forms[key]);
        if (key === 'emailAddress') {
          validate = !validator.isEmail(forms[key]);
        }
        // prettier-ignore
        if (validate) {
          continueRow = false;
        }
      } else {
        continueRow = false;
      }

      if (index + 1 === checkLines.length) {
        if (continueRow) {
          addForm({ sidebarNextEnable: true });
          addForm({ nextStepSidebar: 'brief_completed_offer' });
          addForm({ nextStepSidebarText: 'Download Creative Brief' });
          addForm({ fullButtonSize: true });
        } else {
          addForm({ sidebarNextEnable: false });
        }
      }
      return false;
    });
  }

  triggerme(type) {
    const { addForm } = this.props;
    addForm({ currentStep: type });
  }

  render() {
    return (
      <Container fluid className="checkout-page p-0 m-0">
        <Row>
          <Col xs={12} className="mt-3 pl-lg-0 p-mob-0">
            <form action="" className="form">
              <Col xs={12} className="p-mob-0">
                <Col className="pl-lg-0 p-mob-0">
                  <Col xs={12} className="pl-lg-0 rows p-mob-0">
                    <Col xs={12} className="pl-lg-0">
                      <MainHeadlineSmall
                        topline="CHECKOUT"
                        text="Your contact details"
                        subline="To download your individually generated creative brief and receive it by email we need your contact details."
                      />
                    </Col>
                    <Row>
                      <Spacing space={39} />
                    </Row>
                    <BiefCheckoutContact
                      ValidateParent={() => {
                        this.checkValidation();
                      }}
                    />
                    <Spacing space={39} />
                  </Col>
                  <Col xs={12} className="pl-lg-0 rows">
                    <Row>
                      <Spacing space={39} />
                    </Row>
                    <BriefChoosenDesigns />
                    <Row>
                      <Spacing space={39} />
                    </Row>
                  </Col>
                  <Col xs={12} className="pl-lg-0 rows">
                    <Row>
                      <Spacing space={39} />
                    </Row>
                    <OverviewInformationComponent />
                    <Row>
                      <Spacing space={39} />
                    </Row>
                  </Col>
                </Col>
              </Col>
            </form>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BriefCheckoutComponent);
