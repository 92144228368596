import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import { addForm } from '../../../reducer/actions';

import SideBarProfileComponent from '../../molecules/sidebar/SideBar';

class SidebarComponent extends Component {
  render() {
    return (
      <Row className="h-100 align-middle">
        <Col xs={12}>
          <SideBarProfileComponent />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SidebarComponent);
