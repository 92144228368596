import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';

import LoadingAnimation from '../../atoms/forms/LoadingAnimation';

import PictureSelectionComponent from '../../atoms/image/PictureSelection';
import { MainHeadlineSmall } from '../../molecules/typography/heading';

import { addForm } from '../../../reducer/actions';
import Spacing from '../../atoms/spacing/spacing';

// import Retail from '../../../assets/products/Retail';

class RetailRoomComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRows: [],
      Retail: [],
    };
  }

  componentDidMount() {
    const { addForm, form } = this.props;
    const { forms } = form;

    addForm({ roomTypeSelection: [] });
    addForm({ sidebarNextEnable: false });
    addForm({ nextStepSidebar: false });
    addForm({ hideSidebar: false });

    const { Retail } = this.state;

    if (Retail.length < 1) {
      // const rows = [];
      const url = `${forms.apiUrl}/api/v1/categories/roomTypeCategory/177`;
      axios.get(url).then((res) => {
        const { data } = res;
        if (data) {
          this.setState({
            Retail: data,
          });
        }
      });
    }

    this.setState((prevState) => ({ ...prevState, Retail }));

    addForm({ backBtn: 'upload_files' });

    setTimeout(() => {
      if (forms.offer_service_completed) {
        const stepsRow = [
          'upload_files'
        ];
        addForm({ Steps: stepsRow });
      }
    }, 500);
  }

  handleClick(type) {
    const { addForm } = this.props;
    addForm({ currentStep: type });
  }

  handlePicClick(e) {
    const { addForm } = this.props;

    const { selectedRows } = this.state;
    const st = selectedRows;

    if (st.length > 0) {
      let exist = false;
      st.map((value, index) => {
        if (value[0] === e[0]) {
          exist = true;
          st.splice(index, 1);
        }

        if (index + 1 === st.length) {
          if (!exist) {
            st.push(e);
          }
        }
        return false;
      });
    } else {
      st.push(e);
    }

    this.setState({
      selectedRows: st,
    });

    if (st.length > 0) {
      addForm({ sidebarNextEnable: true });
      addForm({ nextStepSidebar: 'area_specification' });
    } else {
      addForm({ sidebarNextEnable: false });
      addForm({ nextStepSidebar: false });
    }

    addForm({ roomTypeSelection: st });
  }

  render() {
    // prettier-ignore
    const {
      selectedRows,
      Retail,
    } = this.state;

    let picList = [];
    if (Retail.length > 0) {
      Retail.map((pattern, ptindex) => {
        const { image } = pattern;
        const imageUrl = image ? { url: image } : false;

        const st = selectedRows;
        if (st.length > 0) {
          let exist = false;
          st.map((value, index) => {
            if (value[0] === pattern.id) {
              exist = true;
            }
            if (index + 1 === st.length) {
              picList.push(
                <Col
                  key={Math.random().toString()}
                  lg={4}
                  xs={6}
                  className="mb-5"
                >
                  <PictureSelectionComponent
                    limit={1}
                    alertInfo="Only 1 selection are limited"
                    counter="roomTypeSelection"
                    label={`${pattern.title}`}
                    isSelected={exist}
                    image={imageUrl}
                    select
                    id={pattern.id}
                    name={`${pattern.title}`}
                    triggerclick={(e) => {
                      this.handlePicClick(e);
                    }}
                  />
                </Col>
              );
            }
            return false;
          });
        } else {
          picList.push(
            <Col key={ptindex.toString()} lg={4} xs={6} className="mb-5">
              <PictureSelectionComponent
                limit={1}
                alertInfo="Only 1 selection are limited"
                counter="roomTypeSelection"
                label={`${pattern.title}`}
                image={imageUrl}
                select
                id={pattern.id}
                name={`${pattern.title}`}
                triggerclick={(e) => {
                  this.handlePicClick(e);
                }}
              />
            </Col>
          );
        }

        return false;
      });
    } else {
      picList = <LoadingAnimation />;
    }

    return (
      <Container fluid className="choiceRole-container p-0 m-0">
        <Row>
          <Col xs={12}>
            <MainHeadlineSmall
              topline="02 - PRODUCT AND PRICE RANGE - HOSPITALITY"
              text="What area are you working with?"
              subline="Pick the type of room you want to equip."
            />
          </Col>
          <Col xs={12}>
            <Row>
              <Spacing space={39} />
            </Row>
          </Col>
          <Col xs={12}>
            <Row>{picList}</Row>
            <Row className="d-none d-md-block">
              <Spacing space={39} />
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ form: state });
function mapDispatchToProps(dispatch) {
  return {
    addForm: (form) => dispatch(addForm(form)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RetailRoomComponent);
