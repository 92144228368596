import React from 'react';

const Topline = (props) => {
  const { topline } = props;
  if (topline) {
    return <span className="topline condensed">{topline}</span>;
  }
  return <span className="topline empty" />;
};

export default Topline;
